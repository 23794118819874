import React, { FC, PropsWithChildren, forwardRef } from 'react';

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

export type TooltipProps = MuiTooltipProps & { variant?: 'light' | 'dark' };

/**
 * Handle MUI forwardRef issue https://mui.com/material-ui/guides/composition/#caveat-with-refs
 *
 * The tooltip needs to apply DOM event listeners to its child element.
 * If the child is a custom React element, you need to make sure that it spreads its props to the underlying DOM element.
 */
const TooltipContent = forwardRef<HTMLSpanElement, PropsWithChildren<unknown>>((props, ref) => (
  <span {...props} ref={ref}>
    {props.children}
  </span>
));

export const Tooltip: FC<TooltipProps> = ({ children, variant = 'light', ...props }) => (
  <MuiTooltip classes={{ tooltip: variant }} {...props}>
    <TooltipContent>{children}</TooltipContent>
  </MuiTooltip>
);
