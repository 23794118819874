import { Dispatch, ListenerEffect } from '@reduxjs/toolkit';
import { ChatMessageBasic, ChatMessageStatus } from 'domain/Chat';
import type { RootState } from 'store';
import { selectUserAccountId } from 'store/apis/user';

import { addMessages, excludeFromMessages, markMessagesAsRejected } from './chatSlice';

type Effect<T> = ListenerEffect<{ type: string; payload: T }, RootState, Dispatch>;

export const newMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);
  dispatch(addMessages({ messages: payload, status: ChatMessageStatus.Approved, accountId }));
};

export const newPendingMessageEffect: Effect<ChatMessageBasic> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);

  dispatch(addMessages({ messages: [payload], status: ChatMessageStatus.Pending, accountId }));
};

export const newApprovedMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch }) => {
  dispatch(excludeFromMessages({ messages: payload }));
};

export const newRejectedMessagesEffect: Effect<ChatMessageBasic[]> = ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const accountId = selectUserAccountId(state);

  const myMessages = payload.filter(({ sender }) => sender.id === accountId);
  dispatch(markMessagesAsRejected({ messages: myMessages }));

  const otherMessages = payload.filter(({ sender }) => sender.id !== accountId);
  dispatch(excludeFromMessages({ messages: otherMessages }));
};
