import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';

type Props = {
  title?: string;
};

export const ListItemTitle: FC<Props> = ({ title, children }) => (
  <Typography component="div" variant="natter-text-sm" fontWeight={500} sx={textEllipsis} title={title}>
    {children}
  </Typography>
);
