import React, { ComponentProps, FC, ReactNode } from 'react';

import { Tooltip } from 'common/components/Tooltip';

import { StyleButton } from './ActionButton.styled';

export type ActionButtonProps = ComponentProps<typeof StyleButton> & {
  label: string;
  isDisabled?: boolean;
  'data-testid'?: string;
  Icon?: ReactNode;
};

export const ActionButton: FC<ActionButtonProps> = ({
  label,
  isDisabled,
  isLoading,
  children,
  Icon = children,
  color,
  ...props
}) => (
  <Tooltip title={label} placement="top" variant="dark">
    <StyleButton
      color={color}
      disabled={isDisabled}
      aria-label={label}
      disableFocusRipple
      disableRipple
      loading={isLoading}
      {...props}
    >
      {Icon}
    </StyleButton>
  </Tooltip>
);
