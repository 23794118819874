import AgoraRTC from 'agora-rtc-react';
import { DEFAULT_EVENT_IMAGE } from 'event/management/constants';

const FPS = 30;

const createAndDrawCanvas = (width = 720, height = 480) => {
  const canvas = document.createElement('canvas');
  const state = {
    stopAnimation: false,
  };

  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  const animationData = {
    fpsInterval: 1000 / FPS,
    now: 0,
    then: Date.now(),
    elapsed: 0,
  };

  const animatedObject = {
    x: 0,
    y: 0,
    width: width * 0.8,
    height,
    speedX: 1,
    direction: 1,
  };

  if (context) {
    const img = document.createElement('img');
    img.src = DEFAULT_EVENT_IMAGE.src;

    const drawCanvas = () => {
      animationData.now = Date.now();
      animationData.elapsed = animationData.now - animationData.then;

      if (state.stopAnimation) {
        return;
      }

      if (animationData.elapsed > animationData.fpsInterval) {
        context.drawImage(img, animatedObject.x, animatedObject.y, animatedObject.width, animatedObject.height);

        animatedObject.x += animatedObject.speedX * animatedObject.direction;
        if (animatedObject.x < 0 || animatedObject.x + animatedObject.width > canvas.width) {
          // animate it backwards
          animatedObject.direction = -animatedObject.direction;
        }
        animationData.then = animationData.now - (animationData.elapsed % animationData.fpsInterval);
      }

      requestAnimationFrame(drawCanvas);
    };

    img.onload = () => {
      drawCanvas();
    };
  }

  const dispose = () => {
    state.stopAnimation = true;
  };

  return {
    canvas,
    dispose,
  };
};

export const createCanvasVideoTrack = (width?: number, height?: number) => {
  const { canvas, dispose } = createAndDrawCanvas(width, height);
  const canvasStream = canvas.captureStream();
  const canvasMediaStreamTrack = canvasStream.getVideoTracks()[0];

  const videoTrack = AgoraRTC.createCustomVideoTrack({
    mediaStreamTrack: canvasMediaStreamTrack,
  });

  return {
    videoTrack,
    dispose,
  };
};
