import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { eventApi } from 'store/apis/event/eventApi';

import { eventWizardActions } from './eventWizard.actions';
import { createEvent } from './thunks';
import { EventWizardState } from './types';
import { getEventWizardInitialActiveStep, getEventWizardInitialSteps } from './utils';

export const initialState: EventWizardState = {
  isLoading: false,
  form: {
    mode: undefined,
    isDirty: false,
    requiredFieldsOnceFilled: false,
  },
  progressTracker: {
    steps: getEventWizardInitialSteps('create'),
    activeStep: getEventWizardInitialActiveStep('create'),
  },
};

export const eventWizardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(eventWizardActions.initialized, (_, { payload: { id, isRepeat, isWithTemplate } }) => {
      // eslint-disable-next-line no-nested-ternary
      const mode = isRepeat ? 'repeat' : id ? 'edit' : isWithTemplate ? 'template' : 'create';

      return {
        ...initialState,
        eventId: id,
        form: {
          ...initialState.form,
          mode,
        },
        progressTracker: {
          steps: getEventWizardInitialSteps(mode),
          activeStep: getEventWizardInitialActiveStep(mode),
        },
      };
    })
    .addCase(eventWizardActions.progressTracker.navigateToStep, (state, { payload: newStep }) => {
      state.progressTracker.activeStep = newStep;
      state.progressTracker.steps[newStep].status = 'IN_PROGRESS';
    })
    .addCase(eventWizardActions.progressTracker.stepValidationCompleted, (state, { payload: { step, isValid } }) => {
      state.progressTracker.steps[step].status = isValid ? 'COMPLETED' : 'INCOMPLETE';
    })
    .addCase(eventWizardActions.form.completed, (state) => {
      state.form.requiredFieldsOnceFilled = true;
    })
    .addCase(createEvent.fulfilled, () => ({
      ...initialState,
    }))
    .addMatcher(
      isAnyOf(
        eventWizardActions.details.videoDropzone.uploadCanceled,
        eventWizardActions.details.videoDropzone.uploadCompleted,
        eventWizardActions.details.videoDropzone.uploadFailed,
        eventWizardActions.publishButton.clicked.rejected,
        eventApi.endpoints.postEventImage.matchRejected,
        eventApi.endpoints.createEventQuestions.matchRejected
      ),
      (state) => {
        state.progressTracker.steps = state.progressTracker.steps.map((step) => ({
          ...step,
          isDisabled: false,
        }));
        state.progressTracker.steps[state.progressTracker.activeStep].isLoading = false;
        state.isLoading = false;
      }
    )
    .addMatcher(
      isAnyOf(
        eventWizardActions.publishButton.clicked.pending,
        eventWizardActions.details.videoDropzone.fileSelected,
        eventWizardActions.updateButton.clicked.pending,
        eventApi.endpoints.postEventImage.matchPending,
        eventApi.endpoints.createEventQuestions.matchPending
      ),
      (state) => {
        state.progressTracker.steps = state.progressTracker.steps.map((step) => ({
          ...step,
          isDisabled: true,
        }));
        state.progressTracker.steps[state.progressTracker.activeStep].isLoading = true;
        state.isLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(
        eventWizardActions.exited,
        eventWizardActions.updateButton.clicked.fulfilled,
        eventWizardActions.eventLimitReachedModal.confirmedButton.clicked
      ),
      (state) => ({ ...state, ...initialState })
    );
});
