import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { toBoolean } from 'common/utils';
import { EventStatus, MeetingGuest, TranscriptionMode } from 'domain/event';
import { mapEventRoomFromApi, mapToEventRoomAttendeeFromApi, mapToMeetingGuestFromApi } from 'event/mappers';
import { omit, uniqBy } from 'lodash';
import type { RootState } from 'store';
import { eventApi, selectActiveEventDetails } from 'store/apis/event';
import { selectUserAccountId } from 'store/apis/user';
import { initMergedDataDispatch } from 'store/utils/throttledDispatch';

import { addAppErrorMessage, addBroadcastInfoMessage, addNatteringInfoMessage, selectAlertById } from '../../alerts';
import {
  audienceJoinedList_WS,
  audienceJoined_WS,
  bmmMeetingGuestHandDown_WS,
  broadcastDemoted_WS,
  broadcastGuestDemoted_WS,
  broadcastGuestPromoted_WS,
  broadcastStarted_WS,
  broadcastVideoViaUrlPlayed_WS,
  broadcastVideoViaUrlStopped_WS,
  eventStarted_WS,
  eventStopped_WS,
  guestDisconnected_WS,
  guestExitedMeeting_WS,
  guestJoinedRoom_WS,
  joinRoom_WS,
  leaveRoomAsSupport,
  meetingGuestHandDown_WS,
  meetingGuestHandLoweredBy_WS,
  meetingGuestHandUp_WS,
  meetingJoin_WS,
  meetingRoomClosed_WS,
  natteringStarted_WS,
  recordingStarted_WS,
  recordingStopped_WS,
  roomSupportCancelled_WS,
  roomSupportJoined_WS,
  roomSupportLeft_WS,
  roomSupportRequestedList_WS,
  roomSupportRequested_WS,
  stageInvitationRejected_WS,
  startTranscription,
  stopTranscription,
  transcriptionStarted_WS,
  transcriptionStopped_WS,
} from '../actions';
import { BroadcastNotifications } from '../constants';
import { displayHandRaisedAlert, updateHandRaisedAlert, userDemotedEffect } from '../effects/meeting.effects';
import {
  addAudience,
  addLastCallQuest,
  addParticipantsWithRaisedHand,
  addRoom,
  hideTranscriptionModal,
  removeRoom,
  removeUserFromAudience,
  setCloudPlayer,
  setDisconnectedParticipants,
  updateRoom,
} from '../eventSlice.actions';
import {
  selectCurrentEventPhase,
  selectIsCurrentUserEventHost,
  selectIsUserEventHost,
  selectPinValue,
} from '../selectors';
import { EventPhase } from '../types';
import { findAttendeeById, findRoomById, getActiveRoom, getNotificationRecentSpeakersCount } from '../utils';

const groupBroadcastAudience = initMergedDataDispatch<MeetingGuest, { getState: () => RootState }>(
  (tmpBroadcastAudience, { dispatch, getState }) => {
    const { participantsDisconnected, isBigMeetingMode } = getState().event;
    if (isBigMeetingMode) return;

    dispatch(addAudience(tmpBroadcastAudience));
    const tmpBroadcastAudienceIds = tmpBroadcastAudience.map((a) => a.id);
    const updatedDisconnectedParticipants = participantsDisconnected.filter(
      (id) => !tmpBroadcastAudienceIds.includes(id)
    );
    dispatch(setDisconnectedParticipants(updatedDisconnectedParticipants));
    tmpBroadcastAudience.length = 0;
  },
  { shouldAddDataToFront: true }
);

export const meetingListener = createListenerMiddleware<RootState>();

meetingListener.startListening({
  actionCreator: guestDisconnected_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const { event } = getState();
    const room = findRoomById(event.rooms, payload.roomId);
    if (!room) return;

    const attendee = findAttendeeById(room, payload.userId);
    if (!attendee) return;

    dispatch(
      addNatteringInfoMessage(
        `${attendee.name} has been disconnected. You can continue to submit perspectives while we try to reconnect them and you will return to the broadcast when the Natter ends.`
      )
    );
  },
});

meetingListener.startListening({
  actionCreator: meetingRoomClosed_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();

    const room = getActiveRoom(state.event);
    if (!room) return;

    const isCurrentUserHost = selectIsCurrentUserEventHost(state);

    if (payload.id === room.id && isCurrentUserHost) {
      // Event host should leave room immediately
      dispatch(leaveRoomAsSupport({ roomId: room.id }));
    }
  },
});

meetingListener.startListening({
  actionCreator: guestJoinedRoom_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    logger.log('[meetingListener] Guest joined room', {
      extra: { guest: { ...payload } },
    });

    const state = getState();
    const existingRoom = findRoomById(state.event.rooms, payload.roomId);
    const isCurrentUserHost = selectIsCurrentUserEventHost(state);
    const shouldAddNewRoom = !existingRoom && !(state.event.isBigMeetingMode && isCurrentUserHost);
    const newAttendee = mapToEventRoomAttendeeFromApi(payload);

    if (shouldAddNewRoom) {
      dispatch(
        addRoom({
          room: {
            attendees: [newAttendee],
            id: payload.roomId,
            accessToken: '',
            startTime: 0,
            endTime: 0,
            orderNumber: 0,
            roomQuestionAssignments: [],
          },
        })
      );
    }

    if (existingRoom) {
      dispatch(
        updateRoom({
          room: {
            ...existingRoom,
            attendees: uniqBy([newAttendee, ...existingRoom.attendees], 'id'),
          },
        })
      );
    }

    dispatch(setDisconnectedParticipants(state.event.participantsDisconnected.filter((id) => id !== payload.userId)));

    if (!state.event.activeEventId) return;

    const eventDetails = selectActiveEventDetails(state);
    if (!eventDetails) return;

    if (!isCurrentUserHost) {
      const attendee = mapToEventRoomAttendeeFromApi(payload);
      const isUserHost = selectIsUserEventHost(state, attendee.id);
      if (!isUserHost) {
        dispatch(addLastCallQuest(attendee));
      }
    }
  },
});

meetingListener.startListening({
  actionCreator: meetingRoomClosed_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const { event } = getState();
    if (event.activeRoom?.id === payload.id && event.activeRoom.joinedAsSupport) {
      dispatch(leaveRoomAsSupport({ roomId: event.activeRoom.id }));
    }
  },
});

meetingListener.startListening({
  actionCreator: broadcastVideoViaUrlPlayed_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();
    const userAccountId = selectUserAccountId(state);
    const isUser = userAccountId === payload.createdById;

    dispatch(setCloudPlayer(payload));

    if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

    dispatch(addBroadcastInfoMessage(`${isUser ? 'You' : payload.createdByName} started sharing a video`));
  },
});

meetingListener.startListening({
  actionCreator: broadcastVideoViaUrlStopped_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    if (payload?.message) {
      dispatch(
        addAppErrorMessage({
          metadata: 'BroadcastService.stopVideoViaUrl',
          message: `Unable to stop video: ${payload.message}`,
        })
      );
    } else {
      const state = getState();
      if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

      const cloudPlayer = state.event.broadcast?.cloudPlayer;
      if (!cloudPlayer) return;

      const userAccountId = selectUserAccountId(state);
      const isStoppedByCurrentUser = userAccountId === payload.callerUserId;
      if (isStoppedByCurrentUser) {
        dispatch(addBroadcastInfoMessage('You stopped sharing the video'));
      } else {
        const stoppedBy = state.event.speakers.find(({ id }) => id === payload.callerUserId);
        dispatch(addBroadcastInfoMessage(`${stoppedBy ? stoppedBy.name : 'Someone'} stopped the video`));
      }
    }
    dispatch(setCloudPlayer(null));
  },
});

meetingListener.startListening({
  actionCreator: stageInvitationRejected_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();

    if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

    const { invitedUserFullName, invitingUserId } = payload;
    const userAccountId = selectUserAccountId(state);
    const isUser = userAccountId === Number(invitingUserId);
    if (isUser) {
      dispatch(addBroadcastInfoMessage(`${invitedUserFullName} has declined your invitation`));
    }
  },
});

meetingListener.startListening({
  actionCreator: audienceJoined_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    groupBroadcastAudience([mapToMeetingGuestFromApi(payload)], { dispatch, getState });
  },
});

meetingListener.startListening({
  actionCreator: broadcastGuestDemoted_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();
    const speaker = mapToMeetingGuestFromApi(payload);
    const userAccountId = selectUserAccountId(state);
    const isUser = userAccountId === speaker.id;

    if (isUser) {
      dispatch(
        addBroadcastInfoMessage({
          message: BroadcastNotifications.currentUserLeftStage.getMessage(),
          id: BroadcastNotifications.currentUserLeftStage.id,
        })
      );
      return;
    }

    if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

    const previousNotification = selectAlertById(BroadcastNotifications.otherUserLeftStage.id)(state);
    const recentSpeakersCount = getNotificationRecentSpeakersCount(previousNotification);

    dispatch(
      addBroadcastInfoMessage({
        message: BroadcastNotifications.otherUserLeftStage.getMessage(speaker.name, recentSpeakersCount),
        id: BroadcastNotifications.otherUserLeftStage.id,
        metadata: recentSpeakersCount,
      })
    );
  },
});

meetingListener.startListening({
  actionCreator: guestExitedMeeting_WS,
  effect: ({ payload }, { dispatch, getOriginalState, getState }) => {
    const state = getState();
    const userAccountId = selectUserAccountId(state);
    const speaker = mapToMeetingGuestFromApi(payload);
    const isUser = userAccountId === speaker.id;
    if (isUser) {
      const eventPinValue = selectPinValue(state);

      // fix for https://natterco.atlassian.net/browse/NAT-4798
      dispatch(
        meetingJoin_WS({
          pin: eventPinValue,
        })
      );
      return;
    }

    if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

    const isSpeaker = getOriginalState().event.speakers.some((s) => s.id === speaker.id);
    if (!isSpeaker) return;

    const previousNotification = selectAlertById(BroadcastNotifications.otherUserLeftStage.id)(state);
    const recentSpeakersCount = getNotificationRecentSpeakersCount(previousNotification);

    dispatch(
      addBroadcastInfoMessage({
        message: BroadcastNotifications.otherUserLeftStage.getMessage(speaker.name, recentSpeakersCount),
        id: BroadcastNotifications.otherUserLeftStage.id,
        metadata: recentSpeakersCount,
      })
    );
  },
});

meetingListener.startListening({
  actionCreator: broadcastGuestPromoted_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();

    const userAccountId = selectUserAccountId(state);
    if (!userAccountId) return;

    const speaker = mapToMeetingGuestFromApi(payload);
    const isUser = userAccountId === speaker.id;
    if (isUser) {
      return;
    }

    if (selectCurrentEventPhase(state) !== EventPhase.Broadcast) return;

    const previousNotification = selectAlertById(BroadcastNotifications.otherUserJoinedStage.id)(state);
    const recentSpeakersCount = getNotificationRecentSpeakersCount(previousNotification);

    dispatch(
      addBroadcastInfoMessage({
        message: BroadcastNotifications.otherUserJoinedStage.getMessage(speaker.name, recentSpeakersCount),
        id: BroadcastNotifications.otherUserJoinedStage.id,
        metadata: recentSpeakersCount,
      })
    );
  },
});

meetingListener.startListening({
  actionCreator: roomSupportRequested_WS,
  effect: ({ payload }, { dispatch, getState }) => {
    const state = getState();
    const isCurrentUserHost = selectIsCurrentUserEventHost(state);

    if (isCurrentUserHost && state.event.isBigMeetingMode) {
      const room = mapEventRoomFromApi(payload.room);
      dispatch(addRoom({ room }));
      return;
    }

    if (!isCurrentUserHost) {
      logger.log('[meetingListener] Support requested', { extra: { ...payload } });
      dispatch(
        addNatteringInfoMessage('Natter Support has been notified. If they are available, they will join shortly')
      );
    }
  },
});

meetingListener.startListening({
  actionCreator: roomSupportRequestedList_WS,
  effect: ({ payload }, { dispatch, getState }) => {
    const state = getState();
    if (!state.event.isBigMeetingMode) return;

    const isCurrentUserHost = selectIsCurrentUserEventHost(state);
    if (!isCurrentUserHost) return;

    payload.rooms.forEach((roomData) => {
      const room = mapEventRoomFromApi(roomData);
      dispatch(addRoom({ room }));
    });
  },
});

meetingListener.startListening({
  actionCreator: roomSupportJoined_WS,
  effect: ({ payload }, { dispatch, getState }) => {
    const state = getState();
    const isCurrentUserHost = selectIsCurrentUserEventHost(state);
    const room = findRoomById(state.event.rooms, payload.roomId);

    if (isCurrentUserHost && room && !room.accessToken) {
      dispatch(
        updateRoom({
          room: {
            ...room,
            accessToken: payload.user.agoraToken,
          },
        })
      );
    }
  },
});

meetingListener.startListening({
  actionCreator: roomSupportCancelled_WS,
  effect: ({ payload }, { dispatch, getState }) => {
    const state = getState();
    if (!state.event.isBigMeetingMode) return;

    const isUserHost = selectIsCurrentUserEventHost(state);
    const isInRoom = state.event.activeRoom?.id === payload.roomId;
    if (isUserHost && !isInRoom) {
      dispatch(removeRoom({ roomId: payload.roomId }));
    }
  },
});

meetingListener.startListening({
  actionCreator: roomSupportLeft_WS,
  effect: ({ payload }, { dispatch, getState }) => {
    const { event } = getState();
    const attendee = mapToEventRoomAttendeeFromApi(payload.user);
    const room = findRoomById(event.rooms, payload.roomId);
    if (room) {
      dispatch(
        updateRoom({
          room: {
            ...room,
            attendees: room.attendees.filter((a) => a.id !== attendee.id),
          },
        })
      );
    }
  },
});

meetingListener.startListening({
  actionCreator: leaveRoomAsSupport.fulfilled,
  effect: (
    {
      meta: {
        arg: { roomId },
      },
    },
    { dispatch, getState }
  ) => {
    const state = getState();
    const accountId = selectUserAccountId(state);

    if (accountId) {
      const room = findRoomById(state.event.rooms, roomId);

      if (room) {
        dispatch(
          updateRoom({
            room: {
              ...room,
              attendees: room.attendees.filter((a) => a.id !== accountId),
            },
          })
        );
      }
    }
  },
});

meetingListener.startListening({
  actionCreator: audienceJoinedList_WS,
  effect: ({ payload }, { dispatch }) => {
    const newAudienceMembers = payload.map(mapToMeetingGuestFromApi);
    dispatch(addAudience(newAudienceMembers));

    const newParticipantsWithRaisedHand = payload.filter(({ handRaised }) => handRaised).map(({ id }) => Number(id));
    dispatch(addParticipantsWithRaisedHand(newParticipantsWithRaisedHand));
  },
});

meetingListener.startListening({
  actionCreator: eventStarted_WS,
  effect: ({ payload }, { dispatch }) => {
    if (!payload.eventId) return;
    dispatch(
      eventApi.util.updateQueryData('getEventDetails', { id: payload.eventId }, (data) => {
        data.eventTime.startTime = new Date().toISOString();
        data.status = EventStatus.Started;
      })
    );
  },
});

meetingListener.startListening({
  actionCreator: eventStopped_WS,
  effect: ({ payload }, { dispatch }) => {
    if (!payload.eventId) return;
    dispatch(
      eventApi.util.updateQueryData('getEventDetails', { id: payload.eventId }, (data) => {
        data.status = EventStatus.Finished;
      })
    );
  },
});

meetingListener.startListening({
  actionCreator: broadcastStarted_WS,
  effect: ({ payload }, { dispatch }) => {
    logger.log('[meetingListener] Broadcast Started', {
      extra: { ...omit(payload, ['accessToken', 'broadcastChatToken']) },
    });
    if (toBoolean(payload.isRecording)) {
      dispatch(addBroadcastInfoMessage('This Event is being recorded'));
    }
  },
});

meetingListener.startListening({
  actionCreator: natteringStarted_WS,
  effect: ({ payload }) => {
    logger.log('[meetingListener] Nattering Started', { extra: { ...payload } });
  },
});

meetingListener.startListening({
  actionCreator: meetingGuestHandLoweredBy_WS,
  effect: ({ payload: { loweredByUserName } }, { dispatch }) => {
    dispatch(addBroadcastInfoMessage(`${loweredByUserName} has lowered your hand`));
  },
});

meetingListener.startListening({
  actionCreator: recordingStarted_WS,
  effect: (_, { dispatch }) => {
    dispatch(addBroadcastInfoMessage('This Event is being recorded'));
  },
});

meetingListener.startListening({
  actionCreator: recordingStopped_WS,
  effect: (_, { dispatch }) => {
    dispatch(addBroadcastInfoMessage('Recording has stopped'));
  },
});

meetingListener.startListening({
  actionCreator: joinRoom_WS,
  effect: ({ payload: { hasSetTranscriptionPreference, transcriptionMode, id } }, { dispatch }) => {
    if (transcriptionMode === TranscriptionMode.MANDATORY && !hasSetTranscriptionPreference) {
      // this may seem redundant but it's required in v1
      dispatch(startTranscription({ roomId: id }));
    }
  },
});

meetingListener.startListening({
  matcher: isAnyOf(startTranscription.fulfilled, stopTranscription.fulfilled),
  effect: (_, { dispatch }) => {
    dispatch(hideTranscriptionModal());
  },
});

meetingListener.startListening({
  actionCreator: transcriptionStarted_WS,
  effect: (_, { dispatch }) => {
    dispatch(addNatteringInfoMessage('Transcription is on, you also can share your perspectives by typing'));
  },
});

meetingListener.startListening({
  actionCreator: transcriptionStopped_WS,
  effect: (_, { dispatch }) => {
    dispatch(addNatteringInfoMessage('Transcription is off, you can share your perspectives by typing'));
  },
});

meetingListener.startListening({
  matcher: isAnyOf(meetingGuestHandUp_WS, addParticipantsWithRaisedHand),
  effect: displayHandRaisedAlert,
});

meetingListener.startListening({
  actionCreator: meetingGuestHandDown_WS,
  effect: updateHandRaisedAlert,
});

meetingListener.startListening({
  actionCreator: broadcastDemoted_WS,
  effect: userDemotedEffect,
});

meetingListener.startListening({
  actionCreator: bmmMeetingGuestHandDown_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();
    const isUserHost = selectIsCurrentUserEventHost(state);
    if (isUserHost) return;

    const guest = mapToMeetingGuestFromApi(payload);
    dispatch(removeUserFromAudience(guest));
  },
});
