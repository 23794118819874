import React, { FC } from 'react';

import { TooltipProps } from 'recharts';

import { ChartType, TooltipContent as TooltipContentType } from '../../types';
import { MAX_TOOLTIP_WIDTH, TooltipWrapper } from './TooltipContentWrapper.styled';

type Props = TooltipProps<number, string> & {
  activeDataKey?: string;
  isPercentageValue?: boolean;
  TooltipContent?: TooltipContentType;
  shouldDisplay?(currentDataPointPayload: CurrentDataPointPayload): boolean;
  chartType?: ChartType;
};

type CurrentDataPointPayload = { name: string; fill: string; x0: number; x1: number; isAnonymized: boolean };
type TooltipPayload = { fill: string; value: number; dataKey: string; payload: CurrentDataPointPayload };

type ViewBox = {
  width?: number;
  left?: number;
  right?: number;
};

export const TooltipContentWrapper: FC<Props> = ({
  payload,
  activeDataKey = 'value',
  isPercentageValue,
  TooltipContent,
  chartType,
  shouldDisplay,
  viewBox,
  coordinate,
}) => {
  const {
    fill,
    value,
    dataKey,
    payload: currentDataPointPayload = {} as CurrentDataPointPayload,
  } = (payload?.find((p) => p.dataKey === activeDataKey) ?? {}) as TooltipPayload;
  const { name, fill: currentDataPointFill, x0, x1, isAnonymized } = currentDataPointPayload;

  if (isAnonymized) return null;

  const isDisplayed = shouldDisplay ? shouldDisplay(currentDataPointPayload) : true;
  const showTooltip = name !== undefined && isDisplayed;
  const { width = 0, left = 0, right = 0 } = viewBox as ViewBox;
  const { x = 0 } = coordinate ?? {};
  const overflowRight = Math.min(0, width + left + right - (MAX_TOOLTIP_WIDTH / 2 + x));
  const overflowLeft = Math.max(0, MAX_TOOLTIP_WIDTH / 2 - x);
  const translateX = overflowLeft || overflowRight;

  return (
    <TooltipWrapper
      backgroundColor={fill ?? currentDataPointFill}
      visibility={showTooltip ? 'visible' : 'hidden'}
      sx={{
        transform: `translateX(calc(-50% + ${translateX}px))`,
      }}
    >
      {TooltipContent ? (
        <TooltipContent
          value={value}
          name={name}
          dataKey={dataKey}
          range={[x0, x1]}
          isPercentageValue={isPercentageValue}
          chartType={chartType}
          dataPoint={currentDataPointPayload}
        />
      ) : (
        value
      )}
    </TooltipWrapper>
  );
};
