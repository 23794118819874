import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { insightsReportApi } from './insightsReportApi';

export const insightsReportApiLister = createListenerMiddleware<RootState>();

insightsReportApiLister.startListening({
  matcher: insightsReportApi.endpoints.validateReportPin.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to validate PIN');
  },
});
