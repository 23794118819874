import React, { FC, ReactNode } from 'react';

import { DialogActions, DialogContent, Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@mui/material';
import { CloseIcon } from 'icons';

import { Button } from '../Buttons';
import { DialogCloseButton, DialogContentText, DialogTitle, StyledDialogProps } from './Dialog.styled';

export type DialogProps = Pick<
  MuiDialogProps,
  'fullWidth' | 'maxWidth' | 'role' | 'fullScreen' | 'sx' | 'disableRestoreFocus'
> & {
  cancelLabel?: string;
  confirmLabel?: string;
  isConfirmLoading?: boolean;
  isConfirmDisabled?: boolean;
  isOpen: boolean;
  title?: ReactNode;
  description?: string;
  showCancelButton?: boolean;
  showCloseButton?: boolean;
  /** @deprecated There is no more warning icon in the Dialog */
  showWarningIcon?: boolean;
  severity?: 'default' | 'danger';
  disableClickOutside?: boolean;
  formId?: string;
  onClose?(): void;
  onClosed?(): void;
  onConfirm?(): void;
  Actions?: ReactNode;
} & StyledDialogProps;

export const Dialog: FC<DialogProps> = ({
  cancelLabel = 'Cancel',
  children,
  confirmLabel = 'Confirm',
  description,
  fullWidth,
  fullScreen,
  isOpen,
  isConfirmLoading,
  isConfirmDisabled,
  maxWidth = 'md',
  showCancelButton = true,
  showCloseButton = true,
  title,
  severity = 'default',
  variant,
  centered = variant === 'confirmation',
  disableClickOutside = false,
  formId,
  Actions,
  onClose,
  onClosed,
  onConfirm,
  ...dialogProps
}) => {
  const showConfirmButton = !!(onConfirm || formId);
  const showDialogActions = showCancelButton || showConfirmButton || Actions;
  const showDialogContent = description || children;

  return (
    <MuiDialog
      open={isOpen}
      onClose={disableClickOutside ? undefined : onClose}
      disableEscapeKeyDown={disableClickOutside}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      onTransitionExited={onClosed}
      onClick={(e) => e.stopPropagation()}
      {...dialogProps}
    >
      <DialogTitle id="dialog-title" centered={centered} fullScreen={fullScreen}>
        {title}
        {showCloseButton && (
          <DialogCloseButton onClick={onClose} aria-label="Close">
            <CloseIcon fontSize="medium" />
          </DialogCloseButton>
        )}
      </DialogTitle>
      {showDialogContent && (
        <DialogContent data-testid="dialog-content">
          {description && (
            <DialogContentText id="dialog-description" centered={centered}>
              {description}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
      )}
      {showDialogActions && (
        <DialogActions>
          {showCancelButton && (
            <Button
              variant="outlined-grey"
              color="secondary"
              onClick={onClose}
              fullWidth={centered}
              disabled={isConfirmLoading}
            >
              {cancelLabel}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              variant="contained"
              color={severity === 'danger' ? 'error' : 'primary'}
              isLoading={isConfirmLoading}
              fullWidth={centered}
              onClick={formId ? undefined : onConfirm}
              type={formId ? 'submit' : undefined}
              form={formId}
              disabled={isConfirmDisabled}
            >
              {confirmLabel}
            </Button>
          )}
          {Actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};
