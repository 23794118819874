import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Stack } from '@mui/material';
import { TagsCategory } from 'domain/Common';

import { MemberTagCategoryContainer } from './components';

type MemberTagsSelectProps = {
  name: string;
};

export const MemberTagsSelect: FC<MemberTagsSelectProps> = ({ name }) => {
  const { fields: categories } = useFieldArray<Record<string, TagsCategory[]>>({ name });

  return (
    <Stack gap={2}>
      {categories.map((category, index) => (
        <MemberTagCategoryContainer
          name={`${name}.${index}.tags`}
          title={category.name}
          required={category.isSingleSelection}
          isSingleSelection={category.isSingleSelection}
          key={category.id}
        />
      ))}
    </Stack>
  );
};
