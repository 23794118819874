import type { RootState } from 'store';

export const selectIsDarkTheme = (state: RootState) => state.app.darkTheme;
export const selectScrollToTop = (state: RootState) => state.app.scrollToTop;
export const selectIsDrawerDisplayed = (state: RootState) => state.app.displayDrawer;
export const selectIsSimpleHeaderDisplayed = (state: RootState) => state.app.simpleHeader;
export const selectIsMainPaddingDisplayed = (state: RootState) => state.app.displayMainPadding;
/**
 * @TODO cleanup when remove old theme
 * @deprecated */
export const selectThemeColor = (state: RootState) => state.app.themeColor;
export const selectMobileMenuOpen = (state: RootState) => state.app.mobileMenuOpen;
export const selectMobileBroadcastDrawerOpen = (state: RootState) => state.app.mobileBroadcastDrawerOpen;
export const selectIsDrawerCollapsed = (state: RootState) => state.app.isDrawerCollapsed;
