import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isDefined } from 'common/utils';
import { isEqual, xorWith } from 'lodash';
import { InsightsReportType } from 'pages/InsightsReport/types';
import { ReportSource } from 'store/apis/insightsReport';

import { insightsReportsWizardActions } from './insightsReportsWizard.actions';

type InitialState = {
  selectedSurveyType: InsightsReportType | null;
  selectedReportSource: ReportSource | undefined;
  selectedSimilarReportSourcesIds: number[];
  similarReportSources: ReportSource[];
};

export const insightsReportsWizardSliceInitialState: InitialState = {
  selectedSurveyType: null,
  selectedReportSource: undefined,
  selectedSimilarReportSourcesIds: [],
  similarReportSources: [],
};

export const insightsReportsWizardSlice = createSlice({
  name: 'insightsReportsWizard',
  initialState: insightsReportsWizardSliceInitialState,
  reducers: {
    resetInsightsReportsWizardState: () => insightsReportsWizardSliceInitialState,
    setSelectedInsightsReportType: (state, { payload }: PayloadAction<InitialState['selectedSurveyType']>) => {
      state.selectedSurveyType = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(insightsReportsWizardActions.reportSources.reportSourceSelected, (state, { payload }) => {
        state.selectedReportSource = payload;
      })
      .addCase(
        insightsReportsWizardActions.similarReportSources.selectAllCheckboxToggled,
        (state, { payload: { isChecked } }) => {
          if (!isChecked) {
            state.selectedSimilarReportSourcesIds = [];
          } else {
            state.selectedSimilarReportSourcesIds = state.similarReportSources.map((source) => source.id);
          }
        }
      )
      .addCase(insightsReportsWizardActions.similarReportSources.reportSourceSelected, (state, { payload }) => {
        const updatedSimilarSources = xorWith(state.selectedSimilarReportSourcesIds, [payload.id], isEqual);
        state.selectedSimilarReportSourcesIds = updatedSimilarSources;
      })
      .addCase(insightsReportsWizardActions.similarReportSources.similarReportSourcesFetched, (state, { payload }) => {
        state.similarReportSources = [state.selectedReportSource, ...payload].filter(isDefined);
        state.selectedSimilarReportSourcesIds = state.similarReportSources.map((source) => source.id);
      })
      .addCase(insightsReportsWizardActions.similarReportSources.similarReportSourcesCleanup, (state) => {
        state.similarReportSources = [];
        state.selectedSimilarReportSourcesIds = [];
      }),
});
