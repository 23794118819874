import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, ClickAwayListener, type TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import { ClearButton } from 'common/components/Buttons/ClearButton';
import { MenuItemWithText } from 'common/components/Menu/types';
import { isDefined } from 'common/utils';
import { addMinutes, format, isBefore, isValid, startOfDay } from 'date-fns';
import { debounce } from 'lodash';

import { TextField } from '../TextField';
import { StyledPopper } from './TimePicker.styled';
import { TimePickerSelect } from './TimePickerSelect';
import { DEFAULT_INPUT_TIME_FORMAT } from './constants';
import { getNearestTimeOptionLabel } from './utils';

export const DEFAULT_TIME_STEPS_IN_MINUTES = 15;

export type TimePickerProps = {
  error?: boolean;
  fullWidth?: boolean;
  hint?: string;
  label?: string;
  minTime?: Date;
  placeholder?: string;
  required?: boolean;
  value?: Date | null;
  disabled?: boolean;
  onAccept?(date: Date | null): void;
  onBlur?(): void;
  onClose?(): void;
  onChange(value: Date | null): void;
  onOpen?(): void;
};

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      error,
      fullWidth = true,
      hint,
      label,
      minTime,
      placeholder,
      required,
      value,
      disabled,
      onAccept,
      onBlur,
      onClose,
      onChange,
      onOpen,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeValueIndex, setActiveValueIndex] = useState<number>();
    const [tmpValue, setTmpValue] = useState<string>();
    const inputRef = useRef<HTMLInputElement>();

    const timeOptions = useMemo(() => {
      const startDate = startOfDay(minTime && isValid(minTime) ? minTime : new Date());

      return Array.from({ length: 96 })
        .map<MenuItemWithText | undefined>((_, index) => {
          const date = addMinutes(startDate, index * 15);

          if (minTime && isBefore(date, minTime)) return undefined;

          return {
            text: format(date, 'hh:mmaa'),
            onClick: () => {
              onChange(date);
              setActiveValueIndex(index);
            },
          };
        })
        .filter(isDefined<MenuItemWithText>);
    }, [onChange, minTime]);

    const handleAccept = useCallback(
      (date: Date | null) => {
        onChange(date);
        onAccept?.(date);
      },
      [onAccept, onChange]
    );

    const handleOpen = useCallback(
      (event) => {
        if (disabled) return;

        onOpen?.();
        setAnchorEl(event.currentTarget);
      },
      [onOpen, disabled]
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
      onClose?.();
    }, [onClose]);

    const handleClear = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        onChange(null);
      },
      [onChange]
    );

    const onChangeDebounced = useMemo(() => debounce(() => setTmpValue(inputRef.current?.value), 100), []);

    useEffect(() => {
      if (!(value && isValid(value))) return;

      setTmpValue(format(value, 'hh:mm aa'));
    }, [value]);

    useEffect(() => {
      if (!tmpValue) return;

      const nearestTimeOptionLabel = getNearestTimeOptionLabel(tmpValue);
      if (!nearestTimeOptionLabel) return;
      setActiveValueIndex(timeOptions.findIndex((option) => option.text === nearestTimeOptionLabel));
    }, [tmpValue, timeOptions]);

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <Box data-testid="TimePicker-input" width={fullWidth ? '100%' : undefined}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiTimePicker
              format={DEFAULT_INPUT_TIME_FORMAT}
              inputRef={ref}
              label={label}
              minTime={minTime}
              slots={{
                popper: StyledPopper,
                textField: TextField,
              }}
              slotProps={{
                openPickerButton: {
                  sx: {
                    display: 'none',
                  },
                },
                textField: {
                  error,
                  fullWidth,
                  hint,
                  placeholder,
                  required,
                  suffix: value && !disabled ? <ClearButton onClick={handleClear} /> : undefined,
                  onBlur,
                  onClick: handleOpen,
                  inputRef,
                  inputProps: {
                    onChange: onChangeDebounced,
                  },
                } as unknown as TextFieldProps,
              }}
              value={value}
              onAccept={handleAccept}
              onChange={onChange}
              viewRenderers={{
                hours: renderDigitalClockTimeView,
                minutes: undefined,
              }}
              timeSteps={{ minutes: DEFAULT_TIME_STEPS_IN_MINUTES }}
              disabled={disabled}
            />
          </LocalizationProvider>
          <TimePickerSelect
            options={timeOptions}
            anchorEl={anchorEl}
            activeValueIndex={activeValueIndex}
            onClose={handleClose}
          />
        </Box>
      </ClickAwayListener>
    );
  }
);
