import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { QuestionAssignmentAnswer } from 'domain/question';

import {
  answerAssignedToUserIds_WS,
  answerCreated_WS,
  answerDeleted_WS,
  answerUpdated_WS,
  questionAssignment_WS,
} from './actions';

export const answersAdapter = createEntityAdapter<QuestionAssignmentAnswer>({
  selectId: (answer) => answer.uuid,
});

export const answersSlice = createSlice({
  name: 'answers',
  initialState: answersAdapter.getInitialState(),
  reducers: {
    clearAnswers: answersAdapter.removeAll,
    clearUpdatedByUserId: (state, { payload }) => {
      answersAdapter.updateOne(state, { id: payload, changes: { updatedByUserId: undefined } });
    },
    addAnswer: (state, { payload }: PayloadAction<QuestionAssignmentAnswer>) => {
      answersAdapter.setOne(state, payload);
    },
    assignAnswerToUserIds: (state, { payload }: PayloadAction<{ id: string; assignedToUserIds: number[] }>) => {
      const { id, assignedToUserIds } = payload;
      answersAdapter.updateOne(state, { id, changes: { assignedToUserIds } });
    },
    deleteAnswer: (state, { payload }: PayloadAction<string>) => {
      answersAdapter.removeOne(state, payload);
    },
    updateAnswer: (state, { payload }: PayloadAction<{ id: string; changes: Partial<QuestionAssignmentAnswer> }>) => {
      const { id, changes } = payload;
      answersAdapter.updateOne(state, {
        id,
        changes,
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(questionAssignment_WS, (state, { payload }) => {
        answersAdapter.setAll(state, payload.answers);
      })
      .addCase(answerCreated_WS, (state, { payload }) => {
        answersAdapter.setOne(state, payload);
      })
      .addCase(answerAssignedToUserIds_WS, (state, { payload }) => {
        answersAdapter.updateOne(state, { id: payload.uuid, changes: { ...payload, isAnonymous: false } });
      })
      .addCase(answerDeleted_WS, (state, { payload }) => {
        answersAdapter.removeOne(state, payload);
      })
      .addCase(answerUpdated_WS, (state, { payload }) => {
        answersAdapter.updateOne(state, {
          id: payload.answer.uuid,
          changes: { ...payload.answer, updatedByUserId: payload.updatedByUserId },
        });
      }),
});

export const { clearAnswers, clearUpdatedByUserId, addAnswer, updateAnswer, deleteAnswer, assignAnswerToUserIds } =
  answersSlice.actions;
