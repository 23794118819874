import { Dispatch } from '@reduxjs/toolkit';

import { broadcastGuestPromoted_WS, guestExitedMeeting_WS } from './actions';

const guestExitedDelayedEffects = new Map<string, NodeJS.Timeout>();

export const GUEST_EXITED_DELAY = 2000;

export const eventSocketDispatcherMiddleware = (
  dispatch: Dispatch,
  { path, payload }: { path: string; payload: unknown }
) => {
  if (path === guestExitedMeeting_WS.type) {
    const { id } = payload as ReturnType<typeof guestExitedMeeting_WS>['payload'];
    const key = `${guestExitedMeeting_WS.type}${id}`;

    const existingDispatch = guestExitedDelayedEffects.get(key);
    if (existingDispatch) {
      clearTimeout(existingDispatch);
    }

    const delayedDispatch = setTimeout(() => {
      dispatch({
        type: path,
        payload,
      });
      guestExitedDelayedEffects.delete(key);
    }, GUEST_EXITED_DELAY);
    guestExitedDelayedEffects.set(key, delayedDispatch);
    return true;
  }

  if (path === broadcastGuestPromoted_WS.type) {
    const { accountId } = payload as ReturnType<typeof broadcastGuestPromoted_WS>['payload'];
    const key = `${guestExitedMeeting_WS.type}${accountId}`;
    const delayedDispatch = guestExitedDelayedEffects.get(key);
    if (delayedDispatch) {
      clearTimeout(delayedDispatch);
      guestExitedDelayedEffects.delete(key);
      return true;
    }
  }

  return false;
};
