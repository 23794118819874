import React, { FC, KeyboardEventHandler, useMemo, useState } from 'react';

import { ListItemText } from '@mui/material';
import { SearchBox } from 'common/components/SearchBox';

import {
  MultipleSelectFilterCheckbox,
  MultipleSelectFilterMenuItem,
  MultipleSelectFilterSearchWrapper,
  MultipleSelectFilterSelectAllItem,
} from './MultipleSelectFilter.styled';
import { MultipleSelectFilterOption } from './types';

const getOptionLabel = (option: MultipleSelectFilterOption) =>
  option.count === undefined ? option.label : `${option.label} (${option.count})`;

type Props = {
  label: string;
  options: MultipleSelectFilterOption[];
  value: (string | number)[];
  searchLabel?: string;
  onChange(value: (string | number)[]): void;
};

export const MultipleSelectFilterContent: FC<Props> = ({
  value,
  label,
  searchLabel = `Search ${label}`,
  options,
  onChange,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(searchQuery.trim().toLowerCase())),
    [options, searchQuery]
  );
  const isAllOptionsSelected = useMemo(() => value.length === options.length, [options, value]);
  const showSearch = options.length > 1;
  const showSelectAllOption = showSearch && !searchQuery;

  const resetTempValue = () => onChange([]);

  const handleSelectAll = () => {
    if (isAllOptionsSelected) {
      resetTempValue();
    } else {
      onChange(options.map((option) => option.value));
    }
  };

  const handleChange = (option: MultipleSelectFilterOption) => () => {
    const isCurrentlySelected = value.includes(option.value);
    const newValue = isCurrentlySelected ? value.filter((v) => v !== option.value) : value.concat([option.value]);
    onChange(newValue);
  };

  const preventPropagation: KeyboardEventHandler<HTMLDivElement> = (e) => e.stopPropagation();

  return (
    <>
      {showSearch && (
        <MultipleSelectFilterSearchWrapper onKeyDown={preventPropagation}>
          <SearchBox placeholder={searchLabel} value={searchQuery} onSearch={setSearchQuery} />
        </MultipleSelectFilterSearchWrapper>
      )}
      {showSelectAllOption && (
        <MultipleSelectFilterSelectAllItem onClick={handleSelectAll}>
          <MultipleSelectFilterCheckbox checked={isAllOptionsSelected} size="small" />
          <ListItemText primary="Select all" />
        </MultipleSelectFilterSelectAllItem>
      )}
      {filteredOptions.map((option, index) => (
        <MultipleSelectFilterMenuItem
          key={index}
          value={option.value}
          title={option.label}
          onClick={handleChange(option)}
        >
          <MultipleSelectFilterCheckbox checked={value.includes(option.value)} size="small" />
          <ListItemText primary={getOptionLabel(option)} />
        </MultipleSelectFilterMenuItem>
      ))}
    </>
  );
};
