import type { Components, Theme } from '@mui/material';

const DEFAULT_TOOLBAR_HEIGHT = 54;

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      '&:not(.MuiToolbar-dense)': {
        '@media all': {
          minHeight: DEFAULT_TOOLBAR_HEIGHT,
        },
      },
      '&.MuiToolbar-gutters': {
        '@media all': {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },
      },
    }),
  },
};
