import React, { FC, useMemo } from 'react';

import { useTheme } from '@mui/styles';
import { convertNumberToAbbreviation } from 'common/utils';
import { LabelProps } from 'recharts';

import { CHART_LABEL_FONT_SIZE, CHART_LABEL_FONT_WEIGHT, CHART_TICK_PADDING } from '../constants';
import { BasicChartData } from '../types';

type BarChartLabelProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number | string;
  name: string;
};

type Props = LabelProps & {
  isPercentageValue?: boolean;
  data?: BasicChartData;
};

const anonymizedDataWarningLabel = 'Anonymized due to lack of data';
const anonymizedLabelFontSize = '10px';
const anonymizedLabelFontWeight = 400;

export const BarChartLabel: FC<Props> = (props) => {
  const { palette, typography } = useTheme();
  const { fill = '', isPercentageValue = false, data, index } = props;
  const { isAnonymized = false } = index !== undefined && data ? data[index] : {};
  const { x, y, height, width, value, name } = props as BarChartLabelProps;
  const labelPositionX = x + width / 2;
  const labelPositionY = y + height / 2;

  const formattedValue = useMemo(() => {
    if (isAnonymized) {
      return anonymizedDataWarningLabel;
    }

    if (typeof value === 'number') {
      return convertNumberToAbbreviation(value, isPercentageValue);
    }

    return value;
  }, [value, isPercentageValue, isAnonymized]);

  const { fitsHorizontally } = useMemo(() => {
    const element = initTemporaryLabelElement({
      fontFamily: typography.fontFamily,
      value: formattedValue,
      isPercentageValue: isAnonymized ? false : isPercentageValue,
      fontSize: isAnonymized ? anonymizedLabelFontSize : undefined,
      fontWeight: isAnonymized ? anonymizedLabelFontWeight : undefined,
    });
    const { clientHeight, clientWidth } = element;
    document.body.removeChild(element);
    return {
      fitsHorizontally: clientWidth < width && clientHeight < height,
      fitsVertically: clientWidth < height && clientHeight < width,
    };
  }, [typography, formattedValue, width, height, isPercentageValue, isAnonymized]);

  if (isAnonymized && isPercentageValue && value === 100) {
    return (
      <text
        x={labelPositionX}
        y={labelPositionY}
        fill={palette.getContrastText(fill)}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={anonymizedLabelFontSize}
        fontWeight={anonymizedLabelFontWeight}
        style={{ pointerEvents: 'none' }}
        data-testid={`BarChartLabel-${name}`}
      >
        {anonymizedDataWarningLabel}
      </text>
    );
  }

  if (!fitsHorizontally) return null;

  return (
    <text
      x={labelPositionX}
      y={labelPositionY}
      fill={palette.getContrastText(fill)}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={CHART_LABEL_FONT_SIZE}
      fontWeight={CHART_LABEL_FONT_WEIGHT}
      style={{ pointerEvents: 'none' }}
      data-testid={`BarChartLabel-${name}`}
    >
      {`${formattedValue}${isPercentageValue ? '%' : ''}`}
    </text>
  );
};

export const initTemporaryLabelElement = ({
  fontFamily,
  value,
  isPercentageValue = false,
  fontWeight = CHART_LABEL_FONT_WEIGHT,
  fontSize = CHART_LABEL_FONT_SIZE,
}: {
  fontFamily: string | undefined;
  value: string | number | undefined;
  isPercentageValue?: boolean;
  fontWeight?: number;
  fontSize?: string;
}) => {
  const element = document.createElement('div');
  element.style.visibility = 'hidden';
  element.style.fontFamily = fontFamily ?? '';
  element.style.fontSize = fontSize;
  element.style.fontWeight = fontWeight.toString();
  element.style.display = 'inline-block';
  element.style.paddingLeft = `${CHART_TICK_PADDING}px`;
  element.style.paddingRight = `${CHART_TICK_PADDING}px`;
  element.innerHTML = value?.toString() ? `${value.toString()}${isPercentageValue ? '%' : ''}` : '';
  document.body.appendChild(element);
  return element;
};
