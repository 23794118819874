import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { Divider, ListItemIcon, ListItemText, MenuItem as MuiMenuItem } from '@mui/material';
import { ChevronRightIcon } from 'icons';
import { NestedMenuItem } from 'mui-nested-menu';

import { MenuItemType, isMenuItemWithComponent, isMenuItemWithText, isNestedMenuItemWithText } from '../../types';

export type MenuItemProps = {
  isActive?: boolean;
  item: MenuItemType;
  parentMenuOpen?: boolean;
  onClose?(): void;
  autoScroll?: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  isActive,
  item,
  parentMenuOpen = false,
  onClose,
  autoScroll = false,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const isNested = isNestedMenuItemWithText(item);
  const direction = (isNested && item.direction) || 'right';
  const label = isMenuItemWithComponent(item) ? undefined : item.text;
  const color = useMemo(() => {
    if (!isMenuItemWithText(item)) return undefined;
    if (item.color) return item.color;
    return item.severity === 'danger' ? 'danger' : undefined;
  }, [item]);
  const dataTestId = item['data-testid'];
  const handleClick = useCallback(() => {
    item.onClick?.();
    onClose?.();
  }, [item, onClose]);

  useEffect(() => {
    if (!(autoScroll && isActive)) return;

    ref.current?.scrollIntoView({
      block: 'nearest',
    });
  }, [isActive, autoScroll]);

  return (
    <>
      {item.withDivider && <Divider component="li" />}

      {isNested ? (
        <NestedMenuItem
          renderLabel={() => <ListItemText>{item.text}</ListItemText>}
          leftIcon={item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          rightIcon={<ChevronRightIcon fontSize="small" />}
          parentMenuOpen={parentMenuOpen}
          sx={({ spacing }) => ({
            padding: spacing(1, 1.5),
            '& .MuiBox-root': {
              alignItems: 'center',
              gap: 1,
            },
          })}
          MenuProps={{
            sx: { marginLeft: 0.05 },
            anchorOrigin: { vertical: 'center', horizontal: direction ?? 'right' },
            transformOrigin: { vertical: 'center', horizontal: direction === 'right' ? 'left' : 'right' },
          }}
          aria-label={item.text}
          data-testid={dataTestId}
        >
          {item.nestedItems.map((nestedItem, nestedIndex) => (
            <MenuItem
              key={nestedIndex}
              item={nestedItem}
              onClose={onClose}
              parentMenuOpen={parentMenuOpen}
              data-testid={nestedItem['data-testid']}
            />
          ))}
        </NestedMenuItem>
      ) : (
        <MuiMenuItem
          selected={isActive}
          color={color}
          onClick={handleClick}
          disabled={item.disabled}
          aria-label={label}
          ref={ref}
          data-testid={dataTestId}
        >
          {isMenuItemWithComponent(item) ? (
            item.component
          ) : (
            <>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.text}</ListItemText>
            </>
          )}
        </MuiMenuItem>
      )}
    </>
  );
};
