import React, { CSSProperties, ChangeEvent, FC } from 'react';

import { InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { dark as darkColors, grey } from 'common/theme/palette';
import { SearchIcon } from 'icons';

export type SearchBoxProps = Pick<TextFieldProps, 'size' | 'variant' | 'value'> & {
  placeholder?: string;
  onSearch?(searchedTerm: string): void;
  onSearchDebounced?(searchedTerm: string): void;
  backgroundColor?: CSSProperties['backgroundColor'];
  sx?: SxProps;
  'data-testid'?: string;
  defaultValue?: string;
  dark?: boolean;
};

export const SearchBox: FC<SearchBoxProps> = ({
  onSearch,
  onSearchDebounced,
  placeholder = 'Search',
  size = 'small',
  sx,
  dark = false,
  ...props
}) => {
  const debounceChange = AwesomeDebouncePromise((searchedTerm: string) => {
    onSearchDebounced?.(searchedTerm);
  }, 500);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchedTerm: string = event.target.value.trim();
    onSearch?.(searchedTerm);
    debounceChange(searchedTerm);
  };

  return (
    <TextField
      fullWidth
      size={size}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize={size} />
          </InputAdornment>
        ),
        sx: ({ spacing }) => ({
          color: dark ? 'white' : grey[900],
          borderRadius: 2,
          paddingLeft: spacing(1),
          '&:hover:not(.Mui-focused)': {
            backgroundColor: dark ? darkColors[100] : grey[50],
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
          },
          '& > .MuiInputAdornment-root': {
            color: grey[500],
          },
          '&.MuiInputBase-sizeSmall': {
            height: 32,
          },
          '& input::placeholder': {
            paddingLeft: spacing(0.25),
          },
        }),
      }}
      inputProps={{ 'aria-label': 'Search' }}
      placeholder={placeholder}
      sx={sx}
      data-testid={props['data-testid']}
      {...props}
    />
  );
};
