import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import { Box, Stack, StackProps, Typography } from '@mui/material';
import { useHover, useToggleState } from 'common/hooks';
import { ChevronRightIcon } from 'icons';

import {
  ExpandableBoxContent,
  ExpandableBoxSummary,
  ExpandableBoxVariants,
  ExpandableBoxWrapper,
} from './ExpandableBox.styled';

type CallbackParams = { isExpanded: boolean };

type Props = {
  Summary: ReactNode | ((params: CallbackParams) => ReactNode);
  initialExpanded?: boolean;
  Description?: ReactNode;
  showDescription?: boolean | ((params: CallbackParams) => boolean);
  Actions?: ReactNode | ((params: CallbackParams) => ReactNode);
  size?: 'small' | 'medium';
  showDivider?: boolean;
  variant?: ExpandableBoxVariants;
  width?: StackProps['width'];
  expandIconPlacement?: 'left' | 'right';
  isRounded?: boolean;
  disabled?: boolean;
};

export const ExpandableBox: FC<Props> = ({
  Summary,
  Description,
  showDescription = !!Description,
  initialExpanded = false,
  Actions,
  size = 'medium',
  showDivider = false,
  children,
  variant = 'outlined',
  expandIconPlacement = 'left',
  isRounded = size === 'medium',
  disabled,
  ...props
}) => {
  const [isExpanded, toggleExpanded] = useToggleState(disabled ? false : initialExpanded);
  const [ref, isHovered] = useHover();
  const isActionsVisible = isHovered || isMobile;

  const isDescriptionVisible = useMemo(
    () => (typeof showDescription === 'function' ? showDescription({ isExpanded }) : showDescription),
    [showDescription, isExpanded]
  );

  const actionsContent = useMemo(
    () => (typeof Actions === 'function' ? Actions({ isExpanded }) : Actions),
    [Actions, isExpanded]
  );

  useEffect(() => {
    toggleExpanded(disabled ? false : initialExpanded);
  }, [toggleExpanded, disabled, initialExpanded]);

  return (
    <Stack component="section" gap={2} ref={ref} {...props}>
      <ExpandableBoxWrapper
        disableGutters
        // TODO Requires update of @mui/material@^5.15.18 but doesn't work as expected
        // Removing `ExpandableBoxContent` from the DOM is handled by `isExpanded` state
        // slotProps={{ transition: { unmountOnExit: true } }}
        expanded={isExpanded}
        onChange={disabled ? undefined : toggleExpanded}
        isRounded={isRounded}
        boxVariant={variant}
        disabled={disabled}
      >
        <ExpandableBoxSummary
          expandIcon={disabled ? undefined : <ChevronRightIcon fontSize="small" />}
          size={size}
          isExpanded={isExpanded}
          showDivider={showDivider}
          expandIconPlacement={expandIconPlacement}
        >
          <Stack flex={1} justifyContent="start">
            <Typography variant="natter-text-sm" fontWeight={600} component="h2">
              {typeof Summary === 'function' ? Summary({ isExpanded }) : Summary}
            </Typography>
            {isDescriptionVisible && (
              <Typography variant="natter-text-sm" color="grey.600">
                {Description}
              </Typography>
            )}
          </Stack>

          {actionsContent && (
            <Box
              sx={{
                visibility: isActionsVisible ? 'visible' : 'hidden',
              }}
            >
              {actionsContent}
            </Box>
          )}
        </ExpandableBoxSummary>

        {isExpanded && (
          <ExpandableBoxContent size={size} showDivider={showDivider} variant={variant}>
            {children}
          </ExpandableBoxContent>
        )}
      </ExpandableBoxWrapper>
    </Stack>
  );
};
