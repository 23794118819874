/* eslint-disable no-console */
import { Features } from 'common/constants/features';

import type { FeatureKey } from '../types';
import { isFeatureEnabled } from './isFeatureEnabled';

export const logEnabledFeatures = () => {
  console.log('*** Enabled features:');
  Object.entries(Features).forEach(([key]) => {
    console.log(`*** ${key}: ${isFeatureEnabled(key as FeatureKey)}`);
  });

  console.log('*** Natter version:', process.env.REACT_APP_TAG, `(${process.env.REACT_APP_COMMIT ?? ''}) ***`);
};
