import { mapObjectWithIdListToIds } from 'common/utils';
import { differenceInMinutes } from 'date-fns';
import { AgoraProxyMode } from 'domain/event';
import { EventSeries } from 'domain/event/EventSeries';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation/schema';
import { CreateUpdateEventForApi, CreateUpdateEventSeriesForApi } from 'store/apis/event';

export const mapEventWizardFormValuesToApi = ({
  eventVideos,
  eventRecordingEnabled,
  schedule,
  ...data
}: EventWizardFormSerializedValues & {
  communityId?: number;
  relatedImageIds?: number[];
}): CreateUpdateEventForApi => ({
  name: data.name,
  communityId: data.communityId,
  pinEnabled: data.pinEnabled,
  description: data.description || '',
  startTime: schedule[0].startTime,
  eventDuration: differenceInMinutes(new Date(schedule[0].endTime), new Date(schedule[0].startTime)),
  relatedImageIds: data.relatedImageIds || [],
  relatedVideoIds: mapObjectWithIdListToIds(eventVideos),
  agoraProxyMode: AgoraProxyMode.NoProxy,
  agoraCloudRecordingEnabled: eventRecordingEnabled,
  hostIds: data.hosts?.map(({ accountId }) => accountId) ?? [],
});

export const mapEventSeriesWizardFormValuesToApi = ({
  eventVideos,
  eventRecordingEnabled,
  schedule,
  ...data
}: EventWizardFormSerializedValues & {
  communityId?: number;
  relatedImageIds?: number[];
}): CreateUpdateEventSeriesForApi => ({
  name: data.name,
  communityId: data.communityId,
  pinEnabled: data.pinEnabled,
  description: data.description || '',
  eventSchedules: schedule.map(({ eventId, name, startTime, endTime }) => ({
    name,
    startTime,
    durationInMinutes: differenceInMinutes(new Date(endTime), new Date(startTime)),
    eventId,
  })),
  relatedImageIds: data.relatedImageIds || [],
  relatedVideoIds: mapObjectWithIdListToIds(eventVideos),
  agoraProxyMode: AgoraProxyMode.NoProxy,
  agoraCloudRecordingEnabled: eventRecordingEnabled,
  hostIds: data.hosts?.map(({ accountId }) => accountId) ?? [],
});

export const mapEventSeriesToUpdateEventSeriesApiPayload = ({
  data: {
    isAgoraCloudRecordingEnabled,
    communityId,
    description,
    id,
    name,
    isPinEnabled,
    relatedVideos,
    eventImage,
    events,
    hosts,
  },
  eventIdsToRemove,
}: {
  data: EventSeries;
  eventIdsToRemove: number[];
}): CreateUpdateEventSeriesForApi => ({
  id,
  communityId,
  name,
  description,
  agoraCloudRecordingEnabled: isAgoraCloudRecordingEnabled,
  pinEnabled: isPinEnabled,
  // TODO Make sure hostIds is properly passed from BE: https://natterco.atlassian.net/browse/N2-2998
  hostIds: hosts.map(({ accountId }) => accountId),
  relatedImageIds: eventImage ? [eventImage.id] : [],
  relatedVideoIds: relatedVideos.map((video) => video.id),
  eventSchedules: events
    .filter((event) => !eventIdsToRemove.includes(event.id))
    .map((event) => ({
      eventId: event.id,
      name: event.name,
      startTime: new Date(event.startTime).toISOString(),
      durationInMinutes: event.durationInMinutes,
    })),
});
