import React, { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';
import { useVirtualScrollContext } from 'common/components';

type Props<T> = {
  items: T[];
  itemHeight: number;
  offsetTop?: number;
  overScan?: number;
  children(items: T[]): ReactNode[];
};

export const VirtualList = <T,>({ items, itemHeight, offsetTop = 0, overScan = 5, children }: Props<T>) => {
  const { scrollTop, scrollContainerHeight } = useVirtualScrollContext();
  const effectiveScrollTop = Math.max(0, scrollTop - offsetTop);
  const startIndex = Math.max(Math.floor(effectiveScrollTop / itemHeight) - overScan, 0);
  const endIndex = Math.min(
    Math.ceil((effectiveScrollTop + scrollContainerHeight) / itemHeight) + overScan,
    items.length
  );

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: items.length * itemHeight,
      }}
    >
      <Stack
        gap={1}
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
          transform: `translateY(${startIndex * itemHeight}px)`,
        }}
      >
        {children(items.slice(startIndex, endIndex))}
      </Stack>
    </Box>
  );
};
