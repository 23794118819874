import React, { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

export const LoginEmbeddedSkeleton: FC = () => (
  <Stack gap={2}>
    <Skeleton variant="rounded" height={48} />
    <Skeleton variant="rounded" height={48} />
    <Stack direction="row" justifyContent="space-between">
      <Skeleton width={125} />
      <Skeleton width={120} />
    </Stack>

    <Skeleton variant="rounded" height={40} sx={{ mt: 2 }} />
  </Stack>
);
