import { AnyAction } from '@reduxjs/toolkit';
import type { DialogProps } from 'common/components/Dialog/Dialog';
import { ApiExceptionMessage } from 'common/utils';
import * as icons from 'icons';

export enum AlertSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum AlertType {
  BackendError = 'backendError',
  Dialog = 'dialog',
  SnackBar = 'snackbar',
}

export type AlertContext = 'APP' | 'BROADCAST' | 'NATTERING';

export type AppAlertMessage = {
  message: string;
  metadata?: unknown;
  error?: Error;
  timeout?: number;
  icon?: keyof typeof icons;
};

export type AlertBaseData = {
  id: string;
  type: AlertType;
  context: AlertContext;
};

export type SnackBarAlertData = AlertBaseData & {
  type: AlertType.SnackBar;
  message: string | AppAlertMessage;
  severity: AlertSeverity;
};

export type BackendErrorAlertData = AlertBaseData & {
  type: AlertType.BackendError;
  message: string | AppAlertMessage | ApiExceptionMessage;
};

export type DialogAlertData = AlertBaseData & {
  type: AlertType.Dialog;
  message: string;
  options: Partial<Omit<DialogProps, 'onClose' | 'onConfirm'>> &
    Required<Pick<DialogProps, 'title' | 'description'>> & {
      actionToDispatchOnConfirm?: AnyAction;
      actionToDispatchOnClose?: AnyAction;
    };
};

export type AlertData = SnackBarAlertData | BackendErrorAlertData | DialogAlertData;

export const isSnackBarAlert = (data: AlertData): data is SnackBarAlertData => data.type === AlertType.SnackBar;
export const isBackendErrorAlert = (data: AlertData): data is BackendErrorAlertData =>
  data.type === AlertType.BackendError;
export const isDialogAlert = (data: AlertData): data is DialogAlertData => data.type === AlertType.Dialog;
export const isAlertMatchingContext = (context: string) => (data: AlertData) => data.context === context;
