import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CellTowerIcon from '@mui/icons-material/CellTowerRounded';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CommitIcon from '@mui/icons-material/Commit';
import ConstructionIcon from '@mui/icons-material/ConstructionRounded';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import GroupsFilledIcon from '@mui/icons-material/Groups';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import SpeedIcon from '@mui/icons-material/Speed';
import StopIcon from '@mui/icons-material/Stop';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { createSvgIcon } from 'common/theme/utils';

import { ReactComponent as Add } from './Add.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ArrowUp } from './ArrowUp.svg';
import { ReactComponent as BarChart } from './BarChart.svg';
import { ReactComponent as BookSpark } from './BookSpark.svg';
import { ReactComponent as BorderColor } from './BorderColor.svg';
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as CancelPresentationFilled } from './CancelPresentationFilled.svg';
import { ReactComponent as ChartSelect } from './ChartSelect.svg';
import { ReactComponent as Chat } from './Chat.svg';
import { ReactComponent as Check } from './Check.svg';
import { ReactComponent as CheckFilled } from './CheckFilled.svg';
import { ReactComponent as ChevronDown } from './ChevronDown.svg';
import { ReactComponent as ChevronLeft } from './ChevronLeft.svg';
import { ReactComponent as ChevronRight } from './ChevronRight.svg';
import { ReactComponent as ChevronUp } from './ChevronUp.svg';
import { ReactComponent as Close } from './Close.svg';
import { ReactComponent as Copy } from './Copy.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as DoubleArrowLeft } from './DoubleArrowLeft.svg';
import { ReactComponent as Download } from './Download.svg';
import { ReactComponent as DragIndicator } from './DragIndicator.svg';
import { ReactComponent as Duplicate } from './Duplicate.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as EmojiFilled } from './EmojiFilled.svg';
import { ReactComponent as EmojiOutlined } from './EmojiOutlined.svg';
import { ReactComponent as ErrorFilled } from './ErrorFilled.svg';
import { ReactComponent as ErrorOutlined } from './ErrorOutlined.svg';
import { ReactComponent as ExitToApp } from './ExitToApp.svg';
import { ReactComponent as Filter } from './Filter.svg';
import { ReactComponent as Folder } from './Folder.svg';
import { ReactComponent as Forum } from './Forum.svg';
import { ReactComponent as FramePersonOff } from './FramePersonOff.svg';
import { ReactComponent as FramePersonOn } from './FramePersonOn.svg';
import { ReactComponent as GroupOutlined } from './GroupOutlined.svg';
import { ReactComponent as HandFilled } from './HandFilled.svg';
import { ReactComponent as HandOutlined } from './HandOutlined.svg';
import { ReactComponent as HelpFilled } from './HelpFilled.svg';
import { ReactComponent as HelpOutlined } from './HelpOutlined.svg';
import { ReactComponent as HomeFilled } from './HomeFilled.svg';
import { ReactComponent as HomeOutlined } from './HomeOutlined.svg';
import { ReactComponent as Image } from './Image.svg';
import { ReactComponent as InfoFilled } from './InfoFilled.svg';
import { ReactComponent as InfoOutlined } from './InfoOutlined.svg';
import { ReactComponent as Lock } from './Lock.svg';
import { ReactComponent as Login } from './Login.svg';
import { ReactComponent as Logout } from './Logout.svg';
import { ReactComponent as Menu } from './Menu.svg';
import { ReactComponent as MicrophoneOffFilled } from './MicrophoneOffFilled.svg';
import { ReactComponent as MicrophoneOffOutlined } from './MicrophoneOffOutlined.svg';
import { ReactComponent as MicrophoneOn } from './MicrophoneOn.svg';
import { ReactComponent as MoreHorizontal } from './MoreHorizontal.svg';
import { ReactComponent as MoreVertical } from './MoreVertical.svg';
import { ReactComponent as PersonOutlined } from './PersonOutlined.svg';
import { ReactComponent as PhoneFilled } from './PhoneFilled.svg';
import { ReactComponent as Pin } from './Pin.svg';
import { ReactComponent as PlayCircle } from './PlayCircle.svg';
import { ReactComponent as PresentToAll } from './PresentToAll.svg';
import { ReactComponent as RadioButtonChecked } from './RadioButtonChecked.svg';
import { ReactComponent as RaisedHand } from './RaisedHand.svg';
import { ReactComponent as RecordVoiceOver } from './RecordVoiceOver.svg';
import { ReactComponent as Replay } from './Replay.svg';
import { ReactComponent as ScreenShare } from './ScreenShare.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Send } from './Send.svg';
import { ReactComponent as Settings } from './Settings.svg';
import { ReactComponent as SettingsAccount } from './SettingsAccount.svg';
import { ReactComponent as SettingsFilled } from './SettingsFilled.svg';
import { ReactComponent as ShareScreen } from './ShareScreen.svg';
import { ReactComponent as Speaker } from './Speaker.svg';
import { ReactComponent as Star } from './Star.svg';
import { ReactComponent as TriangleArrowDown } from './TriangleArrowDown.svg';
import { ReactComponent as TriangleArrowUp } from './TriangleArrowUp.svg';
import { ReactComponent as Upload } from './Upload.svg';
import { ReactComponent as Videocam } from './Videocam.svg';
import { ReactComponent as VideocamOff } from './VideocamOff.svg';
import { ReactComponent as VideocamOutlined } from './VideocamOutlined.svg';
import { ReactComponent as VisibilityOff } from './VisibilityOff.svg';
import { ReactComponent as VisibilityOn } from './VisibilityOn.svg';
import { ReactComponent as WarningFilled } from './WarningFilled.svg';
import { ReactComponent as WarningOutlined } from './WarningOutlined.svg';

export {
  AccessAlarmIcon,
  AddReactionIcon,
  ArticleOutlinedIcon,
  AssignmentIcon,
  AssignmentOutlinedIcon,
  BackHandOutlinedIcon,
  BarChartRoundedIcon,
  CalendarMonthIcon,
  CalendarMonthOutlinedIcon,
  CellTowerIcon,
  ChatBubbleOutlineIcon,
  ChatBubbleOutlineOutlinedIcon,
  CheckBoxOutlinedIcon,
  CommitIcon,
  ConstructionIcon,
  DashboardOutlinedIcon,
  FormatListNumberedIcon,
  ForumOutlinedIcon,
  FullscreenOutlinedIcon,
  Groups2OutlinedIcon,
  GroupsFilledIcon,
  GroupsOutlinedIcon,
  HighlightOffIcon,
  HubOutlinedIcon,
  InsertEmoticonOutlinedIcon,
  ManageAccountsIcon,
  ManageAccountsOutlinedIcon,
  PauseIcon,
  PeopleAltOutlinedIcon,
  PersonAddAlt1OutlinedIcon,
  PersonAddAltOutlinedIcon,
  PersonOffOutlinedIcon,
  PlayArrowIcon,
  QueryStatsOutlinedIcon,
  QuestionAnswerOutlinedIcon,
  RocketOutlinedIcon,
  SettingsInputAntennaIcon,
  SettingsOutlinedIcon,
  ShareIcon,
  SpeedIcon,
  StopIcon,
  SupportOutlinedIcon,
  TagFacesIcon,
  TagOutlinedIcon,
  TimelineOutlinedIcon,
  UnpublishedOutlinedIcon,
  VerifiedOutlinedIcon,
  VolumeDownIcon,
  VolumeUpIcon,
};
export const AddIcon = createSvgIcon(Add, 'Add');
export const ArrowRightIcon = createSvgIcon(ArrowRight, 'ArrowRight');
export const BarChartIcon = createSvgIcon(BarChart, 'BarChart');
export const BookSparkIcon = createSvgIcon(BookSpark, 'BookSpark');
export const CalendarIcon = createSvgIcon(Calendar, 'Calendar');
export const CancelPresentationFilledIcon = createSvgIcon(CancelPresentationFilled, 'CancelPresentationFilled');
export const ChartSelectIcon = createSvgIcon(ChartSelect, 'ChartSelect');
export const ChatIcon = createSvgIcon(Chat, 'Chat');
export const CheckFilledIcon = createSvgIcon(CheckFilled, 'CheckFilled');
export const CheckIcon = createSvgIcon(Check, 'Check');
export const ChevronDownIcon = createSvgIcon(ChevronDown, 'ChevronDown');
export const ChevronLeftIcon = createSvgIcon(ChevronLeft, 'ChevronLeft');
export const ChevronRightIcon = createSvgIcon(ChevronRight, 'ChevronRight');
export const ChevronUpIcon = createSvgIcon(ChevronUp, 'ChevronUp');
export const CloseIcon = createSvgIcon(Close, 'Close');
export const CopyIcon = createSvgIcon(Copy, 'Copy');
export const DeleteIcon = createSvgIcon(Delete, 'Delete');
export const DoubleArrowLeftIcon = createSvgIcon(DoubleArrowLeft, 'DoubleArrowLeft');
export const DownloadIcon = createSvgIcon(Download, 'Download');
export const DragIndicatorIcon = createSvgIcon(DragIndicator, 'DragIndicator');
export const DuplicateIcon = createSvgIcon(Duplicate, 'Duplicate');
export const EditIcon = createSvgIcon(Edit, 'Edit');
export const EmojiFilledIcon = createSvgIcon(EmojiFilled, 'EmojiFilled');
export const EmojiOutlinedIcon = createSvgIcon(EmojiOutlined, 'EmojiOutlined');
export const ErrorFilledIcon = createSvgIcon(ErrorFilled, 'ErrorFilled');
export const ErrorOutlinedIcon = createSvgIcon(ErrorOutlined, 'ErrorOutlined');
export const ExitToAppIcon = createSvgIcon(ExitToApp, 'ExitToApp');
export const FilterIcon = createSvgIcon(Filter, 'Filter');
export const FolderIcon = createSvgIcon(Folder, 'Folder');
export const ForumIcon = createSvgIcon(Forum, 'Forum');
export const FramePersonIcon = createSvgIcon(FramePersonOn, 'FramePerson');
export const FramePersonOffIcon = createSvgIcon(FramePersonOff, 'FramePersonOff');
export const HandFilledIcon = createSvgIcon(HandFilled, 'HandFilled');
export const HandOutlinedIcon = createSvgIcon(HandOutlined, 'HandOutlined');
export const HelpFilledIcon = createSvgIcon(HelpFilled, 'HelpFilled');
export const HelpOutlinedIcon = createSvgIcon(HelpOutlined, 'HelpOutlined');
export const HomeFilledIcon = createSvgIcon(HomeFilled, 'HomeFilled');
export const HomeOutlinedIcon = createSvgIcon(HomeOutlined, 'HomeOutlined');
export const ImageIcon = createSvgIcon(Image, 'Image');
export const InfoFilledIcon = createSvgIcon(InfoFilled, 'InfoFilled');
export const InfoOutlinedIcon = createSvgIcon(InfoOutlined, 'InfoOutlined');
export const LockIcon = createSvgIcon(Lock, 'Lock');
export const LoginIcon = createSvgIcon(Login, 'Login');
export const LogoutIcon = createSvgIcon(Logout, 'Logout');
export const MenuIcon = createSvgIcon(Menu, 'Menu');
export const MicrophoneOffOutlinedIcon = createSvgIcon(MicrophoneOffOutlined, 'MicrophoneOffOutlined');
export const MicrophoneOffFilledIcon = createSvgIcon(MicrophoneOffFilled, 'MicrophoneOffFilled');
export const MicrophoneOnIcon = createSvgIcon(MicrophoneOn, 'MicrophoneOn');
export const MoreHorizontalIcon = createSvgIcon(MoreHorizontal, 'MoreHorizontal');
export const MoreVerticalIcon = createSvgIcon(MoreVertical, 'MoreVertical');
export const PhoneFilledIcon = createSvgIcon(PhoneFilled, 'PhoneFilled');
export const PinIcon = createSvgIcon(Pin, 'Pin');
export const PlayCircleIcon = createSvgIcon(PlayCircle, 'PlayCircle');
export const PresentToAllIcon = createSvgIcon(PresentToAll, 'PresentToAll');
export const RadioButtonCheckedIcon = createSvgIcon(RadioButtonChecked, 'RadioButtonChecked');
export const RaisedHandIcon = createSvgIcon(RaisedHand, 'RaisedHand');
export const ReplayIcon = createSvgIcon(Replay, 'Replay');
export const ScreenShareIcon = createSvgIcon(ScreenShare, 'ScreenShare');
export const SendIcon = createSvgIcon(Send, 'Send');
export const SearchIcon = createSvgIcon(Search, 'Search');
export const SettingsAccountIcon = createSvgIcon(SettingsAccount, 'SettingsAccount');
export const SettingsFilledIcon = createSvgIcon(SettingsFilled, 'SettingsFilled');
export const SettingsIcon = createSvgIcon(Settings, 'Settings');
export const ShareScreenIcon = createSvgIcon(ShareScreen, 'ShareScreen');
export const SpeakerIcon = createSvgIcon(Speaker, 'Speaker');
export const StarIcon = createSvgIcon(Star, 'Star');
export const TriangleArrowDownIcon = createSvgIcon(TriangleArrowDown, 'TriangleArrowDown');
export const TriangleArrowUpIcon = createSvgIcon(TriangleArrowUp, 'TriangleArrowUp');
export const UploadIcon = createSvgIcon(Upload, 'Upload');
export const VideocamIcon = createSvgIcon(Videocam, 'Videocam');
export const VideocamOffIcon = createSvgIcon(VideocamOff, 'VideocamOff');
export const VideocamOutlinedIcon = createSvgIcon(VideocamOutlined, 'VideocamOutlined');
export const VisibilityOffIcon = createSvgIcon(VisibilityOff, 'VisibilityOff');
export const VisibilityOnIcon = createSvgIcon(VisibilityOn, 'VisibilityOn');
export const WarningFilledIcon = createSvgIcon(WarningFilled, 'WarningFilled');
export const WarningOutlinedIcon = createSvgIcon(WarningOutlined, 'WarningOutlined');
export const RecordVoiceOverIcon = createSvgIcon(RecordVoiceOver, 'RecordVoiceOver');
export const PersonOutlinedIcon = createSvgIcon(PersonOutlined, 'PersonOutlined');
export const GroupOutlinedIcon = createSvgIcon(GroupOutlined, 'GroupOutlined');
export const ArrowUpIcon = createSvgIcon(ArrowUp, 'ArrowUp');
export const BorderColorIcon = createSvgIcon(BorderColor, 'BorderColor');
