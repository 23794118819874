import React, { FC } from 'react';

import { Avatar, type AvatarProps, Palette } from '@mui/material';
import { blue, common, green, grey, orange, red } from 'common/theme/palette';

import AvatarBackground from './AvatarBackground.svg';

const AvatarBackgroundColorMap = {
  blue: blue[300],
  red: red[300],
  green: green[300],
  grey: grey[300],
  orange: orange[300],
  white: common.white,
} as const;

const AvatarColorMap = {
  blue: blue[600],
  red: red[600],
  green: green[600],
  grey: grey[600],
  orange: orange[600],
} as const;

export const UserAvatarColors = Object.values(AvatarColorMap);

export const UserAvatarBackgroundColors = Object.values(AvatarBackgroundColorMap);

export type UserAvatarProps = AvatarProps & {
  avatarLink?: string | null;
  name?: string;
  nameOverride?: string;
  dimensions?: number;
  color?: string;
  backgroundColor?: string;
  disableGradient?: boolean;
  noBackground?: boolean;
};

export const UserAvatar: FC<UserAvatarProps> = ({
  avatarLink,
  name,
  nameOverride,
  dimensions = 40,
  variant = 'circular',
  color,
  disableGradient,
  noBackground = false,
  backgroundColor: initialBackgroundColor = blue[300],
  ...props
}) => {
  const backgroundColor = avatarLink ? 'rgba(0,0,0,0)' : initialBackgroundColor;
  const getColor = (palette: Palette) => {
    if (noBackground) {
      return color;
    }
    if (!disableGradient) {
      return palette.getContrastText(backgroundColor);
    }
    return palette.text.primary;
  };

  return (
    <Avatar
      src={avatarLink ?? undefined}
      variant={variant}
      sx={({ palette }) => ({
        width: dimensions,
        height: dimensions,
        fontWeight: 500,
        backgroundColor: noBackground ? palette.common.white : backgroundColor,
        color: getColor(palette),
        backgroundImage: avatarLink || !disableGradient ? `url("${AvatarBackground}")` : undefined,
        '&&': {
          fontSize: dimensions / 2,
        },
        ...((backgroundColor === 'white' || noBackground) && {
          border: `1px solid ${palette.grey[300]}`,
        }),
      })}
      alt="User avatar"
      data-testid={avatarLink ? 'UserAvatar' : 'UserAvatar-empty'}
      {...props}
    >
      {nameOverride ?? name?.[0]?.toUpperCase()}
    </Avatar>
  );
};
