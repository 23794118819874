import { type Components, type Theme } from '@mui/material';

import { dark } from '../palette';
import { shadows } from '../shadows';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    PopperProps: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  },
  styleOverrides: {
    tooltip: ({ theme: { palette, spacing, typography } }) => ({
      backgroundColor: palette.grey[900],
      color: palette.grey[200],
      ...typography['natter-text-xs'],
      fontWeight: 400,
      maxWidth: 300,

      '& .MuiTypography-root': {
        color: palette.grey[200],
      },

      '&&.MuiTooltip-tooltip': {
        margin: 0,

        '&.dark': {
          minHeight: spacing(3),
          padding: spacing(0.25, 1),
          color: palette.common.white,
          backgroundColor: dark[100],
          boxShadow: shadows['dark-shadow-8'],
        },
      },
    }),
    tooltipArrow: ({ theme: { palette } }) => ({
      '& .MuiTooltip-arrow': {
        color: palette.grey[900],
      },
    }),
  },
};
