import { styled } from '@mui/material';
import { Button } from 'common/components';

export const StyledButton = styled(Button)(({ theme: { spacing, palette } }) => ({
  color: 'inherit',
  border: `1px solid ${palette.grey[300]}`,
  '& .MuiButton-startIcon': {
    marginRight: spacing(1.5),
  },
}));
