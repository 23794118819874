import React, { type FC, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { SentryRoute, SuspenseFallback } from 'common/components';
import { useInternetConnectionWatcher } from 'common/hooks';
import { lazyWithRetry } from 'common/utils';
import { AlertsContainer, ApiErrorAlertContainer } from 'modules/alerts/components';
import { ErrorHandler } from 'modules/app/components';
import { AppRoutes } from 'router';

import App from './App';
import { useIntercom } from './hooks';

const lazyLoadedPages = {
  TechCheck: lazyWithRetry(() => import('pages/TechCheck'), 'TechCheck'),
  PublicEventReport: lazyWithRetry(() => import('pages/PublicEventReport'), 'PublicEventReport'),
  PublicSurveyReport: lazyWithRetry(() => import('pages/PublicSurveyReport'), 'PublicSurveyReport'),
  SurveyPublic: lazyWithRetry(() => import('pages/Survey/SurveyPublic'), 'SurveyPublic'),
  EventPublic: lazyWithRetry(() => import('pages/PublicEventDetails'), 'PublicEventDetails'),
  CustomLogin: lazyWithRetry(() => import('pages/CustomLogin'), 'CustomLogin'),
};

export const PreApp: FC = () => {
  useIntercom();
  useInternetConnectionWatcher();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorHandler />}>
      <ApiErrorAlertContainer />
      <Suspense fallback={<SuspenseFallback />}>
        <Switch>
          <SentryRoute path={AppRoutes.EventPublic} component={lazyLoadedPages.EventPublic} />
          <SentryRoute path={AppRoutes.SurveyPublic} component={lazyLoadedPages.SurveyPublic} />
          <SentryRoute path={AppRoutes.PublicEventReport} component={lazyLoadedPages.PublicEventReport} />
          <SentryRoute path={AppRoutes.PublicSurveyReport} component={lazyLoadedPages.PublicSurveyReport} />
          <SentryRoute path={AppRoutes.TechCheck} component={lazyLoadedPages.TechCheck} />
          <SentryRoute path={AppRoutes.CustomLogin} component={lazyLoadedPages.CustomLogin} />

          <SentryRoute strict path="/" component={App} />
        </Switch>
      </Suspense>
      <AlertsContainer context="APP" />
    </Sentry.ErrorBoundary>
  );
};
