import { MutableRefObject, useEffect, useState } from 'react';

import { useElementSize } from './useElementSize';

export const useIsScrollbarActive = <T extends HTMLElement = HTMLDivElement>(): [
  MutableRefObject<T | null>,
  boolean,
] => {
  const [elementRef, { height }] = useElementSize<T>();
  const [isScrollbarActive, setIsScrollbarActive] = useState(false);

  useEffect(() => {
    if (!elementRef.current?.scrollHeight) return;

    setIsScrollbarActive(elementRef.current.scrollHeight > elementRef.current.getBoundingClientRect().height);
  }, [height, elementRef]);

  return [elementRef, isScrollbarActive];
};
