import React, { FC, ReactNode } from 'react';

import { Container, ContainerProps, TypographyProps } from '@mui/material';
import { Loadable } from 'common/components';
import { useBreakpoints } from 'common/hooks';
import { mergeSxProps } from 'common/theme';

import { PageHeader, type PageHeaderProps } from '../PageHeader';

type PageProps = Pick<PageHeaderProps, 'action' | 'title' | 'description' | 'onBackClick' | 'disableBackButton'> &
  Pick<ContainerProps, 'maxWidth' | 'disableGutters' | 'sx'> &
  Pick<TypographyProps, 'textAlign'> & {
    disableHeader?: boolean;
    disableMargin?: boolean;
    HeaderChildren?: ReactNode;
    isLoading?: boolean;
  };

export const Page: FC<PageProps> = ({
  children,
  action,
  title,
  description,
  maxWidth = false,
  disableGutters,
  textAlign,
  onBackClick,
  disableBackButton,
  disableHeader = false,
  disableMargin = false,
  sx,
  HeaderChildren,
  isLoading = false,
}) => {
  const { isMobile } = useBreakpoints();
  const shouldDisableGutters = maxWidth === 'hug' ? !isMobile : disableGutters;

  return (
    <Container
      component="main"
      maxWidth={maxWidth}
      sx={mergeSxProps(
        { pb: 7 },
        disableMargin ? { ml: 0, mr: 0 } : undefined,
        disableHeader ? { pt: 5 } : undefined,
        sx
      )}
      disableGutters={shouldDisableGutters}
    >
      {!disableHeader && (
        <PageHeader
          action={action}
          title={title}
          description={description}
          onBackClick={onBackClick}
          isFixedHeight={false}
          textAlign={textAlign}
          disableBackButton={disableBackButton}
        >
          {HeaderChildren}
        </PageHeader>
      )}
      <Loadable isLoading={isLoading}>{children}</Loadable>
    </Container>
  );
};
