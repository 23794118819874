import type { Choice } from './Choice';
import type { QuestionAssignmentDefinition } from './QuestionAssignment';
import { QuestionVariants } from './Variants';

export type AnswerOption = {
  id: number;
  content: string;
  questionId: number;
};

export type BasicQuestion = {
  type: QuestionVariants;
  questionId: number;
  title: string;
};

export type TextQuestion = BasicQuestion;

export type MultipleChoiceSingleSelectQuestion = BasicQuestion & {
  choices: Choice[];
};

export type MultipleChoiceMultipleSelectQuestion = BasicQuestion & {
  choices: Choice[];
};

export type RankingQuestion = MultipleChoiceSingleSelectQuestion & {
  randomizeChoices: boolean;
};

export type NPSQuestion = BasicQuestion & {
  minimumValue: number;
  maximumValue: number;
};

export type SliderQuestion = BasicQuestion & {
  minimumValue: number;
  minimumValueLabel: string | undefined;
  maximumValue: number;
  maximumValueLabel: string | undefined;
  incrementValue: number;
};

export type Question =
  | TextQuestion
  | MultipleChoiceSingleSelectQuestion
  | MultipleChoiceMultipleSelectQuestion
  | RankingQuestion
  | NPSQuestion
  | SliderQuestion;

type QuestionsWithChoices = MultipleChoiceSingleSelectQuestion | RankingQuestion | MultipleChoiceMultipleSelectQuestion;

export const isTextQuestion = (question: BasicQuestion): question is RankingQuestion =>
  question?.type === QuestionVariants.TextQuestion;

export const isMultipleChoiceSingleSelectQuestion = (question: BasicQuestion): question is RankingQuestion =>
  question?.type === QuestionVariants.MultipleChoiceSingleSelectQuestion;

export const isMultipleChoiceMultipleSelectQuestion = (
  question: BasicQuestion
): question is MultipleChoiceMultipleSelectQuestion =>
  question?.type === QuestionVariants.MultipleChoiceMultipleSelectQuestion;

export const isRankingQuestion = (question: BasicQuestion): question is RankingQuestion =>
  question?.type === QuestionVariants.RankingQuestion;

export const isNPSQuestion = (question: BasicQuestion): question is NPSQuestion =>
  question?.type === QuestionVariants.NPSQuestion;

export const isSliderQuestion = (question: BasicQuestion): question is SliderQuestion =>
  question?.type === QuestionVariants.SliderQuestion;

export const isQuestionWithChoices = (question: BasicQuestion): question is QuestionsWithChoices =>
  (question as QuestionsWithChoices)?.choices !== undefined;

export type QuestionDefinition<
  QuestionAssignment extends QuestionAssignmentDefinition<unknown> = QuestionAssignmentDefinition<unknown>,
> = {
  question: Question;
  questionAssignment: QuestionAssignment;
};
