import Keycloak from 'keycloak-js';
import { UrlNames } from 'modules/app/constants';
import ConfigService from 'modules/app/services/ConfigService';

interface KeycloakServiceAttributes {
  configService: ConfigService;
}

const KeycloakService = ({ configService }: KeycloakServiceAttributes) => {
  const instance = new Keycloak({
    realm: 'Natter',
    url: configService.getUrl(UrlNames.Auth),
    clientId: 'natter-frontend',
  });
  const { logout } = instance;
  instance.logout = () => logout({ redirectUri: window.location.origin });
  return instance;
};

export default KeycloakService;
