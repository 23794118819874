import React, { FC, ReactNode } from 'react';

import { Container, ContainerProps, Grid, Stack, SxProps, Typography } from '@mui/material';
import { NavigateBack } from 'common/components';

export const Page: FC<{
  title?: ReactNode;
  description?: string;
  marginTop?: number;
  actions?: ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  centerTitle?: boolean;
  sx?: SxProps;
  showNavigateBack?: boolean;
  navigateBackLabel?: string;
  onNavigateBack?(): void;
}> = ({
  title,
  description,
  children,
  marginTop = 1,
  actions,
  maxWidth,
  sx,
  centerTitle = false,
  showNavigateBack = false,
  navigateBackLabel,
  onNavigateBack,
}) => {
  const withHeader = title || description || actions;

  return (
    <Container maxWidth={maxWidth} sx={sx}>
      {withHeader && (
        <Stack mt={{ xs: 2, sm: showNavigateBack ? 4 : 5 }} gap={3} mb={3}>
          {showNavigateBack && <NavigateBack label={navigateBackLabel} onClick={onNavigateBack} />}
          <Stack
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={3}
            mb={{ xs: 2, sm: 0 }}
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          >
            <Stack flex={1} gap={2} alignItems={centerTitle ? 'center' : 'start'}>
              {typeof title === 'string' ? (
                <Typography variant="natter-display-sm" fontWeight={600} textAlign={centerTitle ? 'center' : 'left'}>
                  {title}
                </Typography>
              ) : (
                <>{title}</>
              )}
              {description && <Typography variant="natter-text-sm">{description}</Typography>}
            </Stack>
            {actions && (
              <Stack flexDirection="row" justifyContent="center" ml="auto" gap={3}>
                {actions}
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      <Grid container direction="column" wrap="nowrap" spacing={3} marginTop={{ xs: 0, sm: marginTop }}>
        <Grid item sx={{ '&&': { pt: 0 } }}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};
