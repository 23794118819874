import { createApi } from '@reduxjs/toolkit/query/react';
import { getApiBaseUrl } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { ValidateReportPinPayload } from './types';

export const insightsReportApi = createApi({
  reducerPath: 'insightsReportApi',
  tagTypes: ['REPORTS', 'SURVEY_REPORTS', 'REPORT_DEMOGRAPHICS', 'REPORT_ANSWERS'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v3') }),
  endpoints: (builder) => ({
    exportReport: builder.mutation<void, { reportId: number }>({
      query: ({ reportId }) => ({
        url: `/report/${reportId}/export`,
        method: 'POST',
      }),
    }),
    validateReportPin: builder.mutation<string | undefined, ValidateReportPinPayload>({
      query: ({ reportId, pin }) => ({
        url: `/report/${reportId}/validate-pin`,
        method: 'POST',
        data: { pin },
      }),
      transformResponse: (response: boolean, error, meta) => (response ? meta.pin : undefined),
    }),
  }),
});

export const { useExportReportMutation, useValidateReportPinMutation } = insightsReportApi;
