import React, { FC, createContext, useContext, useEffect, useState } from 'react';

import { Loading } from 'common/components';
import { useTimeout } from 'common/hooks';
import { logger } from 'common/services';
import { assert, isEmbedded, isLocal } from 'common/utils';
import { keycloak } from 'modules/keycloak';
import ErrorPage from 'pages/ErrorPage';

import { LoginEmbeddedSkeleton } from './components';

const AUTH_CONNECTION_TIMEOUT = 5_000;

type AuthContextContextValue = {
  keycloak: typeof keycloak;
  isAuthenticated: boolean;
};

export const AuthContext = createContext<AuthContextContextValue | undefined>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!keycloak.authenticated);
  const [isLoading, setIsLoading] = useState(true);
  const [authenticationError, setAuthenticationError] = useState<string>();

  useEffect(() => {
    keycloak
      .init({ onLoad: 'check-sso', checkLoginIframe: false, enableLogging: isLocal() })
      .then((authenticated) => {
        setIsAuthenticated(authenticated);

        if (isEmbedded()) {
          window.parent.postMessage({ type: 'AUTHENTICATED' }, window.location.origin);
        }
      })
      .catch((error) => {
        logger.error('Keycloak initialization failed', error);
        if (error instanceof Error) {
          setAuthenticationError(error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useTimeout(() => {
    if (isLoading && !isAuthenticated) {
      setAuthenticationError('Could not connect to auth service');
    }
  }, AUTH_CONNECTION_TIMEOUT);

  if (isEmbedded()) {
    return <LoginEmbeddedSkeleton />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (authenticationError) {
    return <ErrorPage message={authenticationError} />;
  }

  return <AuthContext.Provider value={{ keycloak, isAuthenticated }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  assert(ctx, 'useAuth must be used within a AuthProvider');
  return ctx;
};
