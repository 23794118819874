import { MeetingWSActions, MeetingWSMessages } from 'common/constants';
import { EventMetricsPayload, MeetingGuestApiPayload } from 'domain/event';
import { QuestionAssignmentWSPayload } from 'store/features/questions';
import { createMeetingWSAction, createMeetingWSMessage } from 'store/utils';

import { AudienceCountUpdatedPayload } from '../types/event.types';
import type {
  MeetingJoinPayload,
  MeetingStartedPayload,
  MeetingWSParams,
  NatteringJoinPayload,
  NatteringSkipPayload,
} from '../types/meeting.types';

export const meetingStarted_WS = createMeetingWSMessage<MeetingStartedPayload>(MeetingWSMessages.EventStarted);
export const guestExitedMeeting_WS = createMeetingWSMessage<MeetingGuestApiPayload>(
  MeetingWSMessages.GuestExitedMeeting
);
export const meetingGuestHandUp_WS = createMeetingWSMessage<MeetingGuestApiPayload>(MeetingWSMessages.GuestHandUp);
export const meetingGuestHandDown_WS = createMeetingWSMessage<MeetingGuestApiPayload>(MeetingWSMessages.GuestHandDown);
export const meetingGuestHandLoweredBy_WS = createMeetingWSMessage<{
  loweredByUserName: string;
  loweredByUserId: number;
}>(MeetingWSMessages.GuestHandLoweredBy);
export const eventBigMeetingModeEnabled_WS = createMeetingWSMessage(MeetingWSMessages.BigMeetingModeEnabled);
export const audienceCountUpdated_WS = createMeetingWSMessage<AudienceCountUpdatedPayload>(
  MeetingWSMessages.AudienceCountUpdated
);
export const meetingMetricsUpdated_WS = createMeetingWSMessage<EventMetricsPayload>(
  MeetingWSMessages.EventMetricsUpdated
);
export const bmmMeetingGuestHandUp_WS = createMeetingWSMessage<MeetingGuestApiPayload>(
  MeetingWSMessages.BmmGuestHandUp
);
export const bmmMeetingGuestHandDown_WS = createMeetingWSMessage<MeetingGuestApiPayload>(
  MeetingWSMessages.BmmGuestHandDown
);
export const bmmMeetingGuestHandUpList_WS = createMeetingWSMessage<MeetingGuestApiPayload[]>(
  MeetingWSMessages.BmmGuestHandUpList
);
export const userMuted_WS = createMeetingWSMessage<{
  mutedByName: string;
  mutedByUserId: number;
}>(MeetingWSMessages.UserMuted);

export const sendHandUp_WS = createMeetingWSAction<MeetingWSParams>(MeetingWSActions.RaiseHand);
export const sendHandDown_WS = createMeetingWSAction<MeetingWSParams>(MeetingWSActions.LowerHand);
export const sendOtherUSerHandDown_WS = createMeetingWSAction<MeetingWSParams<{ userId: number }>>(
  MeetingWSActions.LowerOtherUserHand
);
export const meetingJoin_WS = createMeetingWSAction<MeetingWSParams<MeetingJoinPayload>>(MeetingWSActions.Join);
export const natteringJoin_WS = createMeetingWSAction<MeetingWSParams<NatteringJoinPayload>>(
  MeetingWSActions.JoinNattering
);
export const natteringSkip_WS = createMeetingWSAction<MeetingWSParams<NatteringSkipPayload>>(
  MeetingWSActions.SkipNattering
);
export const muteUser_WS = createMeetingWSAction<MeetingWSParams<{ userId: number }>>(MeetingWSActions.MuteUser);

export const questionAssignment_WS = createMeetingWSMessage<{ questionsAssignments: QuestionAssignmentWSPayload[] }>(
  MeetingWSMessages.QuestionAssignment
);
