import React, { useCallback, useMemo, useState } from 'react';

import { Breakpoint } from '@mui/material';

import { Button } from '../Buttons/Button';
import { ConfirmationModal } from './ConfirmationModal';

interface CallbackI<T = unknown> {
  (data?: T): void;
}

interface WithCloseCallback {
  (close: CallbackI): JSX.Element;
}

interface ModalPropsI {
  title?: string | JSX.Element | WithCloseCallback;
  content?: string | JSX.Element | WithCloseCallback;
  maxWidth?: false | Breakpoint;
  confirmLabel?: string;
  confirmColor?: 'primary' | 'error';
  darkTheme?: boolean;
}

interface ConfirmationModalPropsI extends ModalPropsI {
  confirmCallback: CallbackI;
}

const getConfirmationModalProps = ({
  confirmCallback,
  title,
  content,
  maxWidth,
  confirmLabel = 'Confirm',
  confirmColor = 'primary',
  darkTheme,
}: ConfirmationModalPropsI) => ({
  dialogProps: undefined,
  dialogTitleProps: {},
  dialogActionsProps: {},
  title,
  content,
  maxWidth,
  darkTheme,
  actions: (close: () => void) => (
    <>
      <Button
        variant="outlined-grey"
        data-testid="cancel"
        onClick={() => {
          close();
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        data-testid="confirm"
        color={confirmColor}
        onClick={() => {
          confirmCallback();
          close();
        }}
      >
        {confirmLabel}
      </Button>
    </>
  ),
});

export function useConfirmationModal<T = unknown>(
  modalProps: ModalPropsI | ((data?: T) => ModalPropsI),
  confirmCallback: CallbackI<T>
): [CallbackI<T>, React.JSX.Element, CallbackI] {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T>();

  const modalData = useMemo(
    () =>
      getConfirmationModalProps({
        ...(typeof modalProps === 'function' ? modalProps(data) : modalProps),
        confirmCallback: () => confirmCallback(data),
      }),
    [modalProps, confirmCallback, data]
  );

  const openCallback = useCallback((callbackData?: T) => {
    setData(callbackData);
    setIsOpen(true);
  }, []);

  const closeCallback = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ModalComponent = useMemo(
    () => <ConfirmationModal isOpen={isOpen} {...modalData} close={closeCallback} />,
    [isOpen, modalData, closeCallback]
  );

  return [openCallback, ModalComponent, closeCallback];
}
