import React, { ComponentType, FC } from 'react';

import { Tooltip, type TooltipProps } from '../Tooltip';

type Props = {
  TooltipProps?: Omit<TooltipProps, 'children'>;
};

export const withTooltip =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P & Props> =>
  ({ TooltipProps, ...props }: Props & P) =>
    TooltipProps ? (
      <Tooltip {...TooltipProps}>
        <WrappedComponent {...(props as P)} />
      </Tooltip>
    ) : (
      <WrappedComponent {...(props as P)} />
    );
