import { createSelector } from '@reduxjs/toolkit';
import { ChatState } from 'domain/Chat';
import type { RootState } from 'store';
import { selectIsConnectedToRoom } from 'store/features/socket';

import { selectActiveEventId } from '../event';
import { messagesAdapter } from './chatSlice';

export const { selectAll: selectMessages } = messagesAdapter.getSelectors<RootState>((state) => state.chat.messages);

export const selectUnreadMessagesCount = (state: RootState) => state.chat.unreadMessagesCount;

const selectSelf = (state: RootState) => state;

const selectIsConnected = createSelector([selectActiveEventId, selectSelf], (eventId, state) =>
  selectIsConnectedToRoom(state, `/meeting/${eventId}`)
);

export const selectChatState = createSelector([selectIsConnected], (isConnected) =>
  isConnected ? ChatState.Ready : ChatState.Error
);

export const selectIsChatActive = (state: RootState) => state.chat.isChatActive;
export const selectIsChatScrolledToBottom = (state: RootState) => state.chat.isChatScrolledToBottom;
