import React, {
  ChangeEventHandler,
  ElementType,
  FC,
  FocusEventHandler,
  MouseEventHandler,
  RefCallback,
  useState,
} from 'react';

import { Box, TypographyProps } from '@mui/material';
import { Tooltip } from 'common/components/Tooltip';
import { useTextInputAutoFocus } from 'common/hooks/useTextInputAutoFocus';

import { TextFieldProps } from '../TextField';
import { InlineEditorActive, InlineEditorInactive } from './InlineEditorInput.styled';

export type InlineEditorInputProps = {
  inputProps?: TextFieldProps;
  component?: ElementType;
  noWrap?: boolean;
  typographyVariant?: TypographyProps['variant'];
  inputRef?: RefCallback<HTMLInputElement | HTMLTextAreaElement>;
} & TextFieldProps;

const getInputWidth = (value: unknown, fullWidth: boolean, minChars = 15) =>
  !fullWidth && typeof value === 'string' ? `${Math.max(value.length, minChars)}ch` : undefined;

export const InlineEditorInput: FC<InlineEditorInputProps> = ({
  typographyVariant = 'natter-text-sm',
  component = 'div',
  noWrap = false,
  disabled = false,
  placeholder,
  value,
  onBlur,
  onChange,
  fullWidth = true,
  sx,
  inputRef,
  ...inputProps
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputSize, setInputSize] = useState<string | undefined>(getInputWidth(value, fullWidth, placeholder?.length));

  const { setAutoFocusInput } = useTextInputAutoFocus();

  const enterEditMode = () => !disabled && setIsEditMode(true);
  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setIsEditMode(false);
    onBlur?.(event);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    onChange?.(event);
    setInputSize(getInputWidth(event.target.value, fullWidth, placeholder?.length));
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (disabled) return;
    event.stopPropagation();
  };

  return (
    <Box
      width={fullWidth ? '100%' : inputSize}
      flex={fullWidth ? 1 : undefined}
      maxWidth="100%"
      minWidth={0}
      fontWeight={500}
      sx={sx}
      flexWrap="nowrap"
      onClick={handleClick}
    >
      {isEditMode ? (
        <InlineEditorActive
          typographyVariant={typographyVariant}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={handleBlur}
          fullWidth
          value={value}
          onChange={handleChange}
          inputProps={{
            ref: (el: HTMLInputElement | HTMLTextAreaElement) => {
              setAutoFocusInput(el);
              inputRef?.(el);
            },
          }}
          {...inputProps}
        />
      ) : (
        <Tooltip title={disabled ? '' : 'Rename'}>
          <InlineEditorInactive
            data-testid="InlineEditorInput-title"
            disabled={disabled}
            variant={typographyVariant}
            fontWeight="inherit"
            noWrap={noWrap}
            component={component}
            onClick={enterEditMode}
          >
            {value || placeholder}
          </InlineEditorInactive>
        </Tooltip>
      )}
    </Box>
  );
};
