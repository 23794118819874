import { exhaustiveCheck } from 'common/utils';
import { EventWizardStep } from 'pages/EventWizard/constants';

import { EventWizardMode } from '../types';

export const getEventWizardInitialActiveStep = (mode: EventWizardMode) => {
  switch (mode) {
    case 'create':
      return EventWizardStep.EntrySurvey;
    case 'repeat':
      return EventWizardStep.EventDetails;
    case 'edit':
      return EventWizardStep.EventDetails;
    case 'template':
      return EventWizardStep.EventDetails;
    default:
      exhaustiveCheck(mode);
  }
};
