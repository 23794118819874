import React, { ComponentType, FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLocalStorage } from 'common/hooks';
import { isEmbedded } from 'common/utils';

export const withRedirectToEventFromLocalStorage =
  <Props extends object>(WrappedComponent: ComponentType<Props>): FC<Props> =>
  (props) => {
    const { data: redirectUri, removeStoredData } = useLocalStorage({ key: 'redirectUri' });
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
      if (isEmbedded() || !redirectUri) {
        return;
      }

      if (redirectUri === pathname) {
        removeStoredData();
        return;
      }

      if (pathname === '/') {
        history.replace(redirectUri);
        removeStoredData();
      }
    }, [pathname, redirectUri, history, removeStoredData]);

    return <WrappedComponent {...(props as Props)} />;
  };
