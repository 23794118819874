import React, { FC, MouseEventHandler, ReactNode, useState } from 'react';

import { Stack, SxProps, Theme } from '@mui/material';
import { mergeSxProps } from 'common/theme';
import { ChevronDownIcon, MoreVerticalIcon } from 'icons';

import { Menu, MenuProps } from '../../Menu';
import type { IconButtonVariant } from '../IconButton/types';
import { IconButtonWithTooltip, IconButtonWithTooltipProps } from '../IconButtonWithTooltip';

type Props = Omit<IconButtonWithTooltipProps, 'title' | 'ref'> &
  Pick<MenuProps, 'anchorOrigin' | 'transformOrigin' | 'items' | 'activeIndex'> & {
    'data-testid'?: string;
    menuSx?: SxProps<Theme> | undefined;
    variant?: IconButtonVariant;
    Icon?: ReactNode;
  } & {
    title?: string;
    showChevron?: boolean;
    onOpenChange?(value: boolean): void;
  };

export const ButtonMenu: FC<Props> = ({
  anchorOrigin,
  transformOrigin,
  menuSx,
  isLoading,
  size,
  variant = 'text',
  Icon = <MoreVerticalIcon />,
  color = variant === 'text' ? 'text' : undefined,
  title = 'More actions',
  TooltipProps,
  showChevron,
  sx,
  disabled,
  onOpenChange,
  ...props
}) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
    onOpenChange?.(true);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <IconButtonWithTooltip
        disabled={disabled}
        onClick={handleClick}
        variant={variant}
        size={size}
        className={anchor ? 'active' : undefined}
        color={color}
        title={title}
        TooltipProps={{ placement: 'bottom-end', ...TooltipProps }}
        isLoading={isLoading}
        data-testid={props['data-testid']}
        sx={mergeSxProps(
          ({ palette }) => ({
            width: showChevron ? 'initial' : undefined,
            ...(palette.mode === 'dark' && {
              color: palette.common.white,
            }),
          }),
          sx
        )}
      >
        <Stack direction="row" alignItems="center" gap={0.5}>
          {Icon}
          {showChevron && <ChevronDownIcon />}
        </Stack>
      </IconButtonWithTooltip>

      <Menu
        sx={menuSx}
        anchor={anchor}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onTransitionExited={() => onOpenChange?.(false)}
        {...props}
      />
    </>
  );
};
