import React, { FC, MouseEventHandler, ReactNode, useState } from 'react';

import { Menu, MenuProps } from 'common/components/Menu';
import { NatterDarkThemeProvider } from 'common/hocs';
import { mergeSxProps } from 'common/theme';
import { MoreVerticalIcon } from 'icons';

import { ActionButton, ActionButtonProps } from './ActionButton';

type Props = Omit<MenuProps, 'anchor' | 'onClose'> & {
  actionButtonProps?: Omit<ActionButtonProps, 'onClick' | 'label' | 'Icon'>;
} & { label: string; Icon?: ReactNode };

export const ActionMenuButton: FC<Props> = ({
  label,
  Icon,
  actionButtonProps,
  sx,
  anchorOrigin,
  transformOrigin,
  slotProps,
  ...props
}) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <ActionButton label={label} onClick={handleClick} {...actionButtonProps}>
        {Icon ?? <MoreVerticalIcon fontSize="medium" />}
      </ActionButton>

      <NatterDarkThemeProvider>
        <Menu
          anchor={anchor}
          onClose={handleClose}
          anchorOrigin={anchorOrigin ?? { vertical: 'top', horizontal: 'right' }}
          transformOrigin={transformOrigin ?? { vertical: 'bottom', horizontal: 'right' }}
          slotProps={slotProps ?? { paper: { sx: { minWidth: 280, marginTop: -1 } } }}
          sx={mergeSxProps(
            ({ zIndex }) => ({
              zIndex: zIndex.snackbar + 1,
            }),
            sx
          )}
          {...props}
        />
      </NatterDarkThemeProvider>
    </>
  );
};
