import { Paper, styled } from '@mui/material';
import { shadows } from 'common/theme';
import { omitForwardedProps } from 'common/utils';

type WrapperProps = {
  bordered?: boolean;
  isLoading?: boolean;
};

export const Wrapper = styled(Paper, {
  shouldForwardProp: omitForwardedProps<WrapperProps>('isLoading', 'bordered'),
})<WrapperProps>(({ theme: { palette, shape, spacing, breakpoints }, bordered, isLoading }) => ({
  padding: spacing(4),
  width: 482,
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  flexShrink: 0,
  overflow: 'hidden',
  border: 'none',
  ...(bordered && {
    borderRadius: shape.borderRadius * 2,
    background: palette.background.paper,
    border: `1px solid ${palette.grey[200]}`,
    boxShadow: shadows['light-shadow-8'],
  }),
  ...(isLoading && {
    height: 400,

    '& iframe': {
      visibility: 'hidden',
    },
  }),
  [breakpoints.down('sm')]: {
    width: '100%',
    padding: 0,
  },
}));

export const StyledIframe = styled('iframe')({
  border: 'none',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  '&&::-webkit-scrollbar': {
    display: 'none',
  },
});
