import { BroadcastWSActions, BroadcastWSMessages } from 'common/constants';
import {
  AgoraCloudPlayerInfo,
  BroadcastConnectionInfo,
  BroadcastDemoteSpeakerApiPayload,
  BroadcastSpeakerApiPayload,
  HandleStageInvitationParams,
  ScreenshareDetailsApiPayload,
  StageInvitationBasicInfo,
} from 'domain/Broadcast';
import { MeetingGuestApiPayload } from 'domain/event';
import { createMeetingWSAction, createMeetingWSMessage } from 'store/utils';

import {
  BroadcastDemoteUserParams,
  MeetingWSParams,
  SendBroadcastStageInvitationPayload,
  StageInvitationAcceptedPayload,
  StageInvitationRejectedPayload,
  StageInvitationSentPayload,
  StartBroadcastPayload,
  StartNatteringPayload,
  StartScreensharingPayload,
  ToggleChatModerationParams,
} from '../types';

export const broadcastStarted_WS = createMeetingWSMessage<BroadcastConnectionInfo>(BroadcastWSMessages.Started);
export const broadcastStopped_WS = createMeetingWSMessage(BroadcastWSMessages.Stopped);
export const stageInvitationReceived_WS = createMeetingWSMessage<StageInvitationBasicInfo>(
  BroadcastWSMessages.StageInvitationReceived
);
export const stageInvitationAccepted_WS = createMeetingWSMessage<StageInvitationAcceptedPayload>(
  BroadcastWSMessages.StageInvitationAccepted
);
export const stageInvitationSent_WS = createMeetingWSMessage<StageInvitationSentPayload>(
  BroadcastWSMessages.StageInvitationSent
);
export const stageInvitationRejected_WS = createMeetingWSMessage<StageInvitationRejectedPayload>(
  BroadcastWSMessages.StageInvitationRejected
);
export const broadcastVideoViaUrlPlayed_WS = createMeetingWSMessage<AgoraCloudPlayerInfo>(
  BroadcastWSMessages.VideoViaUrlPlayed
);
export const broadcastVideoViaUrlStopped_WS = createMeetingWSMessage<{ message?: string; callerUserId: number }>(
  BroadcastWSMessages.VideoViaUrlStopped
);
export const broadcastVideoViaUrlPaused_WS = createMeetingWSMessage(BroadcastWSMessages.VideoViaUrlPaused);
export const broadcastVideoViaUrlResumed_WS = createMeetingWSMessage(BroadcastWSMessages.VideoViaUrlResumed);
export const screensharingStarted_WS = createMeetingWSMessage<ScreenshareDetailsApiPayload>(
  BroadcastWSMessages.ScreensharingStarted
);
export const screensharingStopped_WS = createMeetingWSMessage(BroadcastWSMessages.ScreensharingStopped);
export const audienceJoined_WS = createMeetingWSMessage<MeetingGuestApiPayload>(BroadcastWSMessages.AudienceJoined);
export const audienceJoinedList_WS = createMeetingWSMessage<MeetingGuestApiPayload[]>(
  BroadcastWSMessages.AudienceJoinedList
);
export const broadcastDemoted_WS = createMeetingWSMessage(BroadcastWSMessages.Demoted);
export const broadcastGuestDemoted_WS = createMeetingWSMessage<BroadcastDemoteSpeakerApiPayload>(
  BroadcastWSMessages.GuestDemoted
);
export const broadcastPromoted_WS = createMeetingWSMessage<{ publisherAccessToken: string }>(
  BroadcastWSMessages.Promoted
);
export const broadcastGuestPromoted_WS = createMeetingWSMessage<BroadcastSpeakerApiPayload>(
  BroadcastWSMessages.GuestPromoted
);
export const broadcastGuestPromotedList_WS = createMeetingWSMessage<BroadcastSpeakerApiPayload[]>(
  BroadcastWSMessages.GuestPromotedList
);
export const broadcastChatModerationToggled_WS = createMeetingWSMessage<ToggleChatModerationParams>(
  BroadcastWSMessages.ChatModerationToggled
);

export const startNattering_WS = createMeetingWSAction<MeetingWSParams<StartNatteringPayload>>(
  BroadcastWSActions.StartNattering
);
export const startBroadcast_WS = createMeetingWSAction<MeetingWSParams<StartBroadcastPayload>>(
  BroadcastWSActions.Start
);
export const broadcastPromoteOwner_WS = createMeetingWSAction<MeetingWSParams>(BroadcastWSActions.PromoteCo);
export const broadcastDemoteUser_WS = createMeetingWSAction<MeetingWSParams<BroadcastDemoteUserParams>>(
  BroadcastWSActions.Demote
);
export const sendBroadcastStageInvitation_WS = createMeetingWSAction<
  MeetingWSParams<SendBroadcastStageInvitationPayload>
>(BroadcastWSActions.SendStageInvitation);
export const acceptBroadcastStageInvitation_WS = createMeetingWSAction<MeetingWSParams<HandleStageInvitationParams>>(
  BroadcastWSActions.AcceptStageInvitation
);
export const rejectBroadcastStageInvitation_WS = createMeetingWSAction<MeetingWSParams<HandleStageInvitationParams>>(
  BroadcastWSActions.RejectStageInvitation
);
export const startScreensharing_WS = createMeetingWSAction<MeetingWSParams<StartScreensharingPayload>>(
  BroadcastWSActions.StartSharing
);
export const stopScreensharing_WS = createMeetingWSAction<MeetingWSParams>(BroadcastWSActions.StopSharing);

export const startRecording_WS = createMeetingWSAction(BroadcastWSActions.StartRecording);
export const stopRecording_WS = createMeetingWSAction(BroadcastWSActions.StopRecording);
export const recordingStarted_WS = createMeetingWSMessage(BroadcastWSMessages.RecordingStarted);
export const recordingStopped_WS = createMeetingWSMessage(BroadcastWSMessages.RecordingStopped);
