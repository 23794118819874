import React, { FC } from 'react';

import { Tooltip } from 'common/components/Tooltip';
import { InfoOutlinedIcon } from 'icons';

type Props = {
  color?: string;
};

export const Explanation: FC<Props> = ({ color, children }) => (
  <Tooltip title={children} placement="bottom">
    <InfoOutlinedIcon
      sx={({ palette }) => ({
        color: color ?? palette.grey[500],
        height: 'auto',
        width: 16,
        verticalAlign: 'text-top',
        mt: 0.125,
      })}
    />
  </Tooltip>
);
