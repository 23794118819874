import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'common/components/Buttons/IconButton';
import { Tooltip } from 'common/components/Tooltip';
import { useCopyToClipboard, useTimeout } from 'common/hooks';
import { CopyIcon } from 'icons';

const COPY_TOOLTIP_TIMEOUT = 1_000;

type CopyButtonProps = {
  value: string;
};

export const CopyButton: FC<CopyButtonProps> = ({ value }) => {
  const [tooltipText, setTooltipText] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: 'notifications' });

  const { reset } = useTimeout(() => setOpen(false), COPY_TOOLTIP_TIMEOUT);

  const { copyToClipboard } = useCopyToClipboard({
    value,
    onSuccess: () => {
      setTooltipText(t('copiedToClipboard'));
      setOpen(true);
      reset();
    },
    onError: () => {
      setTooltipText(t('copyToClipboardFailed'));
      setOpen(true);
      reset();
    },
  });

  return (
    <Tooltip title={tooltipText} open={open} placement="top">
      <IconButton variant="text" onClick={copyToClipboard}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
};
