import { datadogRum } from '@datadog/browser-rum';

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: '0e7b150d-d0c4-4736-a9c2-3eb1cfd0b8ff',
    clientToken: 'pub3a66853eb4f9ffa32a6ed2c986d9e8db',
    site: 'datadoghq.com',
    service: 'natter-frontend',
    env: window._env_.REACT_APP_ENVIRONMENT,
    version: process.env.REACT_APP_TAG,
    sessionSampleRate: window._env_.DATADOG_SESSION_SAMPLE_RATE ? Number(window._env_.DATADOG_SESSION_SAMPLE_RATE) : 1,
    sessionReplaySampleRate: window._env_.DATADOG_SESSION_SAMPLE_RATE
      ? Number(window._env_.DATADOG_SESSION_SAMPLE_RATE)
      : 1,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
