import { CSSProperties } from 'react';

import { Palette, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  backgroundColor: CSSProperties['backgroundColor'] | ((palette: Palette) => CSSProperties['backgroundColor']);
  color?: CSSProperties['color'];
  isRounded?: boolean;
};

export const Badge = styled('span', {
  name: 'Badge',
  shouldForwardProp: omitForwardedProps<Props>('backgroundColor', 'color', 'isRounded'),
})<Props>(({ theme: { palette, spacing }, backgroundColor, color, isRounded }) => {
  const backgroundColorValue = typeof backgroundColor === 'function' ? backgroundColor(palette) : backgroundColor;

  return {
    display: 'block',
    width: 'fit-content',
    padding: spacing(0, isRounded ? 0.75 : 0.5),
    borderRadius: isRounded ? 8 : 3,
    backgroundColor: backgroundColorValue,
    color: color ?? palette.getContrastText(backgroundColorValue as string),
    fontSize: '12px',
    lineHeight: '1rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    textAlign: 'center',
  };
});
