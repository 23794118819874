import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { useBreakpoints, useToggleState } from 'common/hooks';
import { ChevronDownIcon, ChevronUpIcon } from 'icons';

import { Button } from '../Buttons';
import { LinkifyText } from '../LinkifyText';

type Props = {
  text: string;
  thresholdMap?: {
    mobile: number;
    tablet: number;
    desktop: number;
  };
  typographyProps?: TypographyProps;
};

export const ShowMoreBox: FC<Props> = ({
  text,
  thresholdMap = { mobile: 125, tablet: 250, desktop: 500 },
  typographyProps,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'showMoreBox' });
  const { isMobile, isTablet } = useBreakpoints();
  const maxShortTextLength = (() => {
    if (isMobile) return thresholdMap.mobile;
    if (isTablet) return thresholdMap.tablet;
    return thresholdMap.desktop;
  })();
  const isShortText = text.length < maxShortTextLength;
  const longTextRef = useRef<HTMLSpanElement>(null);

  const [isExpanded, toggleExpanded] = useToggleState(false);
  const longTextToDisplay = isExpanded ? text : `${text.slice(0, maxShortTextLength)}...`;

  useEffect(() => {
    if (isExpanded) {
      longTextRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isExpanded]);

  return isShortText ? (
    <Typography sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }} {...typographyProps}>
      <LinkifyText>{text}</LinkifyText>
    </Typography>
  ) : (
    <Stack gap={2}>
      <Typography sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }} ref={longTextRef} {...typographyProps}>
        <LinkifyText>{longTextToDisplay}</LinkifyText>
      </Typography>
      <Box>
        {isExpanded ? (
          <Button
            onClick={toggleExpanded}
            variant="text-no-padding"
            endIcon={<ChevronUpIcon />}
            data-testid="ShowMoreBox-button-collapse"
          >
            {t('showLessButtonLabel')}
          </Button>
        ) : (
          <Button
            onClick={toggleExpanded}
            variant="text-no-padding"
            endIcon={<ChevronDownIcon />}
            data-testid="ShowMoreBox-button-expand"
          >
            {t('showMoreButtonLabel')}
          </Button>
        )}
      </Box>
    </Stack>
  );
};
