import { createAsyncThunk } from '@reduxjs/toolkit';
import { EventImageParams, ImageType } from 'domain/event';
import { conformFormImages, filterOutEmptyAndAlreadyUploadedImages, mapImageToApi } from 'event/mappers';
import { union } from 'lodash';
import { find, flow, prop } from 'lodash/fp';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation';
import { fetchAttachmentAsFile } from 'store/apis/attachments/utils';
import { eventApi, eventSeriesApi } from 'store/apis/event';

export const updateImages = createAsyncThunk<
  number[],
  { eventId: number; seriesId?: number; values: EventWizardFormSerializedValues }
>('[Event Wizard] Update event questions', async ({ eventId, seriesId, values }, { dispatch }) => {
  const { eventImage, sponsors, defaultEventImage } = values;
  const selectedImages = conformFormImages({ eventImage, sponsors });
  const imagesToUpload: EventImageParams[] = filterOutEmptyAndAlreadyUploadedImages(selectedImages).map(mapImageToApi);
  const isEventImageUploaded = !!eventImage?.id;

  if (!isEventImageUploaded && !find({ imageType: ImageType.EventImage })(imagesToUpload) && defaultEventImage) {
    const image = await fetchAttachmentAsFile(defaultEventImage);
    imagesToUpload.push({
      image,
      imageType: ImageType.EventImage,
    });
  }

  const apiCalls = imagesToUpload.map((imageObj) => {
    if (seriesId) {
      return dispatch(eventSeriesApi.endpoints.postEventSeriesImage.initiate({ seriesId, ...imageObj })).unwrap();
    }
    return dispatch(eventApi.endpoints.postEventImage.initiate({ id: eventId, ...imageObj })).unwrap();
  });

  const uploadedImages = await Promise.all(apiCalls);

  const alreadyUploadedImageIds = selectedImages.map(flow(prop('image'), prop('id'))).filter(Boolean) as Array<number>;
  const newImageIds = uploadedImages.map(prop('id')) as Array<number>;
  return union(alreadyUploadedImageIds, newImageIds);
});
