import { createAsyncThunk } from '@reduxjs/toolkit';
import { logger } from 'common/services';
import { orderBy } from 'lodash';
import { RootState } from 'store';
import { selectMyCommunityId } from 'store/apis/community';
import { eventApi, eventSeriesApi } from 'store/apis/event';
import { addAppErrorMessage } from 'store/features/alerts';

import { EventWizardFormSerializedValues } from '../../validation';
import { CreateEventEntitiesArgs } from '../types';
import { updateImages } from './updateImages';
import { updateQuestions } from './updateQuestions';

export const createEvent = createAsyncThunk<{ eventId: number }, EventWizardFormSerializedValues>(
  '[Event Wizard] Create Event',
  async (formValues, { dispatch }) => {
    const { eventId, seriesId } = await dispatch(initializeNewEvent(formValues)).unwrap();
    await dispatch(createEventEntities({ formValues, eventId, seriesId })).unwrap();
    return { eventId };
  }
);

const initializeNewEvent = createAsyncThunk<{ eventId: number; seriesId?: number }, EventWizardFormSerializedValues>(
  '[Event Wizard] Initialize New Event',
  async (payload, { dispatch, getState }) => {
    const communityId = selectMyCommunityId(getState() as RootState);

    if (!communityId) {
      dispatch(addAppErrorMessage('Error occurred while creating an Event'));
      logger.error('Community ID missing while creating an Event');
      throw new Error('Community ID missing while creating an Event');
    }

    if (payload.schedule.length > 1) {
      const { events, id } = await dispatch(
        eventSeriesApi.endpoints.createEventSeries.initiate({
          ...payload,
          schedule: orderBy(payload.schedule, 'startTime', 'asc'),
          relatedImageIds: [],
          communityId,
        })
      ).unwrap();
      return { eventId: events[0].id, seriesId: id };
    }

    const { id } = await dispatch(
      eventApi.endpoints.createEvent.initiate({ ...payload, relatedImageIds: [], communityId })
    ).unwrap();
    return { eventId: id };
  }
);

export const createEventEntities = createAsyncThunk<void, CreateEventEntitiesArgs>(
  '[Event Wizard] Create Event Entities',
  async ({ formValues, eventId, seriesId }, { dispatch }) => {
    await dispatch(updateQuestions({ eventId, seriesId, values: formValues })).unwrap();
    await dispatch(updateImages({ eventId, seriesId, values: formValues })).unwrap();
  }
);
