import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { disabled_WS, enabledUntil_WS, questionAssignment_WS } from './actions';
import { QuestionAssignmentState } from './types';

const initialState: Partial<QuestionAssignmentState> = {};

export const questionAssignmentSlice = createSlice({
  name: 'questionAssignment',
  initialState,
  reducers: {
    clearQuestionAssignment: () => initialState,
    setActiveAnswerId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeAnswerId = payload;
    },
    setQuestionAssignment: (
      state,
      {
        payload,
      }: PayloadAction<Pick<QuestionAssignmentState, 'assignmentId' | 'questionAssignmentKey' | 'title' | 'questionId'>>
    ) => {
      state.assignmentId = payload.assignmentId;
      state.questionAssignmentKey = payload.questionAssignmentKey;
      state.title = payload.title;
      state.questionId = payload.questionId;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(questionAssignment_WS, (state, { payload }) => {
        state.type = payload.type;
        state.questionId = payload.questionId;
        state.assignmentId = payload.assignmentId;
        state.enabledUntil = payload.enabledUntil;
        state.title = payload.title;
        state.questionAssignmentKey = payload.questionAssignmentKey;
      })
      .addCase(enabledUntil_WS, (state, { payload }) => {
        // TODO Handle time limited questions
        // eslint-disable-next-line no-console
        console.log(`Unhandled WS message ${enabledUntil_WS}`, payload);
      })
      .addCase(disabled_WS, (state, { payload }) => {
        // TODO Handle limited questions
        // eslint-disable-next-line no-console
        console.log(`Unhandled WS message ${disabled_WS}`, payload);
      }),
});

export const { clearQuestionAssignment, setActiveAnswerId, setQuestionAssignment } = questionAssignmentSlice.actions;
