import { Components, Theme } from '@mui/material';

import { dark } from '../palette';
import { shadows } from '../shadows';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      borderRadius: spacing(0.5),
    }),
    paper: ({ theme: { palette, spacing } }) => ({
      backgroundColor: palette.common.white,
      border: 'none',
      boxShadow: shadows['light-shadow-8'],
      marginTop: spacing(1),
      marginBottom: spacing(1),
      minWidth: 240,

      ...(palette.mode === 'dark' && {
        backgroundColor: dark[200],
        color: palette.grey[200],
        boxShadow: shadows['dark-shadow-8'],
      }),
    }),
  },
};
