import React, { FC, ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';

export const Section: FC<{
  title: ReactNode;
  description?: string;
  mt?: string | number;
}> = ({ title, description, mt = 1, children }) => (
  <Grid container direction="column" wrap="nowrap" spacing={3} mt={mt} position="relative">
    <Grid item container direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="natter-text-sm" fontWeight={600} mb={0}>
          {title}
        </Typography>
      </Grid>
      {description && (
        <Grid item>
          <Typography variant="natter-text-sm" color="text.secondary">
            {description}
          </Typography>
        </Grid>
      )}
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);
