import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from './Logo';
import { LogoWrapper } from './Logo.styled';

const DEFAULT_LOGO_REDIRECT = '/' as const;

type Props = {
  disableInteraction?: boolean;
  darkMode?: boolean;
};

export const LogoContainer: FC<Props> = ({ disableInteraction, darkMode }) => (
  <LogoWrapper
    component={disableInteraction ? 'div' : Link}
    to={disableInteraction ? undefined : DEFAULT_LOGO_REDIRECT}
    disableInteraction={disableInteraction}
    data-testid="logo-container"
  >
    <Logo variant={!darkMode ? 'dark' : 'light'} />
  </LogoWrapper>
);
