import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'lodash/fp';
import type { RootState } from 'store';

const selectInsightsReportsWizardState = (state: RootState) => state.insightsReportsWizard;

export const selectCurrentStep = createSelector(selectInsightsReportsWizardState, prop('step'));

export const selectSelectedReportSource = createSelector(
  selectInsightsReportsWizardState,
  prop('selectedReportSource')
);
export const selectSelectedSimilarReportSourcesIds = createSelector(
  selectInsightsReportsWizardState,
  (state) => state.selectedSimilarReportSourcesIds
);

export const selectSelectedInsightsReportType = createSelector(
  selectInsightsReportsWizardState,
  prop('selectedSurveyType')
);

export const selectSimilarReportSources = createSelector(
  selectInsightsReportsWizardState,
  (state) => state.similarReportSources
);

export const selectIsAllSelected = createSelector(
  selectSimilarReportSources,
  selectSelectedSimilarReportSourcesIds,
  (similarReportSources, selectedSimilarReportSourcesIds) =>
    similarReportSources.length === selectedSimilarReportSourcesIds.length
);
