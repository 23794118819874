import React from 'react';

import { UserAvatar } from 'common/components';
import IconCommunityAdmin from 'community/components/icons/IconCommunityAdmin';

type Props = {
  isCommunityAdmin?: boolean;
  avatarName: string;
  avatarLink?: string;
};

export const AvatarImage = ({ isCommunityAdmin, avatarName, avatarLink }: Props) => {
  if (isCommunityAdmin) {
    return <IconCommunityAdmin width={40} />;
  }
  return <UserAvatar avatarLink={avatarLink} name={avatarName} />;
};
