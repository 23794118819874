import { type Components, type Theme } from '@mui/material';

import { dark, red } from '../palette';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography }, color }) => ({
      ...typography['natter-text-sm'],

      color: color === 'danger' ? red[600] : palette.grey[700],

      minWidth: 150,
      padding: spacing(1, 2),
      gap: spacing(2),

      '&:hover': {
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: 2,
          height: '100%',
          backgroundColor: palette.primary.main,
        },

        backgroundColor: palette.grey[100],
      },

      '&& .MuiListItemIcon-root': {
        color: 'inherit',
        minWidth: 0,
      },

      '&.Mui-selected, &[aria-selected="true"]': {
        backgroundColor: palette.extendedPrimary[50],
        color: palette.primary.main,
        borderColor: palette.primary.main,

        '&:hover': {
          backgroundColor: palette.extendedPrimary[50],
        },
      },

      '&.Mui-disabled': {
        color: palette.text.secondary,
        backgroundColor: 'unset',
      },

      ...(palette.mode === 'dark' && {
        color: color === 'danger' ? red[300] : palette.grey[200],

        '&:hover': {
          backgroundColor: dark[400],
        },
      }),
    }),
  },
};
