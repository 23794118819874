import React, { FC } from 'react';

import { Fade, Stack, SxProps, Theme, Typography, styled } from '@mui/material';

import { Button } from '../_legacy/Button';

const Root = styled(Stack)(
  ({ theme }) => ({
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: 8,
  }),
  {
    alignItems: 'start',
    gap: 8,
  }
);

export const Disclaimer: FC<{
  acknowledged: boolean;
  text: string;
  sx?: SxProps<Theme> | undefined;
  onClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ acknowledged, sx, text, onClose }) => (
  <Fade in={!acknowledged} unmountOnExit mountOnEnter>
    <Root sx={sx}>
      <Typography color="text.secondary" variant="natter-text-xs">
        {text}
      </Typography>
      <Button variant="text" sx={{ marginLeft: -1 }} onClick={onClose}>
        Ok, close
      </Button>
    </Root>
  </Fade>
);
