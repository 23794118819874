import { distributeFrequency, initFrequencyDistributionData } from 'common/components/Charts/utils';

import { BasicChartData } from '../../../types';

export const calculateDensityPlotData = ({
  data,
  dataKeys = ['value'],
}: {
  data: BasicChartData;
  dataKeys?: string[];
}): BasicChartData => {
  const xAxisValues = data.map((d) => Number(d.name)).filter((value) => !isNaN(value));
  const xAxisDomain: [number, number] = [Math.min(...xAxisValues), Math.max(...xAxisValues)];

  const { buckets } = initFrequencyDistributionData({
    dataKeys,
    xAxisDomain,
  });

  distributeFrequency({
    initialData: data,
    buckets,
    dataKeys,
  });

  const totalValues = getTotalValues(buckets, dataKeys);
  const densityPlotData = buckets.map((dataPoint) => {
    dataKeys.forEach((key) => {
      const value = Number(dataPoint[key]);
      dataPoint[key] = isNaN(value) ? 0 : value / totalValues[key];
    });
    return dataPoint;
  });
  const lastPoint = densityPlotData[densityPlotData.length - 1];
  return [
    { name: -0.5, value: 0 },
    ...densityPlotData,
    {
      name: densityPlotData.length - 0.5,
      value: 0,
      x0: lastPoint.x1 && lastPoint.x0 === lastPoint.x1 ? Number(lastPoint.x1) + 1 : lastPoint.x1,
    },
  ];
};

const getTotalValues = (data: BasicChartData, dataKeys: string[]) =>
  data.reduce(
    (totals, curr) => {
      dataKeys.forEach((key) => {
        totals[key] = getNumericValue(totals[key]) + getNumericValue(curr[key]);
      });
      return totals;
    },
    {} as Record<string, number>
  );

const getNumericValue = (value: unknown) => (isNaN(Number(value)) ? 0 : Number(value));
