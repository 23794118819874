import { PaletteMode, PaletteOptions, Theme, createTheme } from '@mui/material';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

export const theme = (color?: string, mode?: PaletteMode) =>
  createTheme({
    components,
    palette: palette(color, mode) as PaletteOptions,
    typography,
    breakpoints,
  });

export * from './shadows';
export * from './utils';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, no-unused-vars
  interface DefaultTheme extends Theme {}
}
