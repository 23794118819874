import { AnyAction, Dispatch, ListenerEffect, ThunkDispatch } from '@reduxjs/toolkit';
import { uuid } from 'common/utils';
import type { RootState } from 'store';
import { selectActiveEventDetails } from 'store/apis/event';
import { selectUserAccountId } from 'store/apis/user';
import { addBroadcastInfoMessage, removeAppMessage, selectAlertById } from 'store/features/alerts';

import { demoteUserToAudience } from '../eventSlice.actions';
import {
  selectBroadcast,
  selectEventHosts,
  selectIsCurrentUserEventHost,
  selectParticipantsWithRaisedHands,
  selectRaisedHandAlertText,
} from '../selectors';
import { isHost } from '../utils';

type CombinedDispatch = Dispatch<AnyAction> & ThunkDispatch<RootState, unknown, AnyAction>;

type Effect = ListenerEffect<AnyAction, RootState, CombinedDispatch>;

const HAND_RAISED_NOTIFICATION_ID = uuid();

export const displayHandRaisedAlert: Effect = async (_, { dispatch, getState }) => {
  const state = getState();
  const broadcast = selectBroadcast(state);
  const eventDetails = selectActiveEventDetails(state);
  const accountId = selectUserAccountId(state);

  if (!(broadcast && eventDetails && accountId)) return;

  const participantsWithRaisedHands = selectParticipantsWithRaisedHands(state);
  const isUserHost = selectIsCurrentUserEventHost(state);
  const shouldSeeHandRaisedAlert = isUserHost;

  if (!shouldSeeHandRaisedAlert) return;

  const otherHandsRaised = participantsWithRaisedHands.filter((id) => id !== accountId);

  if (otherHandsRaised.length === 0) return;

  const message = selectRaisedHandAlertText(getState());
  dispatch(
    addBroadcastInfoMessage({
      message,
      id: HAND_RAISED_NOTIFICATION_ID,
      timeout: 0,
      icon: 'RaisedHandIcon',
    })
  );
};

export const updateHandRaisedAlert: Effect = async (_, { dispatch, getState }) => {
  const state = getState();

  const isHandRaisedNotificationDisplayed = selectAlertById(HAND_RAISED_NOTIFICATION_ID)(state);
  if (!isHandRaisedNotificationDisplayed) return;

  const accountId = selectUserAccountId(state);
  if (!accountId) return;

  const participantsWithRaisedHands = selectParticipantsWithRaisedHands(state);
  const otherHandsRaised = participantsWithRaisedHands.filter((id) => id !== accountId);

  if (otherHandsRaised.length === 0) {
    dispatch(removeAppMessage(HAND_RAISED_NOTIFICATION_ID));
  } else {
    const message = selectRaisedHandAlertText(getState());
    dispatch(
      addBroadcastInfoMessage({
        message,
        id: HAND_RAISED_NOTIFICATION_ID,
        timeout: 0,
        icon: 'RaisedHandIcon',
      })
    );
  }
};

export const userDemotedEffect: Effect = async (_, { dispatch, getState }) => {
  const state = getState();
  const isUserHost = selectIsCurrentUserEventHost(state);

  if (!isUserHost) {
    const eventHosts = selectEventHosts(state);
    const newAudience = state.event.audience.filter(({ id }) => isHost(eventHosts, id));
    dispatch(demoteUserToAudience({ newAudience }));
  }
};
