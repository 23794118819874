import { alpha } from '@mui/material';

import { darkBlue } from './palette';

/**
 * Overriding Mui shadows is very cumbersome in it's current form so it's much easier to just use this instead.
 * https://github.com/mui/material-ui/issues/28820
 *
 * Design System
 * @see https://www.figma.com/design/En2gOztv0xR9rIjYFtdut5/Design-system?node-id=2031-183432&t=In8a5nJ8QMMy2yqP-4
 */
export const shadows = {
  extraLight: `0px 1.85px 6.85px 0px ${alpha('#000', 0.04)}`,
  light: `0px 1.85px 6.25px 0px ${alpha('#000', 0.19)}, 0px 0.5px 1.75px 0px ${alpha('#000', 0.02)}`,
  medium: `0px 8px 12px 0px ${alpha(darkBlue, 0.15)}, 0px 0px 1px 0px ${alpha(darkBlue, 0.31)}`,
  4: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
  'light-shadow-8': '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)',
  'dark-shadow-8': '0px 4px 8px 0px rgba(0, 0, 0, 0.28), 0px 0px 2px 0px rgba(0, 0, 0, 0.20)',
  overlay: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)',
  top: '4px -4px 8px 0px rgba(0, 0, 0, 0.03)',
  bottom: '0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 4px 4px -3px rgba(0, 0, 0, 0.14)',
} as const;
