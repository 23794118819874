/* istanbul ignore file */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import isValidProp from '@emotion/is-prop-valid';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { NatterThemeProvider } from 'common/hocs';
import { logEnabledFeatures } from 'common/utils';
import { MotionConfig } from 'framer-motion';
import { AuthProvider } from 'modules/authorization';
import { reduxHistory } from 'reduxHistory';
import { store } from 'store';
import { KeycloakProvider } from 'store/context/KeycloakContext';

import { initDatadogRum } from './datadog-rum';
import { initGtm } from './gtm';
import i18n from './i18n';
import './index.css';
import { PreApp } from './preApp';
import { initSentry } from './sentry';

initSentry();
initGtm();
initDatadogRum();
logEnabledFeatures();

ReactDOM.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router history={reduxHistory}>
        <StyledEngineProvider injectFirst>
          <MotionConfig isValidProp={isValidProp}>
            <NatterThemeProvider>
              <CssBaseline />
              <AuthProvider>
                <KeycloakProvider>
                  <Provider store={store}>
                    <IntercomProvider appId={window._env_.INTERCOM_ID}>
                      <PreApp />
                    </IntercomProvider>
                  </Provider>
                </KeycloakProvider>
              </AuthProvider>
            </NatterThemeProvider>
          </MotionConfig>
        </StyledEngineProvider>
      </Router>
    </I18nextProvider>
  </StrictMode>,
  document.getElementById('root')
);
