import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChatWSActions, ChatWSMessages } from 'common/constants';
import { ChatMessageBasic } from 'domain/Chat';
import { createMeetingWSAction, createMeetingWSMessage } from 'store/utils';

import { excludeFromMessages } from './chatSlice';
import { ApproveChatMessagesParams, RejectChatMessagesParams, SendChatMessagesParams } from './types';

export const newChatMessages_WS = createMeetingWSMessage<ChatMessageBasic[]>(ChatWSMessages.ChatMessages);
export const newChatPendingMessages_WS = createMeetingWSMessage<ChatMessageBasic>(ChatWSMessages.PendingMessages);
export const newChatApprovedMessages_WS = createMeetingWSMessage<ChatMessageBasic[]>(ChatWSMessages.MessagesApproved);
export const newChatRejectedMessages_WS = createMeetingWSMessage<ChatMessageBasic[]>(ChatWSMessages.MessagesRejected);

export const sendChatMessage = createMeetingWSAction<SendChatMessagesParams>(ChatWSActions.SendChatMessage);
export const approveChatMessages = createMeetingWSAction<ApproveChatMessagesParams>(ChatWSActions.AcceptChatMessages);
export const rejectChatMessages = createMeetingWSAction<RejectChatMessagesParams>(ChatWSActions.RejectChatMessages);
export const enableChatModeration = createMeetingWSAction(ChatWSActions.EnableChatModeration);
export const disableChatModeration = createMeetingWSAction(ChatWSActions.DisableChatModeration);

export const REMOVE_REJECTED_MESSAGE_DELAY = 3000;

export const rejectedMessageSeen = createAsyncThunk<void, ChatMessageBasic>(
  'chat/rejectedMessageSeen',
  (message, { dispatch }) => {
    setTimeout(() => {
      dispatch(excludeFromMessages({ messages: [message] }));
    }, REMOVE_REJECTED_MESSAGE_DELAY);
  }
);
