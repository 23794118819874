import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Stack, SxProps, Typography } from '@mui/material';

import { StatusIndicator } from '../StatusIndicator';

type Props = {
  sx?: SxProps;
};

export const TranscriptionIndicator: FC<Props> = ({ sx }) => {
  const { t } = useTranslation('event', { keyPrefix: 'nattering' });

  return (
    <StatusIndicator color="success" sx={sx}>
      <Stack direction="row" alignItems="center" gap={0.5}>
        <CircularProgress size="12px" color="inherit" disableShrink />
        <Typography>{t('transcriptionActive')}</Typography>
      </Stack>
    </StatusIndicator>
  );
};
