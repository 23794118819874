import { type Components, type Theme } from '@mui/material';

export const MuiTableContainer: Components<Theme>['MuiTableContainer'] = {
  styleOverrides: {
    root: ({ theme: { palette, shape } }) => ({
      border: '1px solid',
      borderColor: palette.grey[200],
      borderRadius: shape.borderRadius * 2,
      background: palette.common.white,
    }),
  },
};
