import React, { ComponentType, FC } from 'react';
import { Redirect } from 'react-router-dom';

import { AppRoutes } from 'router';

import { useAuth } from './AuthProvider';

export const ProtectedRoute: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: AppRoutes.Login, state: { redirectUri: window.location.pathname } }} />;
  }

  return <>{children}</>;
};

export const withProtectedRoute = (Component: ComponentType) => () => (
  <ProtectedRoute>
    <Component />
  </ProtectedRoute>
);
