import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { DemographicFiltersType, InsightsReportsState } from './types';

const insightsReportsSliceInitialState: InsightsReportsState = {
  demographicFilters: {},
};

export const insightsReportsSlice = createSlice({
  name: 'insightsReports',
  initialState: insightsReportsSliceInitialState,
  reducers: {
    setDemographicFilters: (state, { payload }: PayloadAction<DemographicFiltersType>) => {
      state.demographicFilters = payload;
    },
    clearDemographicFilters: (state) => {
      state.demographicFilters = {};
    },
  },
});

export const { setDemographicFilters, clearDemographicFilters } = insightsReportsSlice.actions;

export const selectDemographicFilters = (state: RootState) => state.insightsReports.demographicFilters;

export const selectDemographicFiltersActive = (state: RootState) =>
  Object.values(state.insightsReports.demographicFilters).some((values) => values.length > 0);
