import { UIEventHandler, useCallback, useRef, useState } from 'react';

export const AUTO_SCROLL_MARGIN = 10;

export const useAutoScroll = (initialIsAutoScrollToBottomActive = true) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const previousScrollPosition = useRef<number>();
  const [isAutoScrollToBottomActive, setIsAutoScrollToBottomActive] = useState(initialIsAutoScrollToBottomActive);

  const setAutoScrollToBottomAsActive = useCallback(() => setIsAutoScrollToBottomActive(true), []);

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback((e) => {
    const target = e.target as HTMLDivElement;
    const isScrollingDown =
      previousScrollPosition.current !== undefined ? target.scrollTop > previousScrollPosition.current : true;
    previousScrollPosition.current = target.scrollTop;
    setIsScrolled(target.scrollTop > 0);

    setIsAutoScrollToBottomActive((isAutoScrollToBottom) => {
      if (isScrollingDown && isAutoScrollToBottom) return isAutoScrollToBottom;
      return target.scrollHeight - target.scrollTop < target.clientHeight + AUTO_SCROLL_MARGIN;
    });
  }, []);

  return {
    handleScroll,
    setAutoScrollToBottomAsActive,
    isAutoScrollToBottomActive,
    isScrolled,
  };
};
