import React, { FC, createContext, useCallback, useContext, useState } from 'react';

import { Stack, SxProps } from '@mui/material';
import { useElementSize } from 'common/hooks/useElementSize';
import { mergeSxProps } from 'common/theme';
import { assert } from 'common/utils';

const VirtualScrollContext = createContext<{
  scrollContainerHeight: number;
  scrollTop: number;
}>({
  scrollContainerHeight: 0,
  scrollTop: 0,
});

export const useVirtualScrollContext = () => {
  const ctx = useContext(VirtualScrollContext);
  assert(ctx, 'useVirtualScrollContext must be used inside VirtualScroll');
  return ctx;
};

type Props = {
  sx?: SxProps;
  activeScrollSx?: SxProps;
  onScrollTopChange?(value: number): void;
};

export const VirtualScroll: FC<Props> = ({ children, sx, activeScrollSx, onScrollTopChange }) => {
  const [containerRef, { height: scrollContainerHeight }] = useElementSize();
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      setScrollTop(e.currentTarget.scrollTop);
      onScrollTopChange?.(e.currentTarget.scrollTop);
    },
    [onScrollTopChange]
  );

  return (
    <VirtualScrollContext.Provider
      value={{
        scrollContainerHeight,
        scrollTop,
      }}
    >
      <Stack
        ref={containerRef}
        sx={mergeSxProps({ overflowY: 'auto' }, sx, scrollTop ? activeScrollSx : undefined)}
        onScroll={handleScroll}
      >
        {children}
      </Stack>
    </VirtualScrollContext.Provider>
  );
};
