import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { selectUserAccountId } from 'store/apis/user';

import { roomExit_WS } from '../event';
import { answerDeleted_WS, answerUpdated_WS, submitAnswers } from './actions';
import { clearAnswers, clearUpdatedByUserId } from './answersSlice';
import { clearQuestionAssignment, setActiveAnswerId } from './questionAssignmentSlice';
import { selectQuestionActiveAnswerId } from './selectors';

export const CLEAR_UPDATED_BY_USER_ID_TIMEOUT = 5000;
export const questionsListener = createListenerMiddleware<RootState>();

questionsListener.startListening({
  matcher: isAnyOf(roomExit_WS, submitAnswers.fulfilled, submitAnswers.rejected),
  effect: (_, { dispatch }) => {
    dispatch(clearAnswers());
    dispatch(clearQuestionAssignment());
  },
});

const clearEditingLabelDelayedEffects = new Map<string, NodeJS.Timeout>();
questionsListener.startListening({
  actionCreator: answerUpdated_WS,
  effect: async ({ payload: { answer } }, { dispatch, getState }) => {
    const state = getState();
    const { uuid: answerId, updatedByUserId } = answer;
    const userId = selectUserAccountId(state);
    const isUpdatedByCurrentUser = userId === updatedByUserId;

    const existingEffect = clearEditingLabelDelayedEffects.get(answerId);
    if (existingEffect) {
      clearTimeout(existingEffect);
      clearEditingLabelDelayedEffects.delete(answerId);
    }

    if (isUpdatedByCurrentUser) return;

    const newEffect = setTimeout(() => {
      dispatch(clearUpdatedByUserId(answerId));
      clearEditingLabelDelayedEffects.delete(answerId);
    }, CLEAR_UPDATED_BY_USER_ID_TIMEOUT);

    clearEditingLabelDelayedEffects.set(answerId, newEffect);
  },
});

questionsListener.startListening({
  actionCreator: answerDeleted_WS,
  effect: async ({ payload: answerId }, { dispatch, getState }) => {
    const state = getState();

    const activeAnswerId = selectQuestionActiveAnswerId(state);
    if (activeAnswerId === answerId) {
      dispatch(setActiveAnswerId(undefined));
    }
  },
});
