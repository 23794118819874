import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { eventApi, eventSeriesApi } from 'store/apis/event';
import { handleQueryError } from 'store/utils';

import {
  deleteEventSuccessEffect,
  deleteEventsFromSeriesSuccessEffect,
  getConnectionsFailedEffect,
  refreshEventsList,
  registerForEventFailedEffect,
  registerForEventSuccessEffect,
  startEventFailedEffect,
  startEventSuccessEffect,
  stopEventFailedEffect,
} from './effects';

export const activeEventListener = createListenerMiddleware<RootState>();

activeEventListener.startListening({
  matcher: eventApi.endpoints.deleteEvent.matchFulfilled,
  effect: deleteEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.startEvent.matchRejected,
  effect: startEventFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.startEvent.matchFulfilled,
  effect: startEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.stopEvent.matchRejected,
  effect: stopEventFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.getConnections.matchRejected,
  effect: getConnectionsFailedEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.registerForEvent.matchFulfilled,
  effect: registerForEventSuccessEffect,
});

activeEventListener.startListening({
  matcher: eventApi.endpoints.registerForEvent.matchRejected,
  effect: registerForEventFailedEffect,
});

activeEventListener.startListening({
  matcher: isAnyOf(
    eventSeriesApi.endpoints.createEventSeries.matchFulfilled,
    eventSeriesApi.endpoints.deleteEventsFromSeries.matchFulfilled,
    eventSeriesApi.endpoints.convertSingleEventToSeries.matchFulfilled,
    eventSeriesApi.endpoints.updateEventSeries.matchFulfilled
  ),
  effect: refreshEventsList,
});

activeEventListener.startListening({
  matcher: eventSeriesApi.endpoints.deleteEventsFromSeries.matchFulfilled,
  effect: deleteEventsFromSeriesSuccessEffect,
});

activeEventListener.startListening({
  matcher: isAnyOf(
    eventSeriesApi.endpoints.deleteEventsFromSeries.matchRejected,
    eventApi.endpoints.deleteEvent.matchRejected
  ),
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to delete Event');
  },
});
