import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { upperFirst } from 'lodash';

import { StyledButton } from './ProviderButton.styled';
import { ReactComponent as GoogleIcon } from './google.svg';
import { ReactComponent as MicrosoftIcon } from './microsoft.svg';

const providerIcon = {
  google: <GoogleIcon />,
  microsoft: <MicrosoftIcon />,
};

type Props = {
  provider: 'google' | 'microsoft';
  onClick: () => void;
};

export const ProviderButton: FC<Props> = ({ provider, onClick }) => {
  const { t } = useTranslation('auth');

  return (
    <StyledButton onClick={onClick} variant="text" size="large" startIcon={providerIcon[provider]}>
      {t('joinWithProvider', { provider: upperFirst(provider) })}
    </StyledButton>
  );
};
