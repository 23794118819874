import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ChatMessage, ChatMessageBasic, ChatMessageStatus } from 'domain/Chat';

import { ChatState } from './types';

const CHAT_HISTORY_LIMIT = 1000;

export const messagesAdapter = createEntityAdapter<ChatMessage>({
  selectId: (message) => message.id,
});

export const initialState: ChatState = {
  messages: messagesAdapter.getInitialState(),
  isChatActive: false,
  isChatScrolledToBottom: false,
  unreadMessagesCount: 0,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetChatState: () => initialState,
    setChatActive: (state, action: PayloadAction<boolean>) => {
      state.isChatActive = action.payload;
      if (state.isChatActive) {
        state.unreadMessagesCount = 0;
      }
    },
    setChatScrolledToBottom: (state, action: PayloadAction<boolean>) => {
      state.isChatScrolledToBottom = action.payload;
      if (state.isChatScrolledToBottom) {
        state.unreadMessagesCount = 0;
      }
    },
    excludeFromMessages: (state, action: PayloadAction<{ messages: ChatMessageBasic[] }>) => {
      messagesAdapter.removeMany(
        state.messages,
        action.payload.messages.map(({ id }) => id)
      );
    },
    markMessagesAsRejected: (state, { payload }: PayloadAction<{ messages: ChatMessageBasic[] }>) => {
      messagesAdapter.updateMany(
        state.messages,
        payload.messages.map(({ id }) => ({
          id,
          changes: {
            status: ChatMessageStatus.Rejected,
          },
        }))
      );
      if (!state.isChatActive) {
        state.unreadMessagesCount += payload.messages.length;
      }
    },
    addMessages: (
      state,
      action: PayloadAction<{ messages: ChatMessageBasic[]; status: ChatMessageStatus; accountId?: number }>
    ) => {
      const { messages, status } = action.payload;
      const newMessages = messages.map(
        (message): ChatMessage => ({
          ...message,
          timestamp: 'timestamp' in message ? message.timestamp : Date.now(),
          isNew: false,
          status,
          shouldAnimate: state.isChatActive && state.isChatScrolledToBottom,
        })
      );
      messagesAdapter.addMany(state.messages, newMessages);

      const numberOfMessagesToRemove = state.messages.ids.length - CHAT_HISTORY_LIMIT;
      if (numberOfMessagesToRemove > 0) {
        messagesAdapter.removeMany(state.messages, state.messages.ids.slice(0, numberOfMessagesToRemove));
      }

      if (!state.isChatActive || !state.isChatScrolledToBottom) {
        state.unreadMessagesCount += messages.length;
      }
    },
    markAsReadMessage: (state, action: PayloadAction<{ id: number | string }>) => {
      messagesAdapter.updateOne(state.messages, {
        id: action.payload.id,
        changes: {
          shouldAnimate: false,
        },
      });
    },
  },
});

export const {
  resetChatState,
  setChatActive,
  setChatScrolledToBottom,
  excludeFromMessages,
  markMessagesAsRejected,
  addMessages,
  markAsReadMessage,
} = chatSlice.actions;
