import React, { FC } from 'react';

import {
  FormControl,
  InputLabel,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs';

import { StyledFormControlLabel, StyledRadioGroup, StyledRadioGroupProps } from './RadioGroup.styled';

export type RadioGroupOption<T = string | number> = { label: string; value: T; description?: string };

type RadioGroupProps = {
  name?: string;
  hint?: string;
  label?: string;
  options: RadioGroupOption[];
  autoWidth?: boolean;
  value: MuiRadioGroupProps['value'];
  error?: { message?: string };
  onChange: MuiRadioGroupProps['onChange'];
} & StyledRadioGroupProps;

export const RadioGroup: FC<RadioGroupProps> = ({
  name = 'RadioGroup',
  label,
  hint,
  options,
  value,
  error,
  variant = 'default',
  onChange,
}) => (
  <Stack gap={3}>
    {label && (
      <InputLabel id={`${name}-label`} sx={{ fontWeight: 600 }}>
        {label}
      </InputLabel>
    )}

    <FormControl component="fieldset" error={!!error} fullWidth>
      <StyledRadioGroup value={value} onChange={onChange} data-testid={`radioGroup-${name}`} variant={variant}>
        {options.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            label={
              option.description ? (
                <Stack direction="row" gap={4} justifyContent="space-between" alignItems="center">
                  <Typography component="span" fontWeight={500}>
                    {option.label}
                  </Typography>
                  <Typography
                    component="span"
                    color="text.secondary"
                    fontWeight={400}
                    whiteSpace="pre-wrap"
                    textAlign="end"
                  >
                    {option.description}
                  </Typography>
                </Stack>
              ) : (
                option.label
              )
            }
            control={<Radio />}
            variant={variant}
            checked={value === option.value}
            data-testid={`radioInput-${option.label}`}
            aria-label={option.label}
          />
        ))}
      </StyledRadioGroup>

      <FormFieldHint error={!!error}>{error?.message || hint}</FormFieldHint>
    </FormControl>
  </Stack>
);
