import { makeStyles } from '@mui/styles';

export const useTagsStyles = makeStyles(
  (theme) => ({
    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tag: {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: '0.8rem',
      border: `solid 1px ${theme.palette.custom.lightGrey}`,
      padding: '1px 4px',
      borderRadius: 4,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tags: {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
      padding: theme.spacing(2),
      backgroundColor: theme.palette.custom.cardBackground,
      borderRadius: 8,
      boxShadow: theme.shadows[10],
    },
    tagsButton: {
      cursor: 'default',
      color: theme.palette.custom.darkGrey,
    },
  }),
  { name: 'tags' }
);
