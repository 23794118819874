import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';

type Props = {
  title?: string;
};

export const ListItemDescription: FC<Props> = ({ title, children }) => (
  <Typography
    component="div"
    variant="natter-text-sm"
    sx={({ palette }) => ({ ...textEllipsis, color: palette.grey[600] })}
    title={title}
  >
    {children}
  </Typography>
);
