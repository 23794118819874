import React, { ChangeEvent, FC, useCallback } from 'react';

import {
  Box,
  Pagination as MaterialPagination,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

export interface PagePickerProps {
  totalPages: number;
  currentPage: number;
  isLoading: boolean;
  onChange(currentPage: number): void;
  rowsPerPage?: number;
  onRowsPerPageChange?(rowsPerPage: number): void;
  pageSizeLabel?: string;
  rowsPerPageOptions?: number[];
}

export const PagePicker: FC<PagePickerProps> = ({
  totalPages,
  currentPage,
  isLoading,
  rowsPerPage,
  onChange,
  onRowsPerPageChange,
  pageSizeLabel = 'Rows per page:',
  rowsPerPageOptions = [6, 9, 10, 25, 50, 100],
}) => {
  const arePagePickerPropsPresent = !!onRowsPerPageChange && rowsPerPage !== undefined;
  const isOnlyOneSmallestPage = totalPages === 1 && rowsPerPage === rowsPerPageOptions[0];
  const showPageSizePicker = arePagePickerPropsPresent && !isOnlyOneSmallestPage;
  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, value: number) => {
      onChange(value);
    },
    [onChange]
  );

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    onRowsPerPageChange?.(Number(event.target.value));
  };

  return totalPages > 0 ? (
    <Stack direction="row" alignItems="center" justifyContent="end" width="100%" data-testid="PagePicker">
      {totalPages > 1 && (
        <Stack flex={1} alignItems="center">
          <Box paddingLeft={showPageSizePicker ? '162px' : 0}>
            <MaterialPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              variant="outlined"
              color="primary"
              disabled={isLoading}
            />
          </Box>
        </Stack>
      )}
      {showPageSizePicker && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>{pageSizeLabel}</Typography>
          <Select size="small" value={rowsPerPage.toString()} onChange={handleRowsPerPageChange}>
            {rowsPerPageOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </Stack>
  ) : null;
};
