import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import * as icons from 'icons';
import { SnackBarAlertData } from 'store/features/alerts';

import { DEFAULT_AUTO_HIDE_DURATION, useSnackbarAutoHide } from '../../hooks/useSnackbarAutoHide';
import { Snackbar } from './Snackbar';

type Props = {
  alertData: SnackBarAlertData;
};

export const SnackbarContainer: FC<Props> = ({ alertData }) => {
  const { severity, message } = alertData;
  const isAppAlertMessage = typeof message === 'object';
  const timeout = (isAppAlertMessage ? message.timeout : DEFAULT_AUTO_HIDE_DURATION) ?? DEFAULT_AUTO_HIDE_DURATION;
  const isInfiniteMessage = timeout === 0;

  const { hide } = useSnackbarAutoHide(alertData, isInfiniteMessage ? null : timeout);

  const handleClose = timeout > DEFAULT_AUTO_HIDE_DURATION || isInfiniteMessage ? hide : undefined;

  const Icon = isAppAlertMessage && message.icon ? icons[message.icon] : undefined;
  return (
    <Snackbar severity={severity} onClose={handleClose}>
      {Icon && (
        <Box fontSize="26px" height="26px">
          <Icon fontSize="inherit" />
        </Box>
      )}
      <Typography variant="natter-text-sm" lineHeight="26px">
        {isAppAlertMessage ? message.message : message}
      </Typography>
    </Snackbar>
  );
};
