import React, { FC, useMemo } from 'react';

import { useElementSize } from 'common/hooks';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { CHART_BAR_MAX_WIDTH, legendWrapperStyle } from '../../constants';
import { useChartAxis, useChartStyles, useDynamicChartDimensions, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import {
  calculateChartMargins,
  calculateTicks,
  createTickFormatter,
  getColor,
  mapToStackedChartPercentages,
  mapToStackedChartValues,
} from '../../utils';

const ANONYMIZED_DATA_KEY = 'anonymizedValue';

export const HorizontalStackedBarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data: initialData,
  dataKeys: initialDataKeys = ['value'],
  height,
  TooltipContent,
  isPercentageValue,
  anonymizationEnabled,
  legendFormatter,
  onReady,
}) => {
  const { getChartClass } = useChartStyles();
  const { tickStyles, renderAxisYLabel, renderAxisXLabel, axisLineStyles, tickLineStyles } = useChartAxis();
  const { activeKey, handleMouseEnter, hideTooltip } = useTooltip();
  const dataKeys = useMemo(
    () => (anonymizationEnabled ? [...initialDataKeys, ANONYMIZED_DATA_KEY] : initialDataKeys),
    [anonymizationEnabled, initialDataKeys]
  );
  const legendPayload = useMemo(
    () =>
      initialDataKeys.map<Payload>((name, index) => ({
        value: name,
        type: 'square',
        color: getColor(index),
      })),
    [initialDataKeys]
  );
  const data = useMemo(
    () => (isPercentageValue ? mapToStackedChartPercentages(initialData) : initialData),
    [isPercentageValue, initialData]
  );
  const stackedValues = useMemo(() => mapToStackedChartValues({ data, dataKeys }), [data, dataKeys]);
  const { ticks, isLastTickEqualToMaxValue } = useMemo(() => calculateTicks({ data: stackedValues }), [stackedValues]);
  const [ref, size] = useElementSize();
  const { yAxisWidth, chartHeight } = useDynamicChartDimensions({
    data,
    chartWidth: size.width,
    chartHeight: height,
  });

  return (
    <ResponsiveContainer debounce={300} width="100%" height={chartHeight} ref={ref}>
      <BarChart
        data={data}
        margin={calculateChartMargins({ labelNames, labelValues })}
        className={getChartClass('horizontal', isLastTickEqualToMaxValue)}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="1 1" horizontal={false} />
        <YAxis
          dataKey="name"
          type="category"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} />}
          interval={0}
          tickMargin={10}
          width={yAxisWidth}
          label={renderAxisYLabel(labelNames)}
        />
        <XAxis
          tick={tickStyles}
          tickLine={tickLineStyles}
          tickSize={10}
          axisLine={axisLineStyles}
          tickMargin={16}
          interval={0}
          tickFormatter={createTickFormatter(isPercentageValue)}
          label={renderAxisXLabel(labelValues)}
          type="number"
          domain={[0, 'dataMax']}
          allowDataOverflow
          ticks={ticks}
        />
        <Tooltip
          cursor={false}
          content={
            <TooltipContentWrapper
              activeDataKey={activeKey}
              TooltipContent={TooltipContent}
              isPercentageValue={isPercentageValue}
            />
          }
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && (
          <Legend
            verticalAlign="top"
            align="right"
            formatter={legendFormatter}
            wrapperStyle={legendWrapperStyle}
            payload={legendPayload}
          />
        )}

        {dataKeys.map((key, index) => {
          const color = key === ANONYMIZED_DATA_KEY ? '#EAEAEA' : getColor(index);

          return (
            <Bar
              key={key}
              stackId="stack1"
              dataKey={key}
              maxBarSize={CHART_BAR_MAX_WIDTH}
              fill={color}
              onMouseEnter={handleMouseEnter(key)}
              onMouseLeave={hideTooltip}
              onAnimationEnd={onReady}
            >
              <LabelList
                dataKey={key}
                fill={color}
                content={<BarChartLabel data={data} isPercentageValue={isPercentageValue} />}
              />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
