import accountSettings from './accountSettings.json';
import auth from './auth.json';
import common from './common.json';
import devices from './devices.json';
import event from './event.json';
import insightReport from './insightReport.json';
import natters from './natters.json';
import question from './question.json';
import settings from './settings.json';
import techCheck from './techCheck.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    accountSettings,
    auth,
    common,
    devices,
    event,
    insightReport,
    question,
    natters,
    settings,
    techCheck,
  },
} as const;

export const ns = Object.keys(resources.en) as Array<keyof (typeof resources)['en']>;
