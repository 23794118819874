import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button } from 'common/components/Buttons';

export const ResetFiltersButton: FC = () => {
  const { reset } = useFormContext();

  const values = useWatch();
  const isAnySelected = Object.values(values).some((selected) => selected.length > 0);

  const handleReset = () => {
    reset(
      Object.keys(values).reduce(
        (prev, key) => {
          prev[key] = [];
          return prev;
        },
        {} as Record<string, unknown[]>
      ),
      {
        keepDirty: false,
      }
    );
  };

  if (!isAnySelected) return null;

  return (
    <Button variant="text" onClick={handleReset}>
      Reset all
    </Button>
  );
};
