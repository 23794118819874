import { type Components, type Theme } from '@mui/material';

import { dark } from '../palette';

export const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      borderColor: palette.mode === 'light' ? palette.divider : dark[50],
    }),
  },
};
