import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';

import { EULA_POLICY_URL, PRIVACY_POLICY_URL } from '../constants';

export const SignupAgreement: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <Typography
      variant="natter-text-sm"
      color="text.secondary"
      textAlign="center"
      sx={{ alignSelf: 'center', maxWidth: 336 }}
    >
      <Trans
        t={t}
        i18nKey="agreement.signup"
        components={{
          eulaPolicy: (
            <Link
              component="a"
              href={EULA_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              color="text.primary"
              underline="always"
            />
          ),
          privacyPolicy: (
            <Link
              component="a"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              color="text.primary"
              underline="always"
            />
          ),
        }}
      />
    </Typography>
  );
};
