import type { DateISOString, TimeUnit } from 'domain/Common';

import { CreateEventQuestionRequest } from '../event';

export type AsyncSurveyListSortField = 'NAME' | 'CREATED_AT' | 'STATE';

export const AsyncSurveyStates = {
  Published: 'PUBLISHED',
  Unpublished: 'UNPUBLISHED',
  Completed: 'COMPLETED',
} as const;

export type AsyncSurveyState = (typeof AsyncSurveyStates)[keyof typeof AsyncSurveyStates];

export type AsyncSurveyResponse = {
  id: number;
  name: string;
  state: AsyncSurveyState;
  createdAt: DateISOString;
  publishedAt: number | null;
  dueBy: number;
  ownerId: number;
  expired: boolean;
  reminderIntervalAmount: number;
  reminderIntervalTimeUnit: TimeUnit;
  communityId: number;
  welcomeMessage: string;
  invitedUsers: number | undefined;
  responsesReceived: number | undefined;
};

export type AsyncSurvey = AsyncSurveyResponse;

export type AsyncSurveyPublic = AsyncSurvey;

export type AsyncSurveyListItemResponse = AsyncSurvey;

export type AsyncSurveyListItem = AsyncSurveyListItemResponse;

export type WithSurveyId<T extends Record<string, unknown> = {}> = Pick<AsyncSurvey, 'id'> & T;

export type CreateSurveyPayload = {
  communityId: number;
  name: string;
  dueBy: DateISOString;
  welcomeMessage: string;
  reminderIntervalAmount: number;
  reminderIntervalTimeUnit: TimeUnit;
};

export type CreateSurveyQuestionsPayload = WithSurveyId<{
  questions: CreateEventQuestionRequest[];
}>;

export type CreateSurveyUsersPayload = WithSurveyId<{
  emails: string[];
}>;

export type UpdateSurveyPayload = Pick<
  AsyncSurvey,
  'id' | 'name' | 'reminderIntervalAmount' | 'reminderIntervalTimeUnit' | 'welcomeMessage'
> & {
  dueBy: string | number;
};
