import React, { ComponentType, FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useLocalStorage } from 'common/hooks';
import { generateDynamicPath } from 'common/utils';
import { AppRoutes } from 'router';

export const withStoreRedirectUriToLocalStorage =
  <Props extends object>(WrappedComponent: ComponentType<Props>): FC<Props> =>
  (props) => {
    const { data: redirectUri, storeData } = useLocalStorage({ key: 'redirectUri' });
    const isEventDetailsPage = useRouteMatch<{ id: string }>([AppRoutes.EventDetails, AppRoutes.EventPublic]);

    useEffect(() => {
      if (!isEventDetailsPage) return;

      const redirectUriToStore = generateDynamicPath(AppRoutes.EventDetails, { id: isEventDetailsPage.params.id });
      if (redirectUriToStore !== redirectUri) {
        storeData(redirectUriToStore);
      }
    }, [isEventDetailsPage, redirectUri, storeData]);

    return <WrappedComponent {...(props as Props)} />;
  };
