import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedApiResponse } from 'domain/Common';
import { getApiBaseUrl } from 'modules/api/utils';
import { v4 } from 'uuid';

import axiosBaseQuery from '../_axiosBaseQuery';
import { AutomatedNatter, CreateNatterPayload, GetNatterListPayload, UpdateNatterPayload } from './types';

// eslint-disable-next-line no-unused-vars
const mockedNatter: AutomatedNatter = {
  communityId: 1,
  id: v4(),
  name: 'Mocked Natter',
  question: 'What is your favorite color?',
};

export const nattersApi = createApi({
  reducerPath: 'nattersApi',
  tagTypes: ['NATTERS_LIST', 'NATTER_DETAILS'],
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v1') }),
  endpoints: (builder) => ({
    getNattersList: builder.query<PaginatedApiResponse<AutomatedNatter>, GetNatterListPayload>({
      providesTags: ['NATTERS_LIST'],
      query: () => ({
        url: '/natters',
      }),
      // queryFn: () => Promise.resolve({ data: { data: [mockedNatter], totalCount: 1 } }),
    }),
    createNatter: builder.mutation<unknown, CreateNatterPayload>({
      query: (data) => ({
        url: '/natters',
        method: 'POST',
        data,
      }),
      // queryFn: () => Promise.resolve({ data: mockedNatter }),
      invalidatesTags: ['NATTER_DETAILS'],
    }),
    updateNatter: builder.mutation<unknown, UpdateNatterPayload>({
      query: ({ id, ...data }) => ({
        url: `/natters/${id}`,
        method: 'PUT',
        data,
      }),
      // queryFn: () => Promise.resolve({ data: mockedNatter }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'NATTER_DETAILS', id: arg.id }],
    }),
    deleteNatter: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/natters/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NATTERS_LIST'],
    }),
    getNatter: builder.query<AutomatedNatter, string>({
      providesTags: (_result, _error, arg) => [{ type: 'NATTER_DETAILS', id: arg }],
      query: (id) => ({
        url: `/natters/${id}`,
      }),
      // queryFn: () => Promise.resolve({ data: mockedNatter }),
    }),
  }),
});

export const {
  useGetNattersListQuery,
  useCreateNatterMutation,
  useUpdateNatterMutation,
  useDeleteNatterMutation,
  useGetNatterQuery,
} = nattersApi;
