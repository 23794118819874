import React, { FC, ReactChild, ReactFragment, ReactPortal } from 'react';

import { Stack, Tooltip, Typography } from '@mui/material';
import { ErrorBox } from 'common/components/_legacy/ErrorBox';
import { HelpOutlinedIcon } from 'icons';

interface SectionProps {
  label: string;
  description?: string;
  tooltip?: boolean | ReactChild | ReactFragment | ReactPortal;
  error?: string;
}

export const Section: FC<SectionProps> = ({ label, description, tooltip, children, error }) => (
  <Stack gap={4}>
    <Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="natter-text-sm" fontWeight={600}>
          {label}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} arrow enterTouchDelay={0} leaveTouchDelay={10000}>
            <span>
              <HelpOutlinedIcon />
            </span>
          </Tooltip>
        )}
      </Stack>
      {description && (
        <Typography variant="natter-text-sm" color="text.secondary">
          {description}
        </Typography>
      )}
    </Stack>
    {error && <ErrorBox>{error}</ErrorBox>}
    {children}
  </Stack>
);
