import { ActiveMeetingBroadcast, ScreenshareDetails, StageInvitationBasicInfo } from 'domain/Broadcast';
import { Timestamp } from 'domain/Common';
import { EventMetricsPayload, EventRoom, EventRoomAttendee, MeetingGuest } from 'domain/event';
import { QuestionAssignmentWSPayload } from 'store/features/questions';

export enum EventPhase {
  WaitingRoom = 'WAITING_ROOM',
  Broadcast = 'BROADCAST',
  Backstage = 'BACKSTAGE',
  OneOnOne = 'ONE_ON_ONE',
  Feedback = 'FEEDBACK',
  Ended = 'ENDED',
}

export interface ActiveRoomState {
  id: string;
  isConversationFinished: boolean;
  isBroadcastCountdown: boolean;
  joinedAsSupport: boolean;
  showTranscriptionModal: boolean;
  isTranscribing: boolean;
}

export type AudienceCountUpdatedPayload = {
  audienceCount: number;
};

export interface EventState {
  activeEventId?: number;
  broadcast: ActiveMeetingBroadcast | null;
  audience: MeetingGuest[];
  audienceCount: number;
  speakers: MeetingGuest[];
  serverTimeOffset: number;
  activeRoom: ActiveRoomState | null;
  rooms: EventRoom[];
  roomsRequestingSupport: string[];
  roomsCount: number;
  pin: string;
  isNatteringSkipped: boolean;
  screensharingDetails: ScreenshareDetails | null;
  metrics: EventMetricsPayload;
  stageInvitation: StageInvitationBasicInfo | null;
  lastCallGuests: EventRoomAttendee[];
  isJoiningNattering: boolean;
  natteringRoundStartTime: Timestamp | undefined;
  natteringRoundEndTime: Timestamp | undefined;
  natteringRoundTopic: string | undefined;
  isBigMeetingMode: boolean;
  participantsInvitedToStage: number[];
  participantsWithRaisedHands: number[];
  participantsDisconnected: number[];
  showAudienceList: boolean;
  activePanelTab: number;
  isWaitingForNattering: boolean;
  isBroadcastStopped: boolean;
  /** Skipped Nattering insights */
  questionsAssignments: QuestionAssignmentWSPayload[];
}
