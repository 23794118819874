import { ComponentProps } from 'react';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import { dark } from 'common/theme/palette';
import { omitForwardedProps } from 'common/utils';

type Props = ComponentProps<typeof LoadingButton> & {
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  size?: 'medium' | 'small';
};
export const StyleButton = styled(LoadingButton, { shouldForwardProp: omitForwardedProps('isActive', 'size') })<Props>(
  ({ theme: { spacing, palette, shape }, color, size = 'medium', isActive }) => ({
    height: spacing(6),
    width: spacing(6),
    ...(size === 'small' && {
      height: spacing(4),
      width: spacing(4),
    }),

    minWidth: 'unset',
    borderRadius: shape.borderRadius * 2,
    padding: spacing(1.5),
    color: palette.common.white,
    border: `1px solid ${dark[100]}`,
    backgroundColor: dark[100],
    transition: 'width 0.2s',

    '&:disabled, &.Mui-disabled': {
      color: palette.grey[400],
      backgroundColor: palette.grey[100],
    },

    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: spacing(3),
    },

    '&:hover': {
      backgroundColor: '#3D3C3C',
    },

    ':active': {
      borderColor: dark[100],
    },

    '&.Mui-focusVisible': {
      borderColor: palette.extendedPrimary[300],
      backgroundColor: dark[100],
    },

    '&.MuiLoadingButton-root:disabled.MuiLoadingButton-loading': {
      backgroundColor: dark[100],

      '& .MuiLoadingButton-loadingIndicator': {
        color: palette.common.white,
      },
    },

    ...(isActive && {
      color: palette.extendedPrimary[900],
      backgroundColor: palette.extendedPrimary[200],

      '&:hover': {
        backgroundColor: palette.extendedPrimary[100],
      },

      '&.Mui-focusVisible': {
        borderColor: palette.extendedPrimary[300],
        backgroundColor: palette.extendedPrimary[200],
      },
    }),

    ...(isActive &&
      color === 'primary' && {
        width: 64,
        color: palette.extendedPrimary[900],
        backgroundColor: palette.extendedPrimary[200],
        borderColor: palette.extendedPrimary[200],

        '&:hover': {
          backgroundColor: palette.extendedPrimary[100],
        },

        '&.Mui-focusVisible': {
          borderColor: palette.extendedPrimary[300],
          backgroundColor: palette.extendedPrimary[100],
        },

        '&.MuiLoadingButton-loading': {
          backgroundColor: palette.extendedPrimary[100],
        },
      }),
  })
);
