import { type Components, type Theme } from '@mui/material';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: ({ palette }) => ({
    color: palette.text.primary,

    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette.grey[300],
      border: '0 none',
      borderColor: palette.common.white,
      borderRadius: 50,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: palette.grey[400],
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: palette.grey[500],
    },
    '&::-webkit-scrollbar-track': {
      background: palette.grey[200],
      border: `0px none ${palette.common.white}`, // maybe change to border: none?
      borderRadius: 50,
    },
    '&::-webkit-scrollbar-track:hover': {
      background: palette.grey[200],
    },
    '&::-webkit-scrollbar-track:active': {
      background: palette.grey[200],
    },
    '&::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
  }),
};
