import type { ClientRole, NetworkQuality } from 'agora-rtc-react';
import { MediaTrackErrorKey } from 'devices';
import { MediaTrackState } from 'domain/Broadcast';
import { AgoraProxyMode } from 'domain/event';

type MediaTrack = {
  currentDeviceId: string | null;
  error: MediaTrackErrorKey | null;
  isPublished: boolean;
  state: MediaTrackState;
};

export type AgoraState = {
  connectionTestState: 'idle' | 'in progress' | 'completed' | 'failed';
  isJoined: boolean;
  audioTrack: MediaTrack;
  videoTrack: MediaTrack;
  networkQuality: NetworkQuality[];
  role?: ClientRole;
  supportedCloudProxyOptions: AgoraProxyMode[];
};

export enum NetworkQualityState {
  Unknown,
  Excellent,
  Good,
  SlightlyImpared,
  Poor,
  BarelyUsable,
  Disconnected,
}

export enum NetworkErrorKey {
  UnstableConnection = 'unstableConnection',
  UnstableDownlinkConnection = 'unstableDownlinkConnection',
  UnstableUplinkConnection = 'unstableUplinkConnection',
}
