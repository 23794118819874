import React, { ComponentType, FC } from 'react';

import { PaletteMode, ThemeProvider, useTheme } from '@mui/material';
import { theme as createTheme } from 'common/theme';

type Props = {
  mode?: PaletteMode;
};

export const NatterThemeProvider: FC<Props> = ({ children, mode = 'light' }) => (
  <ThemeProvider theme={createTheme(undefined, mode)}>{children}</ThemeProvider>
);

export const NatterDarkThemeProvider: FC = ({ children }) => {
  const { palette } = useTheme();

  return <ThemeProvider theme={{ palette: { ...palette, mode: 'dark' } }}>{children}</ThemeProvider>;
};

export const withTheme =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => (
    <NatterThemeProvider>
      <WrappedComponent {...props} />
    </NatterThemeProvider>
  );
