import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { isApiResponse } from 'common/utils';
import type { RootState } from 'store';
import { handleQueryError } from 'store/utils';

import { nattersApi } from './nattersApi';

export const nattersApiLister = createListenerMiddleware<RootState>();

nattersApiLister.startListening({
  matcher: isAnyOf(
    nattersApi.endpoints.getNattersList.matchRejected,
    nattersApi.endpoints.createNatter.matchRejected,
    nattersApi.endpoints.updateNatter.matchRejected,
    nattersApi.endpoints.deleteNatter.matchRejected,
    nattersApi.endpoints.getNatter.matchRejected
  ),
  effect: ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || 'Unable to call Natters API');
  },
});
