import React, { FC, ReactNode } from 'react';

import { Box, Paper, Stack, SxProps, Theme } from '@mui/material';
import { Checkbox, IconButtonWithTooltip, Tooltip } from 'common/components';
import { mergeSxProps } from 'common/theme';
import { DeleteIcon } from 'icons';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  avatar?: ReactNode;
  avatarLink?: string | null;
  onDelete?(): void;
  checkboxProps?: {
    isSelected: boolean;
    onSelect?(isSelected: boolean): void;
    disabled?: boolean;
    tooltip?: string;
  };
  'data-testid'?: string;
  sx?: SxProps<Theme>;
};

export const ListItem: FC<Props> = ({
  title,
  description,
  avatar,
  checkboxProps,
  'data-testid': dataTestId,
  sx,
  onDelete,
}) => (
  <Paper sx={mergeSxProps({ borderRadius: 2 }, sx)} data-testid={dataTestId} role="listitem">
    <Stack direction="row" alignItems="center" gap={2} p={2}>
      {checkboxProps && (
        <Tooltip title={checkboxProps.tooltip}>
          <Checkbox
            checked={checkboxProps.isSelected}
            onChange={(_event, checked) => checkboxProps.onSelect?.(checked)}
            disabled={checkboxProps.disabled}
          />
        </Tooltip>
      )}
      {avatar}
      <Stack gap={0.5} justifyContent="center" alignItems="start" minWidth={0} width="100%">
        <Box width="100%" maxWidth="100%">
          {title}
        </Box>
        {description && <Box maxWidth="100%">{description}</Box>}
      </Stack>
      {onDelete && (
        <Stack alignItems="end" flex={1}>
          <IconButtonWithTooltip color="light" aria-label="Remove" title="Remove" onClick={onDelete}>
            <DeleteIcon />
          </IconButtonWithTooltip>
        </Stack>
      )}
    </Stack>
  </Paper>
);
