import { EventTime } from 'domain/event';

import { InsightsReportAssociatedItem, InsightsReportData, ReportSource } from '../insightsReport';

export type EventReportData = Omit<InsightsReportData, 'associatedItems'> & {
  associatedEvents: InsightsReportAssociatedItem[];
};

export type CreateEventsReportPayload = { allEvents: boolean; eventIds: (number | undefined)[] };

export type EventReportSource = ReportSource & {
  eventTime: EventTime;
};

export const isEventReportSource = (reportSource: ReportSource): reportSource is EventReportSource =>
  (reportSource as EventReportSource).eventTime !== undefined;
