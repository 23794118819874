import React, { FC, useEffect, useState } from 'react';

import { StyledIframe } from './LoginEmbedded.styled';

const AUTH_PANEL_WIDTH = 482;
const AUTH_PANEL_HEIGHT = 600;

type Props = {
  src: string;
  onAuthenticated?: () => void;
  onLoad?: () => void;
};

export const LoginEmbedded: FC<Props> = ({ src, onAuthenticated, onLoad }) => {
  const [height, setHeight] = useState(AUTH_PANEL_HEIGHT);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!event.isTrusted) return;
      if (event.data.type === 'natter-theme' && 'height' in event.data) {
        setHeight(event.data.height);
        onLoad?.();
      }
      if (event.data.type === 'AUTHENTICATED') {
        onAuthenticated?.();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [onAuthenticated, onLoad]);

  return (
    <StyledIframe
      src={src}
      width={AUTH_PANEL_WIDTH}
      sx={{ height: height + 8 }}
      loading="lazy"
      sandbox="allow-popups allow-forms allow-same-origin allow-scripts"
    />
  );
};
