import React, { ComponentType, FC, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useAuth } from './AuthProvider';

type Props = {
  getRedirectUrl?(params: {}): string;
};

export const PublicRoute: FC<Props> = ({ children, getRedirectUrl }) => {
  const params = useParams();
  const { isAuthenticated } = useAuth();
  const redirectUri = useMemo(() => getRedirectUrl?.(params), [getRedirectUrl, params]);

  if (isAuthenticated && redirectUri) {
    return <Redirect to={redirectUri} />;
  }

  return <>{children}</>;
};

export const withPublicRoute = (props: Props) => (WrappedComponent: ComponentType) => () => (
  <PublicRoute {...props}>
    <WrappedComponent />
  </PublicRoute>
);
