import { serializeDateField, serializeDateFields } from 'common/utils/time';
import { PaginatedApiPayloadV2 } from 'domain/Common';

import { InsightsReportData, ReportSource } from '../insightsReport';
import { AsyncSurveyListItem } from '../survey/types';
import { SurveysReportData } from './types';

export const mapSurveysReportDataToInsightsReportData = (response: SurveysReportData): InsightsReportData => ({
  associatedItems: response.associatedSurveys.map(serializeDateFields()),
  createdAt: serializeDateField(response.createdAt),
  id: response.id,
  name: response.name,
  status: response.status,
  pin: response.pin,
  anonymityFilterEnabled: response.anonymityFilterEnabled,
});

export const mapSurveysReportListToInsightsReportList = (
  response: PaginatedApiPayloadV2<SurveysReportData>
): PaginatedApiPayloadV2<InsightsReportData> => ({
  ...response,
  data: response.data.map(mapSurveysReportDataToInsightsReportData),
});

export const mapSurveysListToReportSources = (
  response: PaginatedApiPayloadV2<AsyncSurveyListItem>
): PaginatedApiPayloadV2<ReportSource> => ({
  ...response,
  data: response.data.map((survey) => ({
    guestCount: survey.invitedUsers ?? 0,
    id: survey.id,
    name: survey.name,
  })),
});
