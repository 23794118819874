import { createAsyncThunk } from '@reduxjs/toolkit';
import { assert } from 'common/utils';
import { EventStatus } from 'domain/event';
import { mapAllEventWizardQuestionsToApi } from 'event/mappers';
import { EventWizardFormSerializedValues } from 'pages/EventWizard/validation';
import { RootState } from 'store';
import { eventApi, eventSeriesApi, selectActiveEventQuestions } from 'store/apis/event';

export const updateQuestions = createAsyncThunk<
  void,
  { eventId: number; seriesId?: number; values: EventWizardFormSerializedValues }
>('[Event Wizard] Update event questions', async ({ eventId, seriesId, values }, { dispatch, getState }) => {
  if (!seriesId && values.status && values.status !== EventStatus.Published) {
    // updating questions not allowed for single event which is already started
    return;
  }

  const state = getState() as RootState;
  const originalQuestions = selectActiveEventQuestions(state);
  const questions = mapAllEventWizardQuestionsToApi(values);

  if (!(originalQuestions?.length || questions.length)) return;

  if (seriesId) {
    if (values.schedule.length > 1) {
      await dispatch(eventSeriesApi.endpoints.updateEventSeriesQuestions.initiate({ seriesId, questions })).unwrap();
    } else {
      // it was event series before but all but one dates were removed - we convert it back to single event
      const { eventId: singleEventId } = values.schedule[0] ?? {};
      assert(singleEventId, 'Event ID is not present');
      await dispatch(eventApi.endpoints.createEventQuestions.initiate({ id: singleEventId, questions })).unwrap();
    }
  } else {
    assert(eventId, 'Event ID is not present');
    await dispatch(eventApi.endpoints.createEventQuestions.initiate({ id: eventId, questions })).unwrap();
  }
});
