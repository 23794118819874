import React, { FC, useCallback } from 'react';

import { Skeleton } from '@mui/material';
import { useAppSelector } from 'store';
import { selectUserAccount } from 'store/apis/user';
import { useKeycloakContext } from 'store/context/KeycloakContext';

import { CurrentUser } from './CurrentUser';

export const CurrentUserContainer: FC = () => {
  const keycloak = useKeycloakContext();
  const userAccount = useAppSelector(selectUserAccount);
  const handleLogout = useCallback(() => keycloak.logout({ redirectUri: window.location.origin }), [keycloak]);

  if (!userAccount) return <Skeleton width={240} />;

  return <CurrentUser account={userAccount} onLogout={handleLogout} />;
};
