import React, { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { ErrorBox } from 'common/components';
import { OptionItem, SelectInput } from 'common/components/_legacy/Form';
import { constantToLabel } from 'common/utils';
import { UserPrivilege } from 'domain/UserProfile';
import { useGetCommunityRolesQuery } from 'store/apis/organization';

export const MemberPrivileges: FC = () => {
  const privileges: UserPrivilege[] = useWatch({ name: 'privileges' });
  const { errors } = useFormState({ name: 'privileges' });
  const { communityId } = useParams<{ communityId: string }>();
  const { roleOptions = [], privilegeOptions = [] } = useGetCommunityRolesQuery(
    { communityId: Number(communityId) },
    {
      skip: !communityId,
      selectFromResult: ({ data = [] }) => ({
        privilegeOptions: data?.reduce(
          (acc, curr) => [
            ...acc,
            ...curr.privileges.map((privilege) => ({
              value: privilege,
              label: constantToLabel(privilege.name),
              disabled: true,
            })),
          ],
          [] as OptionItem<UserPrivilege>[]
        ),
        roleOptions: data?.reduce(
          (acc, curr) => [...acc, { value: curr.role.name, label: constantToLabel(curr.role.name) }],
          [] as OptionItem<string>[]
        ),
      }),
    }
  );

  return (
    <>
      <Box mt={1}>
        <SelectInput
          name="communityRole"
          label="Role"
          placeholder="Role"
          options={roleOptions}
          centered={false}
          autoWidth
        />
      </Box>
      {errors.privileges && <ErrorBox sx={{ mt: 4 }}>{errors.privileges.message}</ErrorBox>}
      <Stack mt={4}>
        <Typography fontWeight={600}>Actions Access</Typography>
        <Stack>
          {/* Privileges are read-only */}
          {privilegeOptions.map(({ value: privilege, label, disabled: isDisabled }, index) => (
            <Stack
              direction="row"
              key={privilege.name}
              alignItems="center"
              justifyContent="space-between"
              sx={({ palette }) => ({
                borderBottom: index < privilegeOptions.length - 1 ? '1px solid' : 'none',
                borderColor: palette.custom.lightGrey,
                cursor: isDisabled ? 'default' : 'pointer',
              })}
            >
              <Typography color={isDisabled ? 'text.disabled' : undefined}>{label}</Typography>
              <Checkbox disabled={isDisabled} checked={privileges.some(({ id }) => id === privilege.id)} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
};
