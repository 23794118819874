import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'store';
import { addAppErrorMessage, addAppInfoMessage } from 'store/features/alerts';

import { useCopyToClipboard } from './useCopyToClipboard';

export const useCopyToClipboardWithNotification = (value: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCopySuccess = useCallback(() => {
    dispatch(addAppInfoMessage({ message: t('notifications.copiedToClipboard'), timeout: 2_000 }));
  }, [dispatch, t]);

  const onCopyError = useCallback(() => {
    dispatch(addAppErrorMessage({ message: t('notifications.copyToClipboardFailed'), timeout: 3_000 }));
  }, [dispatch, t]);

  const { copyToClipboard } = useCopyToClipboard({
    value,
    onSuccess: onCopySuccess,
    onError: onCopyError,
  });

  return { copyToClipboard };
};
