import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { MediaTrackState } from 'domain/Broadcast';

import { agoraActions } from './agora.actions';
import { CONNECTION_HISTORY_LENGTH } from './constants';
import { type AgoraState, NetworkQualityState } from './types';

const initialState: AgoraState = {
  connectionTestState: 'idle',
  isJoined: false,
  audioTrack: {
    currentDeviceId: null,
    error: null,
    isPublished: false,
    state: MediaTrackState.NotReady,
  },
  videoTrack: {
    currentDeviceId: null,
    error: null,
    isPublished: false,
    state: MediaTrackState.NotReady,
  },
  networkQuality: [
    {
      downlinkNetworkQuality: NetworkQualityState.Unknown,
      uplinkNetworkQuality: NetworkQualityState.Unknown,
    },
    {
      downlinkNetworkQuality: NetworkQualityState.Unknown,
      uplinkNetworkQuality: NetworkQualityState.Unknown,
    },
    {
      downlinkNetworkQuality: NetworkQualityState.Unknown,
      uplinkNetworkQuality: NetworkQualityState.Unknown,
    },
  ],
  supportedCloudProxyOptions: [],
};

export const agoraReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(agoraActions.connectionTest.started, (state) => {
      state.connectionTestState = 'in progress';
    })
    .addCase(agoraActions.connectionTest.cloudProxyOptionSupported, (state, action) => {
      state.supportedCloudProxyOptions.push(action.payload);
    })
    .addCase(agoraActions.connectionTest.completed, (state) => {
      state.connectionTestState = 'completed';
    })
    .addCase(agoraActions.connectionTest.failed, (state) => {
      state.connectionTestState = 'failed';
    })
    .addCase(agoraActions.joined, (state) => {
      state.isJoined = true;
    })
    .addCase(agoraActions.left, (state) => {
      state.isJoined = false;
    })
    .addCase(agoraActions.demoted, (state) => {
      state.role = 'audience';
      state.audioTrack.isPublished = false;
      state.videoTrack.isPublished = false;
    })
    .addCase(agoraActions.promoted, (state) => {
      state.role = 'host';
    })
    .addCase(agoraActions.networkQuality.changed, (state, action) => {
      state.networkQuality.push(action.payload);
      if (state.networkQuality.length > CONNECTION_HISTORY_LENGTH) {
        state.networkQuality.shift();
      }
    })
    .addCase(agoraActions.audioTrack.creation.completed, (state) => {
      state.audioTrack.error = null;
    })
    .addCase(agoraActions.audioTrack.creation.failed, (state, action) => {
      state.audioTrack.error = action.payload;
    })
    .addCase(agoraActions.audioTrack.deviceChange.completed, (state, action) => {
      state.audioTrack.currentDeviceId = action.payload;
    })
    .addCase(agoraActions.audioTrack.toggling.started, (state, action) => {
      state.audioTrack.state = action.payload ? MediaTrackState.Enabling : MediaTrackState.Disabling;
    })
    .addCase(agoraActions.audioTrack.toggling.completed, (state) => {
      state.audioTrack.state =
        state.audioTrack.state === MediaTrackState.Enabling ? MediaTrackState.Enabled : MediaTrackState.Disabled;
    })
    .addCase(agoraActions.audioTrack.toggling.failed, (state) => {
      state.audioTrack.state =
        state.audioTrack.state === MediaTrackState.Enabling ? MediaTrackState.Disabled : MediaTrackState.Enabled;
    })
    .addCase(agoraActions.videoTrack.creation.completed, (state) => {
      state.videoTrack.error = null;
    })
    .addCase(agoraActions.videoTrack.creation.failed, (state, action) => {
      state.videoTrack.error = action.payload;
    })
    .addCase(agoraActions.videoTrack.deviceChange.completed, (state, action) => {
      state.videoTrack.currentDeviceId = action.payload;
    })
    .addCase(agoraActions.videoTrack.toggling.started, (state, action) => {
      state.videoTrack.state = action.payload ? MediaTrackState.Enabling : MediaTrackState.Disabling;
    })
    .addCase(agoraActions.videoTrack.toggling.completed, (state) => {
      state.videoTrack.state =
        state.videoTrack.state === MediaTrackState.Enabling ? MediaTrackState.Enabled : MediaTrackState.Disabled;
    })
    .addCase(agoraActions.videoTrack.toggling.failed, (state) => {
      state.videoTrack.state =
        state.videoTrack.state === MediaTrackState.Enabling ? MediaTrackState.Disabled : MediaTrackState.Enabled;
    })
    .addCase(agoraActions.audioTrack.published, (state) => {
      state.audioTrack.isPublished = true;
    })
    .addCase(agoraActions.videoTrack.published, (state) => {
      state.videoTrack.isPublished = true;
    })
    .addCase(agoraActions.unpublished, (state) => {
      state.audioTrack.isPublished = false;
      state.videoTrack.isPublished = false;
    })
    .addCase(agoraActions.audioTrack.cleanedUp, (state) => {
      state.audioTrack.state = MediaTrackState.NotReady;
      state.audioTrack.isPublished = false;
    })
    .addCase(agoraActions.videoTrack.cleanedUp, (state) => {
      state.videoTrack.state = MediaTrackState.NotReady;
      state.videoTrack.isPublished = false;
    })
    .addMatcher(
      isAnyOf(agoraActions.cleanedUpAfterBroadcast, agoraActions.cleanedUpAfterNattering),
      () => initialState
    );
});
