import { ERROR_MESSAGES } from 'common/constants';
import { ApiErrors, isApiErrors } from 'common/utils';
import { push, replace } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect } from 'store';
import { eventApi, eventSeriesApi } from 'store/apis/event';
import {
  addAppAlertDialog,
  addAppBackendErrorMessage,
  addAppErrorMessage,
  addAppSuccessMessage,
} from 'store/features/alerts';

import { eventWizardActions } from './eventWizard.actions';
import { CreateEventEntitiesArgs } from './types';

const isMonthlyEventLimitReached = ({ errors }: ApiErrors) =>
  errors.some((error) => error.message.includes('Events per month limit'));

export const createEventFailedEffect: Effect = ({ payload }, { dispatch }) => {
  if (isApiErrors(payload?.data)) {
    if (isMonthlyEventLimitReached(payload.data)) {
      dispatch(
        addAppAlertDialog({
          title: 'Monthly Event Limit Reached',
          description:
            'You have reached your monthly limit for creating Events. To create a new Event this month, you must either cancel one of your existing Events or wait until next month.',
          showCancelButton: true,
          cancelLabel: 'Close',
          confirmLabel: 'Go to My Events',
          actionToDispatchOnConfirm: eventWizardActions.eventLimitReachedModal.confirmedButton.clicked(),
        })
      );
    } else {
      (payload.data as ApiErrors).errors.forEach(({ message }) => {
        dispatch(addAppErrorMessage(message));
      });
    }
  } else {
    dispatch(addAppBackendErrorMessage(ERROR_MESSAGES.unknownServerError));
  }
};

export const goToMyEventsEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.MyEvents));
};

export const updateEventFailedEffect: Effect = (_, { dispatch }) => {
  dispatch(addAppBackendErrorMessage(ERROR_MESSAGES.unknownServerError));
};

export const eventUpdated: Effect = (action, { dispatch, getState }) => {
  const state = getState().router.location?.state as { previousLocation: Location } | undefined;
  const redirectUrl = state?.previousLocation.pathname ?? AppRoutes.MyEventsUpcoming;
  const isRedirectToEventsList = redirectUrl === AppRoutes.MyEventsUpcoming;
  dispatch(replace(redirectUrl, isRedirectToEventsList ? { eventId: action.payload, mode: 'edit' } : undefined));
  if (!isRedirectToEventsList) {
    dispatch(addAppSuccessMessage('Event successfully updated'));
  }
};

export const eventCreated: Effect = (action, { dispatch }) => {
  dispatch(push(AppRoutes.MyEventsUpcoming, { eventId: action.payload.eventId, mode: 'create' }));
};

export const eventVideoUploadFailedEffect: Effect = ({ payload: errorMessage }, { dispatch }) => {
  dispatch(addAppErrorMessage(errorMessage));
};

export const eventImageUploadFailedEffect: Effect = (_action, { dispatch }) => {
  dispatch(addAppErrorMessage('Error occurred while uploading some of the Event images'));
};

export const eventQuestionCreationFailedEffect: Effect = (_action, { dispatch }) => {
  dispatch(addAppErrorMessage('Error occurred while uploading some of the Event questions'));
};

export const createEventEntitiesFailedEffect: Effect = ({ meta, error }, { dispatch }) => {
  const { eventId, seriesId } = meta.arg as CreateEventEntitiesArgs;
  if (seriesId) {
    dispatch(eventSeriesApi.endpoints.deleteEventSeries.initiate({ seriesId }));
  } else {
    dispatch(eventApi.endpoints.deleteEvent.initiate({ id: eventId, silentDelete: true }));
  }

  if (isApiErrors(error)) {
    error.errors.forEach(({ message }) => {
      dispatch(addAppErrorMessage(message));
    });
  }
};
