import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { eventApi } from 'store/apis/event/eventApi';

import { eventWizardActions } from './eventWizard.actions';
import {
  createEventEntitiesFailedEffect,
  createEventFailedEffect,
  eventCreated,
  eventImageUploadFailedEffect,
  eventQuestionCreationFailedEffect,
  eventUpdated,
  eventVideoUploadFailedEffect,
  goToMyEventsEffect,
  updateEventFailedEffect,
} from './eventWizard.effects';
import { createEvent, createEventEntities, updateEvent } from './thunks';

export const eventWizardListener = createListenerMiddleware<RootState>();

eventWizardListener.startListening({
  actionCreator: createEvent.rejected,
  effect: createEventFailedEffect,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.eventLimitReachedModal.confirmedButton.clicked,
  effect: goToMyEventsEffect,
});

eventWizardListener.startListening({
  actionCreator: createEvent.fulfilled,
  effect: eventCreated,
});

eventWizardListener.startListening({
  actionCreator: updateEvent.fulfilled,
  effect: eventUpdated,
});

eventWizardListener.startListening({
  actionCreator: updateEvent.rejected,
  effect: updateEventFailedEffect,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.details.videoDropzone.uploadFailed,
  effect: eventVideoUploadFailedEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.postEventImage.matchRejected,
  effect: eventImageUploadFailedEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.createEventQuestions.matchRejected,
  effect: eventQuestionCreationFailedEffect,
});

eventWizardListener.startListening({
  actionCreator: createEventEntities.rejected,
  effect: createEventEntitiesFailedEffect,
});
