import { logger } from 'common/services';
import { isAfter } from 'date-fns';
import type Keycloak from 'keycloak-js';

const MIN_VALIDITY = 7200; // 2 hours

export const updateKeycloakToken = async (keycloak: Keycloak) => {
  const { refreshTokenParsed } = keycloak;

  if (!refreshTokenParsed?.exp || isAfter(new Date(), new Date(refreshTokenParsed.exp * 1_000))) {
    await keycloak.logout();
    return;
  }

  try {
    await keycloak.updateToken(MIN_VALIDITY);
  } catch (error) {
    if (error instanceof Error) {
      logger.error('[KC] Failed to refresh the token, or the session has expired', { message: error.message });
    }
    throw error;
  }
};
