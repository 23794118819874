import React, { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';
import { CommunityLogo } from 'common/layout/components/CommunityLogo';
import { textEllipsis } from 'common/theme/typography';
import { getItemsCountLabel } from 'common/utils';
import { Community } from 'domain/Community';

export type CommunityCardProps = {
  community: Community;
  onClick?(): void;
  actions?: ReactNode;
  isActive?: boolean;
};

const TEST_IDS = {
  communityCardTitle: 'CommunityCard-titles',
  communityCardIdPrefix: 'CommunityCard-',
};

export const CommunityCard: FC<CommunityCardProps> = ({ onClick, community, actions = null, isActive = false }) => (
  <Stack
    data-testid={`${TEST_IDS.communityCardIdPrefix}${community.id}`}
    onClick={onClick}
    sx={({ palette }) => ({
      cursor: onClick ? 'pointer' : 'default',
      padding: 3,
      height: 200,
      background: palette.background.paper,
      border: '1px solid',
      borderColor: isActive ? community.brandingColor : palette.custom.lightGrey,
      borderRadius: '16px 16px 0 0',
      borderBottom: '3px solid',
      borderBottomColor: community.brandingColor,
      '&:hover': {
        backgroundColor: onClick ? palette.grey[50] : undefined,
      },
    })}
  >
    <Stack direction="row" justifyContent="space-between" pb={2}>
      <CommunityLogo community={community} />
      {actions}
    </Stack>
    <Typography
      variant="natter-text-md"
      fontWeight={600}
      pb={1}
      title={community.name}
      sx={textEllipsis}
      data-testid={TEST_IDS.communityCardTitle}
    >
      {community.name}
    </Typography>
    <Typography variant="natter-text-sm" color="text.secondary">
      {getItemsCountLabel('Member', community.communityMembersCount)}
    </Typography>
  </Stack>
);
