import { Dispatch } from '@reduxjs/toolkit';
import { logger } from 'common/services';

type SocketDispatcherMiddlewareType = (dispatch: Dispatch, args: { path: string; payload: unknown }) => boolean;

const socketDispatcherMiddlewares: SocketDispatcherMiddlewareType[] = [];

export const addSocketDispatcherMiddleware = (dispatcher: SocketDispatcherMiddlewareType) =>
  socketDispatcherMiddlewares.push(dispatcher);

export const initSocketMessageDispatcher =
  (dispatch: Dispatch) =>
  ({ path, payload }: { path: string; payload: unknown }) => {
    // eslint-disable-next-line functional/no-let
    let handled = false;
    try {
      // eslint-disable-next-line sonarjs/no-empty-collection
      handled = socketDispatcherMiddlewares.some((dispatcher) => dispatcher(dispatch, { path, payload }));
    } catch (error) {
      logger.error('[SocketMessageDispatcher] Error while handling WS message', { error, extra: { path, payload } });
      handled = false;
    } finally {
      if (!handled) {
        dispatch({
          type: path,
          payload,
        });
      }
    }
  };
